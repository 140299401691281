import React, { useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Confirm, useCrudContext } from '@guillotinaweb/react-gmi'

export function DeleteItemFromObject({ path, label, field, isMultiple = false }) {
  const [open, setOpen] = useState(false)
  const { patch, loading, Ctx } = useCrudContext()

  const doAction = async () => {
    let data = {}
    if (isMultiple) {
      data = {
        [field]: {
          op: 'remove',
          value: path,
        },
      }
    } else {
      data = {
        [field]: null,
      }
    }
    const dataPatch = await patch(data)
    if (dataPatch.isError) {
      Ctx.flash(`Error when delete ${label} ${path}!`, 'danger')
    } else {
      Ctx.flash(`${label} ${path}, deleted!`, 'success')
    }

    Ctx.refresh()
  }

  return (
    <>
      {open && (
        <Confirm
          loading={loading}
          onCancel={() => setOpen(false)}
          onConfirm={doAction}
          message={`Are you sure to delete ${label}?`}
        />
      )}
      <DeleteOutlined
        data-test={`deleteItemFromObjectTest${field}`}
        className="mr-2"
        onClick={(event) => {
          setOpen(true)
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation()
        }}
      />
    </>
  )
}
