import React from 'react'

const plain = ['string', 'number', 'boolean']

export function RenderField({ value, schema, Widget }) {
  if (value === null || value === undefined) return ''

  if (Widget) {
    return <Widget value={value} schema={schema} />
  }

  const type = typeof value
  if (plain.includes(type)) {
    return value
  }
  if (type === 'object') {
    if (Array.isArray(value)) {
      return value.map((item) => (
        <div key={item}>
          <RenderField value={item} schema={schema} />
        </div>
      ))
    }
    return Object.keys(value).map((key) => (
      <FieldValue field={key} value={value[key]} key={key} schema={schema} />
    ))
  }
  return <p>No render for {JSON.stringify(value)}</p>
}

const FieldValue = ({ field, value, schema }) => (
  <div className="field">
    <div className="label">{field}</div>
    <div className="value">
      <RenderField value={value} schema={schema} />
    </div>
  </div>
)
