import { memo } from 'react'
import { EyeOutlined, LogoutOutlined } from '@ant-design/icons'

import { useLocation as useLocationWouter } from 'wouter'

const ActionsViewComponent = ({ data }) => {
  const [, setLocationWouter] = useLocationWouter()
  let proposalPath = data
  if (proposalPath.startsWith('/')) {
    proposalPath = proposalPath.substring(1)
  }
  return (
    <div className="level">
      <div className="level-item has-text-centered">
        <EyeOutlined
          style={{ fontSize: '20px' }}
          twoToneColor="#000"
          onClick={() => {
            setLocationWouter(
              `/?path=/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${proposalPath}/`
            )
          }}
        />
      </div>
      <a
        className="level-item has-text-centered"
        style={{ color: '#000' }}
        href={`/?path=/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${proposalPath}/`}
        target="_blank"
        rel="noreferrer"
      >
        <LogoutOutlined style={{ fontSize: '20px' }} />
      </a>
    </div>
  )
}

const ActionsView = memo(ActionsViewComponent)
ActionsView.displayName = 'ActionsView'
export default ActionsView
