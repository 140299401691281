import { Typography, Tabs } from 'antd'
import { PerformanceForm } from 'components/Forms'
import { useTraversal, useGuillotinaClient, useLocation } from '@guillotinaweb/react-gmi'
import { processResponse } from 'helpers/utils'
import { PerformancesList } from './Performances/PerformancesList'

export function PanelPerformances() {
  const traversal = useTraversal()
  const client = useGuillotinaClient()

  const [location, setLocation] = useLocation()

  const defaultTab = location.get('performanceTab')
  const modifyContent = traversal.hasPerm('guillotina.ModifyContent')

  async function createPerformance(dataForm) {
    const form = Object.assign({}, { '@type': 'Actuacio' }, dataForm)
    let processResult = {}
    try {
      const response = await client.post(
        `/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${client.getYearFromPath(
          traversal.context['@id']
        )}/actuacions`,
        form
      )
      processResult = await processResponse(response, true)
    } catch (e) {
      console.error('Error', e)
      processResult = { isError: true, errorMessage: 'unhandled exception' }
    }
    const { isError, errorMessage } = processResult
    if (!isError) {
      traversal.flash('Content created!', 'success')
    } else {
      traversal.flash(`An error has ocurred: ${errorMessage}`, 'danger')
    }
  }

  async function doSubmit(data) {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        await createPerformance(data[i])
      }
    } else {
      await createPerformance(data.formData ? data.formData : data)
    }

    setLocation({
      performanceTab: 'list_performances',
    })
    traversal.refresh()
  }

  return (
    <div data-test="panelPerformancesTest">
      <Typography.Title level={3}>Actuacions</Typography.Title>

      <Tabs
        defaultActiveKey={defaultTab ?? 'list_performances'}
        onChange={(key) => {
          setLocation({
            performanceTab: key,
          })
        }}
      >
        <Tabs.TabPane
          tab={<div data-test="tabPanelListPerformanceTest">Llistat actuacions</div>}
          key="list_performances"
        >
          <PerformancesList />
        </Tabs.TabPane>
        {modifyContent && (
          <Tabs.TabPane
            tab={<div data-test="tabPanelAddPerformanceTest">Afegir actuacions</div>}
            key="add_performance"
          >
            <PerformanceForm
              onSubmit={doSubmit}
              onError={(err) => console.log(err)}
              actionName={'Add'}
              dataTest={`formAdd$ActuacioTest`}
              multipleData
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </div>
  )
}
