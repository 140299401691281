import {
  Confirm,
  Delete,
  Notification,
  Pagination,
  buildQs,
  parser,
  useConfig,
  useGuillotinaClient,
  useLocation,
} from '@guillotinaweb/react-gmi'
import { Button, Checkbox, Col, Divider, Row, Table, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useSetState } from 'react-use'

import { InputSearchFilter } from 'components/Filters/InputSearch'
import { SelectFilter } from 'components/Filters/Select'
import { getComponentsByTypeColumns } from 'components/Table/RenderColumns/getComponents'
import GuilloCfg from 'guillotina/config'
import { typesGetLabelByKey, vocabulariesGetLabelByKey } from 'helpers/vocabulariesMapKeyLabels'

import {
  arrayFilters,
  arrayFiltersInputs,
  arrayVocabulariesFilters,
  getParamsFilters,
} from 'helpers/entitiesFiltersKeys'

import { LIMIT_ELEMENTS_GUILLOTINA, LLOTJA_STATUS_CLASS } from 'helpers/constants'
import {
  ATTRIBUTES_WITHOUT_IN_FILTER,
  get,
  getDefaultValueFormParams,
  getQueryParam,
} from 'helpers/utils'
import { useVocabularies } from 'hooks/useVocabulary'

const columns = [
  {
    title: 'Accions',
    key: 'path',
    dataIndex: 'path',
    render: (data) => getComponentsByTypeColumns({ type: 'actions-view', data }),
  },
  {
    title: 'Número',
    key: 'num',
    dataIndex: 'num',
  },
  {
    title: 'Nom',
    key: 'title',
    dataIndex: 'title',
  },
  {
    title: 'Correu',
    dataIndex: 'correu',
    key: 'correu',
  },
  {
    title: 'País',
    dataIndex: 'pais',
    key: 'pais',
    render: (data) => vocabulariesGetLabelByKey(data),
  },
  {
    title: 'Idioma',
    dataIndex: 'idioma',
    key: 'idioma',
    render: (data) => vocabulariesGetLabelByKey(data),
  },
  {
    title: 'Estat',
    dataIndex: 'review_state',
    key: 'review_state',
  },
  {
    title: 'Total Acreditats',
    dataIndex: 'total_acreditats',
    key: 'total_acreditats',
  },
  {
    title: 'Tipus',
    dataIndex: 'type_name',
    key: 'type_name',
    render: (data) => typesGetLabelByKey(data),
  },
  {
    title: 'Tipus Artist/Representant',
    dataIndex: 'tipus',
    key: 'tipus',
  },
  {
    title: 'Tipus estand',
    dataIndex: 'tipus_estand',
    key: 'tipus_estand',
    render: (data) => getComponentsByTypeColumns({ type: 'tags', data, getLabelByKey: true }),
  },
  {
    title: 'Tipus parada',
    dataIndex: 'tipus_parada',
    key: 'tipus_parada',
    render: (data) => getComponentsByTypeColumns({ type: 'tags', data, getLabelByKey: true }),
  },
  {
    title: 'Estat Pagaments',
    dataIndex: 'estat_pagaments',
    key: 'estat_pagaments',
    render: (data) => getComponentsByTypeColumns({ type: 'tags', data }),
  },
  {
    title: 'Creador',
    dataIndex: 'creators',
    key: 'creators',
    render: (data) => getComponentsByTypeColumns({ type: 'tags', data }),
  },
]

const initialState = {
  selectedYearData: undefined,
  items: undefined,
  loading: false,
  total: 0,
}

const orderOptions = [
  {
    title: 'Data creacio',
    token: 'creation_date',
  },
  {
    title: 'Data Modificacio',
    token: 'modification_date',
  },
  {
    title: 'Nom',
    token: 'title',
  },
  {
    title: 'Número',
    token: 'num_int',
  },
  {
    title: 'Total acreditats',
    token: 'total_acreditats',
  },
]

const searchParsed = parser('type_name__in=Ens,EnsPremsa,EnsProfessional,EnsServeis')

export function EntitiesPage({ onLogout, years, selectedYear, setSelectedYear }) {
  const [flashMessageInfo, setFlashMessageInfo] = useState({
    message: null,
    type: 'success',
  })

  const [loadingActions, setLoadingActions] = useState(false)
  const [openSendEmails, setOpenSendEmails] = useState(false)
  const [openSendRemindToPayEmails, setOpenSendRemindToPayEmails] = useState(false)
  const [state, setState] = useSetState(initialState)
  const [totalItemsEachSearch, setTotalItemsEachSearch] = useSetState({
    loading: false,
    result: undefined,
  })

  const [location, setLocation] = useLocation()
  const { PageSize } = useConfig({ PageSize: LIMIT_ELEMENTS_GUILLOTINA })
  const client = useGuillotinaClient()
  const { data: vocabularies, loading: isLoadingVocabularies } = useVocabularies(
    arrayVocabulariesFilters,
    `${GuilloCfg.url}${selectedYear}/`
  )

  const { items, loading, total, selectedYearData } = state
  const page = parseInt(getQueryParam(location, 'page', 0))
  const sort = getQueryParam(location, 'sort', 'creation_date')
  const sortDirection = getQueryParam(location, 'sort_direction', 'des')

  const [paramsParsed, defaultFilters] = getParamsFilters(location)
  const [
    defaultCountry,
    defaultMembers,
    defaultTipusEstand,
    defaultTipusParada,
    defaultTipusEns,
    defautTipusEnsArtistaRepresentant,
    defaultPaymentStatus,
    defaultLanguage,
    defaultReviewState,
    defaultProcedencia,
    defaultPromoCode,
    defaultPaymentPending,
    defaultTitle,
    defaultNum,
    defaultTotalAccreditations,
  ] = defaultFilters

  useEffect(() => {
    ;(async () => {
      if (!isLoadingVocabularies && selectedYear) {
        setState({ loading: true })

        const responseYear = await client.get(selectedYear)

        let sortParsed = {}
        if (sortDirection === 'asc') {
          sortParsed = parser(`_sort_asc=${sort}`)
        } else if (sortDirection === 'des') {
          sortParsed = parser(`_sort_des=${sort}`)
        }
        let typeSearchParsed = searchParsed
        if (defaultTipusEns) {
          typeSearchParsed = []
        }
        const qs = buildQs([...typeSearchParsed, ...sortParsed, ...paramsParsed])
        const response = await client.search(
          `${GuilloCfg.url}${selectedYear}/`,
          qs,
          false,
          false,
          page * PageSize,
          PageSize
        )

        if (response && response.error) {
          onLogout()
          setState({
            loading: false,
          })
        } else {
          setState({
            selectedYearData: await responseYear.json(),
            items: response.items,
            loading: false,
            total: response.items_total,
          })
        }
      }
    })()
  }, [
    isLoadingVocabularies,
    selectedYear,
    page,
    sort,
    sortDirection,
    defaultCountry,
    defaultMembers,
    defaultTipusEstand,
    defaultTipusParada,
    defaultTipusEns,
    defautTipusEnsArtistaRepresentant,
    defaultPaymentStatus,
    defaultLanguage,
    defaultReviewState,
    defaultProcedencia,
    defaultPromoCode,
    defaultTitle,
    defaultNum,
    defaultTotalAccreditations,
    defaultPaymentPending,
  ])

  useEffect(() => {
    ;(async () => {
      const payload = {}

      if (!loading && selectedYear && vocabularies !== undefined && !isLoadingVocabularies) {
        setTotalItemsEachSearch({
          loading: true,
        })

        for (let j = 0; j < arrayFilters.length; j++) {
          const { filter, resultObj } = arrayFilters[j]
          payload[filter] = {}

          const cleanedParamsParsed = paramsParsed.filter((params) => {
            return params[0].indexOf(filter) === -1
          })
          let typeSearchParsed = searchParsed
          if (defaultTipusEns || filter.indexOf('type_name') !== -1) {
            typeSearchParsed = []
          }
          const commonFilters = [...typeSearchParsed, ...cleanedParamsParsed]
          const commonFiltersBase = [...searchParsed, ...cleanedParamsParsed]

          const totalQs = buildQs(commonFilters)
          payload[filter]['total'] = {
            total: get(
              client.cacheSearchResults,
              [`${selectedYear}/${totalQs}`, 'total'],
              undefined
            ),
            query: totalQs !== '' ? totalQs : buildQs(commonFiltersBase),
          }

          let values = resultObj ? vocabularies[`${resultObj}`] : arrayFilters[j].values
          if (selectedYearData && arrayFilters[j].yearField) {
            values = (selectedYearData[arrayFilters[j].yearField] ?? []).map((info) => {
              return {
                title: info,
                token: info,
              }
            })
          }

          for (let i = 0; i < values.length; i++) {
            let filterParsed = []
            if (ATTRIBUTES_WITHOUT_IN_FILTER.includes(filter)) {
              filterParsed = parser(`${filter}=${values[i].token}`)
            } else {
              filterParsed = parser(`${filter}__in=${values[i].token}`)
            }
            let qs = buildQs([...commonFilters, ...filterParsed])
            if (qs === '') {
              qs = buildQs([...commonFiltersBase, ...filterParsed])
            }
            payload[filter][values[i].token] = {
              total: get(
                client.cacheSearchResults,
                [`${selectedYear}/${qs}`, , 'total'],
                undefined
              ),
              query: qs,
            }
          }
        }

        const res = await client.post(`${GuilloCfg.url}${selectedYear}/@totalsFilters`, payload)

        if (res.ok) {
          setTotalItemsEachSearch({
            loading: false,
            result: await res.json(),
          })
        } else {
          setTotalItemsEachSearch({
            loading: false,
          })
        }
      }
    })()
  }, [loading, selectedYear, vocabularies, isLoadingVocabularies, defaultTipusEns])

  const doPaginate = (page) => {
    setLocation({ page: page })
  }

  const handleSendEmailActivateEns = async () => {
    setLoadingActions(true)
    let typeSearchParsed = searchParsed
    if (defaultTipusEns) {
      typeSearchParsed = []
    }
    const qs = buildQs([...typeSearchParsed, ...paramsParsed])
    const endpoint = `${GuilloCfg.url}${selectedYear}/@sendEmailActivatedEns?${qs}`
    const response = await client.get(endpoint)
    if (response.ok) {
      setFlashMessageInfo({
        message: 'Great! Emails sent',
        type: 'success',
      })
    } else {
      setFlashMessageInfo({
        message: 'Error when sending emails',
        type: 'danger',
      })
    }
    setLoadingActions(false)
    setOpenSendEmails(false)
  }

  const handleSendEmailRemindToPay = async () => {
    setLoadingActions(true)
    let typeSearchParsed = searchParsed
    if (defaultTipusEns) {
      typeSearchParsed = []
    }
    const qs = buildQs([...typeSearchParsed, ...paramsParsed])
    const endpoint = `${GuilloCfg.url}${selectedYear}/@sendEmailRemaindEntityToPay?${qs}`
    const response = await client.post(endpoint)
    if (response.ok) {
      setFlashMessageInfo({
        message: 'Great! Emails sent',
        type: 'success',
      })
    } else {
      setFlashMessageInfo({
        message: 'Error when sending emails',
        type: 'danger',
      })
    }
    setLoadingActions(false)
    setOpenSendRemindToPayEmails(false)
  }

  const handleDownloadParadesXlsx = async () => {
    setLoadingActions(true)
    let typeSearchParsed = searchParsed
    if (defaultTipusEns) {
      typeSearchParsed = []
    }
    const qs = buildQs([...typeSearchParsed, ...paramsParsed])
    const endpoint = `${GuilloCfg.url}${selectedYear}/@getParadesExcel?${qs}`
    const res = await client.download(endpoint)

    const text = await res.blob()
    const blob = new Blob([text], {
      type: '',
    })
    const url = window.URL.createObjectURL(blob)

    // Create blob link to download
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `parades.xlsx`)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.parentNode?.removeChild(link)
    setLoadingActions(false)
  }

  const handleDownloadXlsx = async () => {
    setLoadingActions(true)
    let typeSearchParsed = searchParsed
    if (defaultTipusEns) {
      typeSearchParsed = []
    }
    const qs = buildQs([...typeSearchParsed, ...paramsParsed])
    const endpoint = `${GuilloCfg.url}${selectedYear}/@getEntitatsExcel?${qs}`
    const res = await client.download(endpoint)

    const text = await res.blob()
    const blob = new Blob([text], {
      type: '',
    })
    const url = window.URL.createObjectURL(blob)

    // Create blob link to download
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `entitats.xlsx`)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.parentNode?.removeChild(link)
    setLoadingActions(false)
  }

  return (
    <div className="box main-panel">
      {(loading || years.loading || isLoadingVocabularies || !vocabularies) && (
        <div className="progress-line"></div>
      )}
      {!loading && !years.loading && !isLoadingVocabularies && vocabularies && (
        <>
          {years.total > 1 && (
            <>
              <Divider orientation="left">Edicio</Divider>
              <Row gutter={[16, 24]} justify="start" align="middle">
                <Col className="gutter-row" span={6}>
                  <SelectFilter
                    defaultValue={selectedYear}
                    values={years.items.map((item) => {
                      return {
                        title: item.id,
                        token: item.id,
                      }
                    })}
                    loadingTotals={false}
                    totalItemsWhenSearch={{}}
                    onChange={(val) => {
                      setSelectedYear(val)
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          <Divider orientation="left">Filtres</Divider>
          <Row gutter={[16, 24]} justify="start" align="middle">
            {arrayFilters.map((filterObj) => {
              const { filter: filterName, resultObj, label } = filterObj
              let values = resultObj ? vocabularies[`${resultObj}`] : filterObj.values
              if (selectedYearData && filterObj.yearField) {
                values = (selectedYearData[filterObj.yearField] ?? []).map((info) => {
                  return {
                    title: info,
                    token: info,
                  }
                })
              }
              return (
                <Col className="gutter-row" span={6} key={`filter_${filterName}`}>
                  <Typography.Text>{label}</Typography.Text>
                  <SelectFilter
                    defaultValue={getDefaultValueFormParams(location, filterName)}
                    type={filterName}
                    values={values}
                    loadingTotals={totalItemsEachSearch.loading}
                    totalItemsWhenSearch={totalItemsEachSearch.result}
                  />
                </Col>
              )
            })}
            {arrayFiltersInputs.map((filterObj) => {
              const { filter: filterName, label } = filterObj
              return (
                <Col className="gutter-row" span={6} key={`filter_input_${filterName}`}>
                  <Typography.Text>{label}</Typography.Text>
                  <InputSearchFilter
                    value={getDefaultValueFormParams(location, filterName, '')}
                    type={filterName}
                  />
                </Col>
              )
            })}
          </Row>
          <Divider orientation="left">Ordenar</Divider>
          <Row gutter={[16, 24]} justify="start" align="middle">
            <Col className="gutter-row" span={6}>
              <Typography.Text>Ordenar per</Typography.Text>
              <SelectFilter
                defaultValue={sort}
                type={'sort'}
                values={orderOptions}
                appendDefault={false}
              />
            </Col>
            <Col className="gutter-row" span={6}>
              <Typography.Text></Typography.Text>
              <div>
                <Checkbox
                  checked={sortDirection === 'asc'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setLocation({ sort_direction: 'asc', page: 0 })
                    } else {
                      setLocation({ sort_direction: 'des', page: 0 })
                    }
                  }}
                >
                  Invertir
                </Checkbox>
              </div>
            </Col>
          </Row>
          <Divider orientation="left">Accions</Divider>
          {flashMessageInfo.message && (
            <Notification isColor={flashMessageInfo.type}>
              {flashMessageInfo.message}
              <Delete
                onClick={() => {
                  setFlashMessageInfo({
                    message: null,
                    type: 'success',
                  })
                }}
              />
            </Notification>
          )}

          <Button
            className="mr-5"
            type="primary"
            onClick={() => {
              handleDownloadXlsx()
            }}
          >
            Descarregar XLSX
          </Button>
          <Button
            className="mr-5"
            type="primary"
            onClick={() => {
              handleDownloadParadesXlsx()
            }}
          >
            Descarregar XLSX Parades
          </Button>
          <Button
            className="mr-5"
            type="primary"
            onClick={() => {
              setOpenSendEmails(true)
            }}
          >
            Enviar email entitats activades
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setOpenSendRemindToPayEmails(true)
            }}
          >
            Enviar email entitats amb pagaments pendents
          </Button>
          {loadingActions && <div className="progress-line mt-2"></div>}
          <Divider orientation="left">Dades</Divider>

          <div className="columns">
            <div className="column">
              <Pagination
                current={page}
                total={total}
                key="pagination-top"
                doPaginate={doPaginate}
                pager={PageSize}
              />
            </div>
          </div>
          <div style={{ width: '100%', overflow: 'scroll' }}>
            <Table
              rowClassName={(record) => {
                return LLOTJA_STATUS_CLASS[record.review_state]
              }}
              dataSource={items ?? []}
              columns={columns}
              rowKey={(record) => record.id}
              size="middle"
              pagination={false}
              scroll={{ x: 900 }}
            />
          </div>

          <div className="columns">
            <div className="column">
              <Pagination
                current={page}
                total={total}
                key="pagination-top"
                doPaginate={doPaginate}
                pager={PageSize}
              />
            </div>
          </div>
        </>
      )}
      {openSendEmails && (
        <Confirm
          loading={loadingActions}
          onCancel={() => setOpenSendEmails(false)}
          onConfirm={handleSendEmailActivateEns}
          message="Nomès s'enviaràn els emails a les entitats artistes/representants actives, estàs segur d'enviar els emails?"
        />
      )}
      {openSendRemindToPayEmails && (
        <Confirm
          loading={loadingActions}
          onCancel={() => setOpenSendRemindToPayEmails(false)}
          onConfirm={handleSendEmailRemindToPay}
          message="Nomès s'enviaràn els emails a les entitats artistes/representants que tenen pagaments pendents, estàs segur d'enviar els emails?"
        />
      )}
    </div>
  )
}
