import * as React from 'react'
import { Input, Button } from '@guillotinaweb/react-gmi'
import { useSetState } from 'react-use'
import { v4 as uuidv4 } from 'uuid'

export const DateStartEndField = ({ value, onChange, dataTest, ...rest }) => {
  const [currentValue, setCurrentValue] = useSetState({
    id: uuidv4(),
    date: undefined,
    start: undefined,
    end: undefined,
  })

  const addDates = (event) => {
    onChange([...value, event])
  }

  return (
    <div className="control">
      <div className="tags">
        {value.map((date, index) => (
          <div key={`input_list_${date.id}_${index}`} className="tag is-info is-medium">
            {`${new Intl.DateTimeFormat('ca').format(new Date(date.date))} ${date.start}-${
              date.end
            }`}
            <button
              className="delete is-small"
              onClick={() => onChange([...value.filter((itemDate) => itemDate.id !== date.id)])}
            />
          </div>
        ))}
      </div>
      <div className="d-flex">
        <Input
          value={currentValue.date ?? ''}
          dataTest={`${dataTest}_date`}
          onChange={(ev) => setCurrentValue({ date: ev !== '' ? ev : null })}
          type="date"
          placeholder="Data"
          {...rest}
        />
        <Input
          value={currentValue.start ?? ''}
          dataTest={`${dataTest}_start`}
          onChange={(ev) => setCurrentValue({ start: ev !== '' ? ev : null })}
          type="time"
          placeholder="Inici"
          {...rest}
        />
        <Input
          value={currentValue.end ?? ''}
          dataTest={`${dataTest}_end`}
          onChange={(ev) => setCurrentValue({ end: ev !== '' ? ev : null })}
          type="time"
          placeholder="Fi"
          {...rest}
        />
        <Button
          className="is-small is-primary mb-4"
          onClick={() => {
            addDates(currentValue)
            setCurrentValue({
              id: uuidv4(),
              date: undefined,
              start: undefined,
              end: undefined,
            })
          }}
        >
          Add
        </Button>
      </div>
    </div>
  )
}
