import { getItemParsed } from './utils'

export const arrayVocabulariesFilters = [
  {
    filter: 'ambits',
    label: 'Ambits',
    resultObj: 'areaObj',
  },
  {
    filter: 'public',
    label: 'Public',
    resultObj: 'audienceObj',
  },
  {
    filter: 'caracteristiques',
    label: "Ubicació de l'espectacle",
    resultObj: 'featuresObj',
  },
  {
    filter: 'estat_intern',
    vocabularyName: 'estat_proposta',
    label: 'Estat',
    resultObj: 'proposalStatusObj',
  },
]

export const arrayFilters = [
  {
    filter: 'language_artist',
    label: 'Idioma',
    values: [
      {
        title: 'Català',
        token: 'lang-ca',
      },
      {
        title: 'Español',
        token: 'lang-es',
      },
      {
        title: 'English',
        token: 'lang-en',
      },
    ],
  },
  ...arrayVocabulariesFilters,
]

export const arrayFiltersInputs = [
  {
    filter: 'nom',
    label: 'Espectacle',
  },
  {
    filter: 'title_artist',
    label: 'Artista',
  },
  {
    filter: 'title_representant',
    label: 'Representant',
  },
]

export const getParamsFilters = (location) => {
  let result = []
  const resultQueryParams = []
  arrayFilters.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  arrayFiltersInputs.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  return [result, resultQueryParams]
}
