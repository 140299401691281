import { Collapse, Divider, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useTraversal, ItemModel } from '@guillotinaweb/react-gmi'
import { useState, useRef, useMemo } from 'react'
import { get } from 'helpers/utils'

import { DeleteItem } from './DeleteItem'
import { VocabularyForm } from './VocabularyForm'

function compare(a, b) {
  if (a[1].default.toLowerCase() < b[1].default.toLowerCase()) {
    return -1
  }
  if (a[1].default.toLowerCase() > b[1].default.toLowerCase()) {
    return 1
  }
  return 0
}

export function VocabularyItem({ field = 'vocabulari_eix' }) {
  const traversal = useTraversal()
  const fieldRef = useRef(null)

  const [currentToEdit, setCurrentToEdit] = useState(null)
  const [addMode, setAddMode] = useState(false)
  const { item: model } = new ItemModel(traversal.context)

  const allItems = useMemo(() => {
    const dict = get(model, field, {})
    // Create items array
    const items = Object.keys(dict).map(function (key) {
      return [key, dict[key]]
    })
    items.sort(compare)
    const sortedObj = {}
    items.forEach((obj) => {
      sortedObj[obj[0]] = obj[1]
    })
    return sortedObj
  }, [model])

  const scrollIntoView = () => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' })
    }
  }

  return (
    <>
      <Typography.Title level={3}>{`Name: ${field}`}</Typography.Title>
      {(currentToEdit || addMode) && (
        <>
          <VocabularyForm
            ref={fieldRef}
            field={field}
            allItems={allItems}
            keyItem={currentToEdit}
            initData={get(model, `${field}.${currentToEdit}`, null)}
            onCancel={() => {
              setCurrentToEdit(null)
              setAddMode(false)
            }}
          />
          <Divider />
        </>
      )}
      {Object.keys(allItems).length === 0 && (
        <Typography.Title level={5}>No hi ha valors del vocabluari</Typography.Title>
      )}
      <div data-test="wrapperTotalVocabulariesItemsTest">
        {Object.keys(allItems).map((keyItem, index) => {
          return (
            <Collapse key={`vocabulary_${field}_${keyItem}`}>
              <Collapse.Panel
                header={
                  <span
                    data-test={`vocabularyItemTest-${keyItem}`}
                  >{`${allItems[keyItem]['default']}`}</span>
                }
                key={index}
                extra={
                  <>
                    <DeleteItem field={field} keyItem={keyItem} />
                    <EditOutlined
                      data-test={`editVocabularyItemTest-${keyItem}`}
                      onClick={(event) => {
                        setCurrentToEdit(keyItem)
                        scrollIntoView()
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation()
                      }}
                    />
                  </>
                }
              >
                {Object.keys(allItems[keyItem]).map((key) => {
                  return (
                    <div
                      key={`vocabulary_${field}_${keyItem}_${key}`}
                      data-test={`vocabularyItemTest-${keyItem}-${key}`}
                    >
                      <strong>{key}</strong>:&nbsp; {allItems[keyItem][key]}
                    </div>
                  )
                })}
              </Collapse.Panel>
            </Collapse>
          )
        })}
      </div>

      <Divider />
      <button
        className="button"
        data-test="btnAddNewItemTest"
        onClick={() => {
          setCurrentToEdit(null)
          setAddMode(true)
          scrollIntoView()
        }}
      >
        Add new item
      </button>
    </>
  )
}
