import { AccreditationForm } from './AccreditationForm'

export const AccreditationPressForm = ({ onSubmit, title, loading, dataTest, actionName }) => (
  <AccreditationForm
    onSubmit={onSubmit}
    title={title}
    loading={loading}
    dataTest={dataTest}
    actionName={actionName}
    type="AcreditacioPremsa"
  />
)
