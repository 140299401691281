import { useSetState } from 'react-use'
import { useEffect } from 'react'
import { useGuillotinaClient, useTraversal } from '@guillotinaweb/react-gmi'

export function useVocabulary(vocabularyName, path) {
  const traversal = useTraversal()
  const client = useGuillotinaClient()

  const [vocabulary, setVocabulary] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })

  const getPath = () => {
    if (path) return path
    return `/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${traversal.client.getYearFromPath(
      traversal.path
    )}/`
  }

  useEffect(() => {
    ;(async () => {
      if (vocabularyName && vocabulary.data === undefined && !vocabulary.loading) {
        try {
          setVocabulary({ loading: true })
          const dataJson = await client.getVocabulary(getPath(), vocabularyName)
          setVocabulary({ loading: false, data: dataJson })
        } catch (err) {
          setVocabulary({ loading: false, error: err, data: {} })
        }
      }
    })()
  }, [vocabularyName, vocabulary, path])

  return vocabulary
}
