import { get } from 'helpers/utils'
import { useGetValueVocabularyDynamic } from './useGetValueVocabularyDynamic'

export function useGetValueVocabularyDynamicBySchema({ schema, val }) {
  const isMultiple = schema.type === 'array'
  const vocabularyName = isMultiple
    ? get(schema, 'items.vocabularyName', null)
    : get(schema, 'vocabularyName', null)
  return useGetValueVocabularyDynamic({ isMultiple, vocabularyName, val })
}
