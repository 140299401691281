import React, { useEffect } from 'react'
import { useSetState } from 'react-use'
import {
  useTraversal,
  ItemModel,
  Icon,
  PropertiesButtonView,
  BehaviorsView,
} from '@guillotinaweb/react-gmi'

import { PropertiesDetail, WorkflowLlotja } from 'components/Properties'
import { Divider } from 'antd'

export function PanelProperties() {
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const traversal = useTraversal()
  const modifyContent = traversal.hasPerm('guillotina.ModifyContent')

  const model = new ItemModel(traversal.context)

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'Estand')
          setSchema({ loading: false, data: dataJson })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  return (
    <div className="container">
      <div className="level">
        <div className="level-left">
          <h2 className="title is-size-4 is-primary">
            <Icon icon={model.icon} align="is-left" className="has-text-grey" />
            &nbsp;
            <span>{traversal.context.title || traversal.context['@name']}</span>
          </h2>
        </div>
        <div className="level-right">
          <PropertiesButtonView />
        </div>
      </div>
      <hr />
      {schema && schema.data && !schema.loading && (
        <>
          {modifyContent && (
            <>
              <Divider orientation="left">Workflow</Divider>
              <WorkflowLlotja />
            </>
          )}
          <PropertiesDetail
            schema={schema.data}
            model={model.item}
            canModified={modifyContent}
            ignoreFields={[
              'guillotina.behaviors.dublincore.IDublinCore',
              'guillotina.contrib.workflows.interfaces.IWorkflowBehavior',
              '__behaviors__',
              'type_name',
              'creation_date',
              'modification_date',
            ]}
          />
          <BehaviorsView context={traversal.context} schema={schema.data} />
        </>
      )}
    </div>
  )
}
