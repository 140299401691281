import { Fragment, useEffect } from 'react'
import { useSetState } from 'react-use'
import {
  useTraversal,
  buildQs,
  parser,
  useGuillotinaClient,
  useLocation,
} from '@guillotinaweb/react-gmi'
import { PROPOSAL_INTERNAL_STATUS } from 'helpers/constants'
import GuilloCfg from 'guillotina/config'
import { Spin, Divider, Collapse } from 'antd'

import { LogoutOutlined } from '@ant-design/icons'
import { getRelativePath } from 'helpers/utils'
import { PropertiesDetail } from 'components/Properties'

export function CreateShowSection({ renderLink = false }) {
  const traversal = useTraversal()
  const client = useGuillotinaClient()
  const [data, setData] = useSetState({
    shows: undefined,
    loading: false,
    schema: undefined,
  })

  const [, setLocation] = useLocation()

  useEffect(() => {
    ;(async () => {
      if (traversal?.context && data.shows === undefined && !data.loading) {
        setData({ loading: true })
        const searchProposal = parser(
          `proposta_original=${client.cleanPathWithContainer(traversal.context['@id'])}`
        )
        const qs = buildQs([...searchProposal])
        const response = await client.search(GuilloCfg.url, qs, false, false, 0, 5)
        const resultState = {}
        if (response && !response.error) {
          resultState['shows'] = response.items
        }

        if (response.items_total > 0) {
          const jsonSchema = await traversal.client.getTypeSchema(traversal.path, 'Espectacle')
          resultState['schema'] = jsonSchema
        }

        resultState['loading'] = false
        setData(resultState)
      }
    })()
  }, [traversal, data])

  const hasPerm = (perms) => {
    return perms.some((perm) => traversal.hasPerm(perm))
  }

  const onAction = () => {
    traversal.doAction('createShowFromProposal', {
      item: traversal.context,
    })
  }

  if (data.loading) {
    return (
      <div className="p-4">
        <Spin />
      </div>
    )
  }

  return (
    <Fragment>
      {hasPerm(['fm.GestionarPropostes']) &&
        data.shows !== undefined &&
        data.shows.length === 0 &&
        traversal?.context?.estat_intern === PROPOSAL_INTERNAL_STATUS.PACTADA && (
          <Fragment>
            <Divider orientation="left"></Divider>
            <button
              key={`panel_action_crear_espectacle`}
              className="button mr-4"
              onClick={onAction}
              data-test="btnCreateShowTest"
            >
              Crear espectacle
            </button>
          </Fragment>
        )}
      {renderLink && data.shows !== undefined && data.shows.length === 1 && (
        <Fragment>
          <Divider orientation="left"></Divider>
          <Collapse>
            <Collapse.Panel
              header={`Espectacle: ${data.shows[0].title}`}
              key="1"
              extra={
                <LogoutOutlined
                  onClick={(event) => {
                    setLocation({ path: getRelativePath(data.shows[0]['@id']) })
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation()
                  }}
                />
              }
            >
              <PropertiesDetail
                schema={data.schema}
                model={data.shows[0]}
                ignoreFields={[
                  'guillotina.behaviors.dublincore.IDublinCore',
                  'guillotina.behaviors.attachment.IMultiAttachment',
                  '__behaviors__',
                  'type_name',
                  'creation_date',
                  'modification_date',
                  'proposta_original',
                  'artistes',
                ]}
              />
            </Collapse.Panel>
          </Collapse>
        </Fragment>
      )}
    </Fragment>
  )
}
