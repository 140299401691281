import React, { useEffect } from 'react'
import { useSetState } from 'react-use'

import {
  BehaviorsView,
  Icon,
  ItemModel,
  PropertiesButtonView,
  TraversalContext,
} from '@guillotinaweb/react-gmi'

import { PropertiesDetail } from 'components/Properties'
import { AcreditatItem } from './Properties/acreditatItem'

const ignoreFiels = [
  'guillotina.behaviors.dublincore.IDublinCore',
  'guillotina.contrib.workflows.interfaces.IWorkflowBehavior',
  '__behaviors__',
  'type_name',
  'creation_date',
  'modification_date',
  'acreditat',
  'acreditat',
  'entitat_uuid',
]

export function PanelProperties() {
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const [acreditatSchema, setAcreditatSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const Ctx = React.useContext(TraversalContext)
  const modifyContent = Ctx.hasPerm('guillotina.ModifyContent')

  const model = new ItemModel(Ctx.context)

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await Ctx.client.getTypeSchema(Ctx.path, model.type)
          setSchema({ loading: false, data: dataJson })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  useEffect(() => {
    ;(async () => {
      if (!acreditatSchema.loading && !acreditatSchema.data && !acreditatSchema.error) {
        try {
          setAcreditatSchema({ loading: true })
          const dataJson = await Ctx.client.getTypeSchema(Ctx.path, 'AcreditacioProfessional')
          setAcreditatSchema({ loading: false, data: dataJson })
        } catch (err) {
          setAcreditatSchema({ loading: false, error: err })
        }
      }
    })()
  }, [acreditatSchema])

  return (
    <div className="container">
      <div className="level">
        <div className="level-left">
          <h2 className="title is-size-4 is-primary">
            <Icon icon={model.icon} align="is-left" className="has-text-grey" />
            &nbsp;
            <span>{Ctx.context.title || Ctx.context['@name']}</span>
          </h2>
        </div>
        <div className="level-right">
          <PropertiesButtonView />
        </div>
      </div>
      <hr />

      {schema &&
        schema.data &&
        !schema.loading &&
        acreditatSchema &&
        acreditatSchema.data &&
        !acreditatSchema.loading && (
          <>
            <AcreditatItem schema={acreditatSchema} ignoreFiels={ignoreFiels} />
            <PropertiesDetail
              schema={schema.data}
              model={model.item}
              canModified={modifyContent}
              ignoreFields={ignoreFiels}
            />
            <BehaviorsView context={Ctx.context} schema={schema.data} />
          </>
        )}
    </div>
  )
}
