import { useMemo } from 'react'
import { PAYMENT_TYPE_LINES } from 'helpers/constants'

const valuesLines = {
  inscription_payment: 'Inscripció',
  inscription_payment_discount_promo: 'Descompte inscripció codi promocional',
  inscription_payment_discount_member: 'Descompte inscripció membre associació',
  inscription_payment_discount_stand: 'Descompte inscripció al afegir estand',
  stand_payment: 'Preu estand',
  accreditation_payment: 'Preu acreditació',
  shop_payment: 'Preu parada',
  shop_payment_discount_crafts: 'Descompte parada artesana/artesà',
  payment_fira_discount: 'Descompte ofert per la fira ',
}

export const PaymentDetail = ({ payment }) => {
  const subTotal = useMemo(() => {
    return payment.informacio_pagament
      .filter((line) => line.type === PAYMENT_TYPE_LINES.AMOUNT)
      .reduce((a, b) => ({ value: parseFloat(a.value) + parseFloat(b.value) }), { value: 0 })[
      'value'
    ]
  }, [payment.informacio_pagament])

  const totalDiscount = useMemo(() => {
    return payment.informacio_pagament
      .filter((line) => line.type === PAYMENT_TYPE_LINES.DISCOUNT)
      .reduce((a, b) => ({ value: parseFloat(a.value) + parseFloat(b.value) }), { value: 0 })[
      'value'
    ]
  }, [payment.informacio_pagament])

  return (
    <table className="table is-striped is-fullwidth is-size-7">
      <thead>
        <tr>
          <th>Concepte</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
        {payment.informacio_pagament.map((line) => (
          <tr key={line['title']}>
            <td>{valuesLines[line.title]}</td>
            <td>
              {PAYMENT_TYPE_LINES.DISCOUNT === line.type && '-'}&nbsp;{line.value}&nbsp;€
            </td>
          </tr>
        ))}

        {totalDiscount !== 0 && (
          <>
            <tr>
              <td>Subtotal</td>
              <td>{subTotal}&nbsp;€</td>
            </tr>
            <tr>
              <td>Descompte</td>
              <td>{totalDiscount}&nbsp;€</td>
            </tr>
          </>
        )}
        <tr>
          <td>
            <b>Total</b>
          </td>
          <td>{payment.preu_final}&nbsp;€</td>
        </tr>
      </tbody>
    </table>
  )
}
