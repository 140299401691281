import { getItemParsed } from './utils'

export const arrayVocabulariesFilters = [
  {
    filter: 'pais',
    vocabularyName: 'countriesfm',
    label: 'País',
    resultObj: 'paisObj',
  },
  {
    filter: 'ens_type_name',
    label: 'Tipus entitat',
    values: [
      {
        title: 'Professional',
        token: 'EnsProfessional',
      },
      {
        title: 'Premsa',
        token: 'EnsPremsa',
      },
      {
        title: 'Serveis',
        token: 'EnsServeis',
      },
      {
        title: 'Artistes/Representants',
        token: 'Ens',
      },
    ],
  },
  {
    filter: 'ens_tipus',
    label: 'Tipus entitat Artista/Representant',
    values: [
      {
        title: 'Artista',
        token: 'artista',
      },
      {
        title: 'Representant',
        token: 'representant',
      },
    ],
  },
  {
    filter: 'te_factura',
    label: 'Factura',
    values: [
      {
        title: 'Si',
        token: 'true',
      },
      {
        title: 'No',
        token: 'false',
      },
    ],
  },
]

export const arrayFilters = [
  ...arrayVocabulariesFilters,
  {
    filter: 'review_state',
    label: 'Estat',
    values: [
      {
        title: 'Privat',
        token: 'privat',
      },
      {
        title: 'Pendent',
        token: 'pendent',
      },
      {
        title: 'Pagat',
        token: 'pagat',
      },
    ],
  },
]

export const arrayFiltersInputs = [
  {
    filter: 'num',
    label: 'Número',
  },
  {
    filter: 'ens_title',
    label: 'Titol entitat',
  },
]

export const getParamsFilters = (location) => {
  let result = []
  const resultQueryParams = []
  arrayFilters.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  arrayFiltersInputs.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  return [result, resultQueryParams]
}
