import React from 'react'

import { EditableField, useTraversal } from '@guillotinaweb/react-gmi'

const renderFields = ['review_state']
const editableFiels = ['review_state']

export function IWorkflow({ properties, values }) {
  const Ctx = useTraversal()
  const modifyContent = Ctx.hasPerm('guillotina.ModifyContent')

  return (
    <React.Fragment>
      {Object.keys(properties).map(
        (key) =>
          renderFields.includes(key) && (
            <tr key={'dublin_' + key}>
              <td key={1}>{key}</td>
              <td key={2}>
                <EditableField
                  field={key}
                  value={values[key]}
                  ns="guillotina.behaviors.dublincore.IDublinCore"
                  schema={properties[key]}
                  modifyContent={modifyContent && editableFiels.includes(key)}
                />
              </td>
            </tr>
          )
      )}
    </React.Fragment>
  )
}
