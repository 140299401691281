import { parser } from '@guillotinaweb/react-gmi'

export const ATTRIBUTES_WITHOUT_IN_FILTER = [
  'reporter_grafic',
  'te_factura',
  'total_acreditats',
  'pendent_rebre_pagament',
  'obrador_arrel',
  'entrada_exhaurida',
  'cancelat',
]
export const getItemParsed = (result, resultQueryParams, location, filter) => {
  const itemParam = location.get(filter)
  resultQueryParams.push(itemParam)
  let filterParsed = undefined
  if (itemParam && itemParam !== '') {
    if (ATTRIBUTES_WITHOUT_IN_FILTER.includes(filter)) {
      filterParsed = parser(itemParam, filter)
    } else {
      filterParsed = parser(itemParam, `${filter}__in`)
    }
  }
  return [...result, ...(filterParsed || [])]
}

export const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  // Find value if exist return otherwise return undefined value;
  return pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj) || defValue
}

export const getRelativePath = (path) => {
  if (path) {
    path = path.split(process.env.REACT_APP_GUILLOTINA)
  }

  if (path && path.length > 0) {
    return `/${path[1]}/`
  } else {
    return '/'
  }
}

export const getQueryParam = (location, item, defaultValue) => {
  let value
  try {
    value = location.get(item) || defaultValue
  } catch {
    value = defaultValue
  }
  return value
}

export const getErrorMessage = (dataError, defaultValue) => {
  if (dataError && dataError.details) {
    return dataError.details
  } else if (dataError && dataError.reason) {
    return dataError.reason
  }
  return defaultValue
}

export const processResponse = async (res, readyBody = true) => {
  if (res.status < 400)
    return {
      isError: false,
      loading: false,
      result: readyBody ? await res.json() : res.status,
      response: res,
    }
  else
    return {
      isError: true,
      loading: false,
      errorMessage: getErrorMessage(await res.json(), res.status),
      response: res,
    }
}

export const getDefaultValueFormParams = (location, value, defaultValue = 'all') => {
  return location.get(value) ? location.get(value) : defaultValue
}
