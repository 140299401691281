import React from 'react'

import { Confirm, sleep, useConfig, useTraversal } from '@guillotinaweb/react-gmi'

// BBB guillotina 5
const getId = (item) => {
  if (item['@id']) {
    return item['@id']
  }
  return item['@absolute_url']
}

export function UnpublishItems(props) {
  console.log(props)
  const Ctx = useTraversal()
  const cfg = useConfig()
  const [loading, setLoading] = React.useState(false)
  const { items = [] } = props

  async function unpublishItems() {
    const errors = []
    setLoading(true)

    const actions = items.map(async (item) => {
      console.log(item)
      const res = await Ctx.client.post(`${getId(item)}/@workflow/amagar`)
      if (!res.ok) {
        const err = await res.json()
        errors.push(err)
      }
    })
    // this sleep is here, to let elasticsearch, wait for
    // index our operations... (will work 99% of use cases)
    actions.push(sleep(cfg.DelayActions))

    await Promise.all(actions)

    if (errors.length === 0) {
      Ctx.flash(`Items unpublished!`, 'success')
      Ctx.refresh()
    } else {
      const errorstr = errors.map((err) => JSON.stringify(err)).join('\n')
      Ctx.flash(`Something went wrong!! ${errorstr}`, 'danger')
    }
    setLoading(false)
    Ctx.cancelAction()
  }

  return (
    <Confirm
      loading={loading}
      onCancel={() => Ctx.cancelAction()}
      onConfirm={unpublishItems}
      message={
        <>
          <p className="mb-2">Are you sure to unpublish these elements?</p>
          {items.map((item) => (
            <div className="mb-1" key={item.id}>{`${item['@name']}`}</div>
          ))}
        </>
      }
    />
  )
}
