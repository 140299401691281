import {
  Confirm,
  stringToSlug,
  useGuillotinaClient,
  useLocation,
  useTraversal,
} from '@guillotinaweb/react-gmi'
import { processResponse } from 'helpers/utils'
import { useState } from 'react'

export function CreateShow(props) {
  const Ctx = useTraversal()
  const [, navigate] = useLocation()
  const client = useGuillotinaClient()
  const [loading, setLoading] = useState(false)
  const { item } = props

  const getId = () => {
    let result = ''

    if (item.nom) {
      result = `${stringToSlug(item.nom)}_`
    }
    return stringToSlug(`${result}${item.parent['title']}`)
  }

  async function createShow() {
    setLoading(true)
    const data = {
      '@type': 'Espectacle',
      id: getId(),
      title: item.nom,
      artista: item.parent.title,
      pais: item.pais,
      comunitat_autonoma: item.comunitat_autonoma,
      comarca: item.comarca ?? [],
      localitat: item.localitat,
      durada: item.durada,
      franja: item.franja,
      seccio: 'oficial',
      altres_ambits: item.altres_ambits,
      ambit: item.ambits,
      estrena: item.estrena,
      idioma: item.idioma,
      public_familiar: item.public === 'familiar',
      public: item.public,
      proposta_original: client.cleanPathWithContainer(item['@id']),
      artistes: [client.cleanPathWithContainer(item.parent['@id'])],
    }

    let processResult = {}

    try {
      const response = await client.post(
        `${client.cleanPath(item['@id']).split('/').splice(0, 3).join('/')}/espectacles`,
        data
      )
      processResult = await processResponse(response, true)
    } catch (e) {
      console.error('Error', e)
      processResult = { isError: true, errorMessage: 'unhandled exception' }
    }
    setLoading(false)
    const { isError, errorMessage, result } = processResult

    if (!isError) {
      navigate({
        path: `/${client.cleanPath(result['@id'])}/`,
        tab: '',
      })
      Ctx.flash(`Created!`, 'success')
    } else {
      Ctx.flash(`Failed to create!: ${errorMessage}`, 'danger')
    }

    Ctx.refresh()
    Ctx.cancelAction()
  }

  return (
    <Confirm
      loading={loading}
      onCancel={() => Ctx.cancelAction()}
      onConfirm={createShow}
      message={`Are you sure to create it?`}
    />
  )
}
