export const vocabulariesMapKeyLabels = {
  'lang-notext': 'Sense text',
  'lang-aa': 'магIарул мацI',
  'lang-ab': 'бызшәа',
  'lang-ae': 'avesta',
  'lang-af': 'Afrikaans',
  'lang-ak': 'Akan',
  'lang-am': 'አማርኛ',
  'lang-an': 'Aragonés',
  'lang-ar': 'العربية',
  'lang-as': 'অসমিয়া',
  'lang-ay': 'Aymara',
  'lang-az': 'Azəri Türkçəsi',
  'lang-ba': 'Bashkir',
  'lang-be': 'Беларускі',
  'lang-bg': 'Български',
  'lang-bh': 'Bihari',
  'lang-bi': 'Bislama',
  'lang-bm': 'bamanankan',
  'lang-bn': 'বাংলা',
  'lang-bo': 'བོད་སྐད་',
  'lang-br': 'brezhoneg',
  'lang-bs': 'Bosanski',
  'lang-ca': 'Català',
  'lang-ce': 'нохчийн мотт',
  'lang-ch': 'Chamor',
  'lang-co': 'Cors',
  'lang-cr': 'ᓀᐦᐃᔭᐍᐏᐣ',
  'lang-cs': 'Čeština',
  'lang-c': 'ѩзыкъ словѣньскъ',
  'lang-cv': 'чӑваш чӗлхи',
  'lang-cy': 'Cymraeg',
  'lang-da': 'Dansk',
  'lang-de': 'Deutsch',
  'lang-dv': 'Divehi',
  'lang-dz': 'Bhutani',
  'lang-ee': 'Eʋegbe',
  'lang-el': 'Ελληνικά',
  'lang-en': 'English',
  'lang-eo': 'Esperanto',
  'lang-es': 'Español',
  'lang-et': 'Eesti',
  'lang-e': 'Euskara',
  'lang-fa': 'فارسی',
  'lang-ff': 'Fulfulde',
  'lang-fi': 'Suomi',
  'lang-fj': 'Fiji',
  'lang-fo': 'Føroyska',
  'lang-fr': 'Français',
  'lang-fy': 'Frysk',
  'lang-ga': 'Gaeilge',
  'lang-gd': 'Gàidhlig',
  'lang-gl': 'Galego',
  'lang-gn': 'Guarani',
  'lang-g': 'ગુજરાતી',
  'lang-gv': 'Gaelg',
  'lang-ha': 'هَوُس',
  'lang-he': 'עברית',
  'lang-hi': 'हिंदी',
  'lang-ho': 'Hiri Mot',
  'lang-hr': 'Hrvatski',
  'lang-ht': 'Kreyòl ayisyen',
  'lang-h': 'Magyar',
  'lang-hy': 'Հայերէն',
  'lang-hz': 'Otjiherero',
  'lang-ia': 'Interlingua',
  'lang-id': 'Bahasa Indonesia',
  'lang-ie': 'Interlingue',
  'lang-ig': 'Asụsụ Igbo',
  'lang-ii': 'Nuos',
  'lang-ik': 'Iñupiaq',
  'lang-io': 'Ido',
  'lang-is': 'Íslenska',
  'lang-it': 'Italià',
  'lang-i': 'ᐃᓄᒃᑎᑐᑦ',
  'lang-ja': '日本語',
  'lang-jv': 'Javanese',
  'lang-ka': 'ქართული',
  'lang-kg': 'KiKongo',
  'lang-ki': 'Gĩkũyũ',
  'lang-kj': 'Kuanyama',
  'lang-kk': 'ﻗﺎﺯﺍﻗﺸﺎ',
  'lang-kl': 'Greenlandic',
  'lang-km': 'ខ្មែរ',
  'lang-kn': 'ಕನ್ನಡ',
  'lang-ko': '한국어',
  'lang-kr': 'Kanuri',
  'lang-ks': 'काऽशुर',
  'lang-k': 'Kurdí',
  'lang-kv': 'коми кыв',
  'lang-kw': 'Kernewek',
  'lang-ky': 'Кыргыз',
  'lang-la': 'Latin',
  'lang-lb': 'Lëtzebuergesch',
  'lang-lg': 'Luganda',
  'lang-li': 'Limburgs',
  'lang-ln': 'Lingala',
  'lang-lo': 'ພາສາລາວ',
  'lang-lt': 'Lietuviskai',
  'lang-l': 'Tshiluba',
  'lang-lv': 'Latvieš',
  'lang-mg': 'Malagasy',
  'lang-mh': 'Kajin M̧ajeļ',
  'lang-mi': 'Maori',
  'lang-mk': 'Македонски',
  'lang-ml': 'മലയാളം',
  'lang-mn': 'Монгол',
  'lang-mo': 'Moldavian',
  'lang-mr': 'मराठी',
  'lang-ms': 'Bahasa Melay',
  'lang-mt': 'Malti',
  'lang-my': 'Burmese',
  'lang-na': 'Naur',
  'lang-nb': 'Norsk bokmål',
  'lang-nd': 'Ndebele (North)',
  'lang-ne': 'नेपाली',
  'lang-ng': 'Owambo',
  'lang-nl': 'Nederlands',
  'lang-nn': 'Nynorsk',
  'lang-no': 'Norsk',
  'lang-nr': 'IsiNdebele',
  'lang-nv': 'Diné bizaad',
  'lang-ny': 'chiCheŵa',
  'lang-oc': 'Occitan',
  'lang-oj': 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
  'lang-om': 'Oromo',
  'lang-or': 'ଓଡ଼ିଆ',
  'lang-os': 'ирон æвзаг',
  'lang-pa': 'ਪੰਜਾਬੀ',
  'lang-pi': 'पाऴि',
  'lang-pl': 'Polski',
  'lang-ps': 'پښتو',
  'lang-pt': 'Português',
  'lang-q': 'Quechua',
  'lang-rm': 'Rhaeto-Romance',
  'lang-rn': 'Kirundi',
  'lang-ro': 'Română',
  'lang-r': 'Русский',
  'lang-rw': 'Kinyarwanda',
  'lang-sa': 'संस्कृत',
  'lang-sc': 'sard',
  'lang-sd': 'Sindhi',
  'lang-se': 'Northern Sámi',
  'lang-sg': 'Sangho',
  'lang-sh': 'Serbo-Croatian',
  'lang-si': 'Singhalese',
  'lang-sk': 'Slovenčina',
  'lang-sl': 'Slovenščina',
  'lang-sm': 'Samoan',
  'lang-sn': 'Shona',
  'lang-so': 'Somali',
  'lang-sq': 'Shqip',
  'lang-sr': 'српски',
  'lang-ss': 'SiSwati',
  'lang-st': 'Sesotho',
  'lang-s': 'Sudanese',
  'lang-sv': 'Svenska',
  'lang-sw': 'Kiswahili',
  'lang-ta': 'தமிழ',
  'lang-te': 'తెలుగు',
  'lang-tg': 'Тоҷики',
  'lang-th': 'ไทย',
  'lang-ti': 'ትግርኛ',
  'lang-tk': 'түркmенче',
  'lang-tl': 'Tagalog',
  'lang-tn': 'Setswana',
  'lang-to': 'Tonga',
  'lang-tr': 'Türkçe',
  'lang-ts': 'Xitsonga',
  'lang-tt': 'татарча',
  'lang-tw': 'Twi',
  'lang-ty': 'Reo Tahiti',
  'lang-ug': 'Uigur',
  'lang-uk': 'Українська',
  'lang-ur': 'اردو',
  'lang-uz': 'Ўзбекча',
  'lang-ve': 'Tshivenḓa',
  'lang-vi': 'Tiếng Việt',
  'lang-vk': 'Ovalingo',
  'lang-vo': 'Volapük',
  'lang-wa': 'Walon',
  'lang-wo': 'Wolof',
  'lang-xh': 'IsiXhosa',
  'lang-yi': 'ײִדיש',
  'lang-yo': 'Yorùbá',
  'lang-za': 'Zhuang',
  'lang-zh': '中文',
  'lang-z': 'IsiZul',
  //
  'country-AF': 'Afghanistan',
  'country-AN': 'Andorra',
  'country-AX': 'Aland Islands',
  'country-AL': 'Albania',
  'country-DZ': 'Algeria',
  'country-AS': 'American Samoa',
  'country-AD': 'Andorra',
  'country-AO': 'Angola',
  'country-AI': 'Anguilla',
  'country-AQ': 'Antarctica',
  'country-AG': 'Antigua and Barbuda',
  'country-AR': 'Argentina',
  'country-AM': 'Armenia',
  'country-AW': 'Aruba',
  'country-AU': 'Australia',
  'country-AT': 'Austria',
  'country-AZ': 'Azerbaijan',
  'country-BS': 'Bahamas',
  'country-BH': 'Bahrain',
  'country-BD': 'Bangladesh',
  'country-BB': 'Barbados',
  'country-BY': 'Belarus',
  'country-BE': 'Belgium',
  'country-BZ': 'Belize',
  'country-BJ': 'Benin',
  'country-BM': 'Bermuda',
  'country-BT': 'Bhutan',
  'country-BO': 'Bolivia, Plurinational State of',
  'country-BQ': 'Bonaire, Sint Eustatius and Saba',
  'country-BA': 'Bosnia and Herzegovina',
  'country-BW': 'Botswana',
  'country-BV': 'Bouvet Island',
  'country-BR': 'Brazil',
  'country-IO': 'British Indian Ocean Territory',
  'country-BN': 'Brunei Darussalam',
  'country-BG': 'Bulgaria',
  'country-BF': 'Burkina Faso',
  'country-BI': 'Burundi',
  'country-KH': 'Cambodia',
  'country-CM': 'Cameroon',
  'country-CA': 'Canada',
  'country-CV': 'Cape Verde',
  'country-KY': 'Cayman Islands',
  'country-CF': 'Central African Republic',
  'country-TD': 'Chad',
  'country-CL': 'Chile',
  'country-CN': 'China',
  'country-CX': 'Christmas Island',
  'country-CC': 'Cocos (Keeling Islands)',
  'country-CO': 'Colombia',
  'country-KM': 'Comoros',
  'country-CG': 'Congo',
  'country-CD': 'Congo, The Democratic Republic of the',
  'country-CK': 'Cook Islands',
  'country-CR': 'Costa Rica',
  'country-CI': "Cote D'ivoire",
  'country-HR': 'Croatia',
  'country-CU': 'Cuba',
  'country-CW': 'Curaeao',
  'country-CY': 'Cyprus',
  'country-CZ': 'Czech Republic',
  'country-DK': 'Denmark',
  'country-DJ': 'Djibouti',
  'country-DM': 'Dominica',
  'country-DO': 'Dominican Republic',
  'country-EC': 'Ecuador',
  'country-EG': 'Egypt',
  'country-SV': 'El Salvador',
  'country-GQ': 'Equatorial Guinea',
  'country-ER': 'Eritrea',
  'country-EE': 'Estonia',
  'country-ET': 'Ethiopia',
  'country-FK': 'Falkland Islands (Malvinas)',
  'country-FO': 'Faroe Islands',
  'country-FJ': 'Fiji',
  'country-FI': 'Finland',
  'country-FR': 'França',
  'country-GF': 'French Guiana',
  'country-PF': 'French Polynesia',
  'country-TF': 'French Southern Territories',
  'country-GA': 'Gabon',
  'country-GM': 'Gambia',
  'country-GE': 'Georgia',
  'country-DE': 'Germany',
  'country-GH': 'Ghana',
  'country-GI': 'Gibraltar',
  'country-GR': 'Greece',
  'country-GL': 'Greenland',
  'country-GD': 'Grenada',
  'country-GP': 'Guadeloupe',
  'country-GU': 'Guam',
  'country-GT': 'Guatemala',
  'country-GG': 'Guernsey',
  'country-GN': 'Guinea',
  'country-GW': 'Guinea-bissau',
  'country-GY': 'Guyana',
  'country-HT': 'Haiti',
  'country-HM': 'Heard Island and McDonald Islands',
  'country-VA': 'Holy See (Vatican City State)',
  'country-HN': 'Honduras',
  'country-HK': 'Hong Kong',
  'country-HU': 'Hungary',
  'country-IS': 'Iceland',
  'country-IN': 'India',
  'country-ID': 'Indonesia',
  'country-IR': 'Iran, Islamic Republic of',
  'country-IQ': 'Iraq',
  'country-IE': 'Ireland',
  'country-IM': 'Isle of Man',
  'country-IL': 'Israel',
  'country-IT': 'Italy',
  'country-JM': 'Jamaica',
  'country-JP': 'Japan',
  'country-JE': 'Jersey',
  'country-JO': 'Jordan',
  'country-KZ': 'Kazakhstan',
  'country-KE': 'Kenya',
  'country-KI': 'Kiribati',
  'country-KP': "Korea, Democratic People's Republic of",
  'country-KR': 'Korea, Republic of',
  'country-KW': 'Kuwait',
  'country-KG': 'Kyrgyzstan',
  'country-LA': "Lao People's Democratic Republic",
  'country-LV': 'Latvia',
  'country-LB': 'Lebanon',
  'country-LS': 'Lesotho',
  'country-LR': 'Liberia',
  'country-LY': 'Libya',
  'country-LI': 'Liechtenstein',
  'country-LT': 'Lithuania',
  'country-LU': 'Luxembourg',
  'country-MO': 'Macao',
  'country-MK': 'Macedonia, The Former Yugoslav Republic of',
  'country-MG': 'Madagascar',
  'country-MW': 'Malawi',
  'country-MY': 'Malaysia',
  'country-MV': 'Maldives',
  'country-ML': 'Mali',
  'country-MT': 'Malta',
  'country-MH': 'Marshall Islands',
  'country-MQ': 'Martinique',
  'country-MR': 'Mauritania',
  'country-MU': 'Mauritius',
  'country-YT': 'Mayotte',
  'country-MX': 'Mexico',
  'country-FM': 'Micronesia, Federated States of',
  'country-MD': 'Moldova, Republic of',
  'country-MC': 'Monaco',
  'country-MN': 'Mongolia',
  'country-ME': 'Montenegro',
  'country-MS': 'Montserrat',
  'country-MA': 'Morocco',
  'country-MZ': 'Mozambique',
  'country-MM': 'Myanmar',
  'country-NA': 'Namibia',
  'country-NR': 'Nauru',
  'country-NP': 'Nepal',
  'country-NL': 'Netherlands',
  'country-NC': 'New Caledonia',
  'country-NZ': 'New Zealand',
  'country-NI': 'Nicaragua',
  'country-NE': 'Niger',
  'country-NG': 'Nigeria',
  'country-NU': 'Niue',
  'country-NF': 'Norfolk Island',
  'country-MP': 'Northern Mariana Islands',
  'country-NO': 'Norway',
  'country-OM': 'Oman',
  'country-PK': 'Pakistan',
  'country-PW': 'Palau',
  'country-PS': 'Palestinian Territory, Occupied',
  'country-PA': 'Panama',
  'country-PG': 'Papua New Guinea',
  'country-PY': 'Paraguay',
  'country-PE': 'Peru',
  'country-PH': 'Philippines',
  'country-PN': 'Pitcairn',
  'country-PL': 'Poland',
  'country-PT': 'Portugal',
  'country-PR': 'Puerto Rico',
  'country-QA': 'Qatar',
  'country-QE': 'Quebec',
  'country-RE': 'Reunion',
  'country-RO': 'Romania',
  'country-RU': 'Russian Federation',
  'country-RW': 'Rwanda',
  'country-BL': 'Saint Barthelemy',
  'country-SH': 'Saint Helena, Ascension and Tristan Da Cunha',
  'country-KN': 'Saint Kitts and Nevis',
  'country-LC': 'Saint Lucia',
  'country-MF': 'Saint Martin (French Part)',
  'country-PM': 'Saint Pierre and Miquelon',
  'country-VC': 'Saint Vincent and the Grenadines',
  'country-WS': 'Samoa',
  'country-SM': 'San Marino',
  'country-ST': 'Sao Tome and Principe',
  'country-SA': 'Saudi Arabia',
  'country-SN': 'Senegal',
  'country-RS': 'Serbia',
  'country-SP': 'Serbia',
  'country-SC': 'Seychelles',
  'country-SL': 'Sierra Leone',
  'country-SG': 'Singapore',
  'country-SX': 'Sint Maarten (Dutch Part)',
  'country-SK': 'Slovakia',
  'country-SI': 'Slovenia',
  'country-SB': 'Solomon Islands',
  'country-SO': 'Somalia',
  'country-ZA': 'South Africa',
  'country-GS': 'South Georgia and the South Sandwich Islands',
  'country-SS': 'South Sudan',
  'country-ES': 'Espanya',
  'country-LK': 'Sri Lanka',
  'country-SD': 'Sudan',
  'country-SR': 'Suriname',
  'country-SJ': 'Svalbard and Jan Mayen',
  'country-SZ': 'Swaziland',
  'country-SE': 'Sweden',
  'country-CH': 'Switzerland',
  'country-SY': 'Syrian Arab Republic',
  'country-TW': 'Taiwan, Province of China',
  'country-TJ': 'Tajikistan',
  'country-TZ': 'Tanzania, United Republic of',
  'country-TH': 'Thailand',
  'country-TL': 'Timor-leste',
  'country-TG': 'Togo',
  'country-TK': 'Tokelau',
  'country-TO': 'Tonga',
  'country-TT': 'Trinidad and Tobago',
  'country-TN': 'Tunisia',
  'country-TR': 'Turkey',
  'country-TM': 'Turkmenistan',
  'country-TC': 'Turks and Caicos Islands',
  'country-TV': 'Tuvalu',
  'country-UG': 'Uganda',
  'country-UA': 'Ukraine',
  'country-AE': 'United Arab Emirates',
  'country-GB': 'Regne Unit',
  'country-US': "Estats Units d'Amèrica",
  'country-UM': 'United States Minor Outlying Islands',
  'country-UY': 'Uruguay',
  'country-UZ': 'Uzbekistan',
  'country-VU': 'Vanuatu',
  'country-VE': 'Venezuela, Bolivarian Republic of',
  'country-VN': 'Viet Nam',
  'country-VG': 'Virgin Islands, British',
  'country-VI': 'Virgin Islands, U.S.',
  'country-WF': 'Wallis and Futuna',
  'country-EH': 'Western Sahara',
  'country-YE': 'Yemen',
  'country-ZM': 'Zambia',
  'country-ZW': 'Zimbabwe',
  //
  estrena_si_catalunya: 'Sí, estrena a Catalunya',
  estrena_si_estat: 'Sí, estrena a l’Estat',
  estrena_si_absoluta: 'Sí, estrena absoluta a la Fira',
  estrena_enproces: 'Espectacle en procés de creació durant els dies de la 24a Fira Mediterrània',
  si: 'Sí',
  no: 'No',
  //
  andalucia: 'Andalusia',
  aragon: 'Aragó',
  asturias: "Principat d'Astúries",
  balears: 'Illes Balears',
  canarias: 'Canaries',
  cantabria: 'Cantàbria',
  castilla_la_mancha: 'Castella la Mancha',
  castilla_y_leon: 'Castella Lleó',
  catalunya: 'Catalunya',
  extremadura: 'Extremadura',
  galicia: 'Galicia',
  rioja: 'La Rioja',
  comunidad_de_madrid: 'Comunitat de Madrid',
  murcia: 'Regió de Murcia',
  navarra: 'Navarra',
  pais_vasco: 'País Basc',
  valencia: 'País Valencià',
  //
  alt_camp: 'Alt Camp',
  alt_emporda: 'Alt Empordà',
  alt_penedes: 'Alt Penedès',
  alt_urgell: 'Alt Urgell',
  alta_ribagorca: 'Alta Ribagorça',
  anoia: 'Anoia',
  bages: 'Bages',
  baix_camp: 'Baix Camp',
  baix_ebre: 'Baix Ebre',
  baix_emporda: 'Baix Empordà',
  baix_llobregat: 'Baix Llobregat',
  baix_penedes: 'Baix Penedès',
  barcelones: 'Barcelonès',
  bergueda: 'Berguedà',
  cerdanya: 'Cerdanya',
  conca_barbera: 'Conca de Barberà',
  garraf: 'Garraf',
  garrigues: 'Garrigues',
  garrotja: 'Garrotxa',
  girones: 'Gironès',
  maresme: 'Maresme',
  moisanes: 'Moianès',
  montsia: 'Montsià',
  noguera: 'Noguera',
  osona: 'Osona',
  pallars_jussa: 'Pallars Jussà',
  pallars_sobira: 'Pallars Sobirà',
  pla_durgell: "Pla d'Urgell",
  pla_estany: "Pla de l'Estany",
  priorat: 'Priorat',
  ribera_ebre: "Ribera d'Ebre",
  ripolles: 'Ripollès',
  segarra: 'Segarra',
  segria: 'Segrià',
  selva: 'Selva',
  solsones: 'Solsonès',
  tarragones: 'Tarragonès',
  terra_alta: 'Terra Alta',
  urgell: 'Urgell',
  vall_aran: "Vall d'Aran",
  valles_occidental: 'Vallès Occidental',
  valles_oriental: 'Vallès Oriental',
  //
  exterior: 'Carrer (exterior)',
  interior: 'Sala (interior)',
  //
  concert: 'Concert',
  ball: 'Ball folk',
  //
  adults: 'Adults',
  familiar: 'Per a tots els públics',
  //
  mirades:
    "Mirades (Propostes d'artistes que no treballen habitualment amb l'arrel i que presenten la seva particular mirada cap a la cultura popular i tradicional)",
  joves:
    "Propostes d'artistes emergents que ja de partida han centrat el seu treball creatiu a partir de l'arrel tradicional",
  present:
    "Propostes que posen l'accent en una arrel creativa que no viu aliena a la realitat que ens envolta i que a partir del llenguatge d'arrel i de la cultura popular i tradicional ens pot parlar i reflexionar del que passa avui a la societat",
  cap: 'No encaixa en cap de les claus anteriors',
  //
  estand4: 'Estand de 4m2',
  estand6: 'Estand de 6m2',
  openarea: 'Àrea oberta, a partir de 4m2',
}

export const vocabulariesGetLabelByKey = (key) => {
  if (key in vocabulariesMapKeyLabels) {
    return vocabulariesMapKeyLabels[key]
  }
  return key
}

export const typesMapKeyLabels = {
  Ens: 'Entitat artistes/representants',
  EnsPremsa: 'Entitat premsa',
  EnsProfessional: 'Entitat professional',
  EnsServeis: 'Entitat serveis',
  AcreditacioPremsa: 'Acreditació de premsa',
  AcreditacioProfessional: 'Acreditació professional',
}

export const typesGetLabelByKey = (key) => {
  if (key in typesMapKeyLabels) {
    return typesMapKeyLabels[key]
  }
  return key
}
