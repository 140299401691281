import { parser, buildQs } from '@guillotinaweb/react-gmi'
export const dynamicFunctionsPaymentForm = {
  getQueryObjectsPayment: (uuid) => {
    return [
      ...parser(`type_name__not=Pagament`),
      ['__or', buildQs([...parser(`parent_uuid=${uuid}`), ...parser(`uuid=${uuid}`)])],
    ]
  },
  getPathObjectsPayment: (model, client) => {
    return `/${client.getYearFromPath(model.item['@id'])}/`
  },
}
