import { memo } from 'react'
import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useLocation, useGuillotinaClient } from '@guillotinaweb/react-gmi'
const { Option } = Select

const antIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />

const SelectComponent = ({
  values = [],
  defaultValue = 'all',
  type = '',
  appendDefault = true,
  totalItemsWhenSearch = {},
  loadingTotals = false,
  onChange = null,
}) => {
  const [, setLocation, remove] = useLocation()
  const client = useGuillotinaClient()
  const haveTotals = type in totalItemsWhenSearch

  const getTotals = (key) => {
    if (loadingTotals) {
      return <Spin className="ml-5" indicator={antIcon} />
    }
    if (haveTotals) {
      client.setCacheSearchResults(totalItemsWhenSearch[type][key].query, {
        items: null,
        total: totalItemsWhenSearch[type][key].total.toString(),
      })
      return `(${totalItemsWhenSearch[type][key].total})`
    }
    return ''
  }
  return (
    <Select
      defaultValue={defaultValue}
      size={'large'}
      style={{ width: '100%' }}
      onChange={(value) => {
        if (onChange) {
          onChange(value)
        } else {
          if (value === 'all') {
            remove(type)
          } else {
            setLocation({ [type]: value, page: 0 })
          }
        }
      }}
    >
      {appendDefault && (
        <Option value="all">
          Tots
          {getTotals('total')}
        </Option>
      )}
      {values.map((item) => {
        return (
          <Option key={item.token} value={item.token} className="select-options-all-text">
            {item.title.default ?? item.title}
            {getTotals(item.token)}
          </Option>
        )
      })}
    </Select>
  )
}

export const SelectFilter = memo(SelectComponent)
SelectFilter.displayName = 'SelectFilter'
export default SelectFilter
