import React from 'react'

import { Guillotina, useGuillotinaClient } from '@guillotinaweb/react-gmi'
import GuilloCfg from './config'

export function Guillo() {
  const client = useGuillotinaClient()
  return (
    <div className="guillotina">
      <Guillotina
        client={client}
        config={GuilloCfg.Cfg}
        url={GuilloCfg.url}
        registry={GuilloCfg.registry}
      />
    </div>
  )
}
