import { useSetState } from 'react-use'
import { useEffect } from 'react'
import { useTraversal, useGuillotinaClient } from '@guillotinaweb/react-gmi'

export function useVocabularies(allVocabularies, path) {
  const traversal = useTraversal()
  const client = useGuillotinaClient()
  const [vocabularies, setVocabularies] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })

  const getPath = () => {
    if (path) return path
    return `/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${traversal.client.getYearFromPath(
      traversal.path
    )}/`
  }

  const getVocabulary = async (vocabularyName) => {
    try {
      return await client.getVocabulary(getPath(), vocabularyName)
    } catch (err) {
      return {}
    }
  }

  useEffect(() => {
    ;(async () => {
      if (allVocabularies && vocabularies.data === undefined && !vocabularies.loading) {
        setVocabularies({ loading: true })
        const result = {}
        for (let index = 0; index < allVocabularies.length; index++) {
          const vocName =
            allVocabularies[index]['vocabularyName'] ?? allVocabularies[index]['filter']
          const data = await getVocabulary(vocName)
          result[allVocabularies[index]['resultObj']] = data.items
        }
        setVocabularies({ loading: false, data: result })
      }
    })()
  }, [allVocabularies, vocabularies, path])

  return vocabularies
}
