import { memo } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

const BooleanRenderComponent = ({ data }) => {
  if (data) {
    return <CheckOutlined />
  }
  return <CloseOutlined />
}

const BooleanRender = memo(BooleanRenderComponent)
BooleanRender.displayName = 'BooleanRender'
export default BooleanRender
