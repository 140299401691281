import React, { Fragment, useState } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Confirm, useCrudContext } from '@guillotinaweb/react-gmi'

export function DeleteItem({ field, keyItem }) {
  const [open, setOpen] = useState(false)
  const { patch, loading, Ctx } = useCrudContext()

  const doAction = async () => {
    const data = {
      [field]: {
        op: 'del',
        value: keyItem,
      },
    }
    const dataPatch = await patch(data)
    if (dataPatch.isError) {
      Ctx.flash(`Error when delete vocabulary value ${keyItem}!`, 'danger')
    } else {
      Ctx.flash(`Vocabulary value ${keyItem}, deleted!`, 'success')
    }

    Ctx.refresh()
  }

  return (
    <Fragment>
      {open && (
        <Confirm
          loading={loading}
          onCancel={() => setOpen(false)}
          onConfirm={doAction}
          message={`Are you sure to vocabulary value ${keyItem}?`}
        />
      )}
      <DeleteOutlined
        className="mr-2"
        data-test={`deleteVocabularyItemTest-${keyItem}`}
        onClick={(event) => {
          setOpen(true)
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation()
        }}
      />
    </Fragment>
  )
}
