import React from 'react'
import { useSetState } from 'react-use'
import {
  ItemsActionsProvider,
  AllItemsCheckbox,
  ItemsActionsDropdown,
} from '@guillotinaweb/react-gmi'
import { Pagination } from '@guillotinaweb/react-gmi'
import { RItem } from '../Items'
import { SearchLabels } from '@guillotinaweb/react-gmi'
import { useTraversal } from '@guillotinaweb/react-gmi'
import { buildQs } from '@guillotinaweb/react-gmi'
import { parser } from '@guillotinaweb/react-gmi'
import { useConfig } from '@guillotinaweb/react-gmi'
import { useEffect } from 'react'
import { useLocation } from '@guillotinaweb/react-gmi'
import { Icon } from '@guillotinaweb/react-gmi'
import { get } from 'helpers/utils'
import { MAP_TYPE_NAME_LABEL } from 'helpers/constants'

const initialState = {
  page: 0,
  items: [],
  loading: true,
  total: 0,
}

const getColumns = () => {
  const smallcss = { width: 25 }
  const mediumcss = { width: 120 }
  return [
    {
      label: '',
      child: (m) => <td style={smallcss}>{<Icon icon={m.icon} />}</td>,
    },
    {
      label: 'type',
      child: (m, navigate) => (
        <td style={smallcss} onClick={navigate}>
          <span className="tag">{MAP_TYPE_NAME_LABEL[m.type]}</span>
        </td>
      ),
    },
    {
      label: 'id/name',
      child: (m, navigate, search) => {
        return (
          <td onClick={navigate}>
            {m.title || get(m, 'item.nom', null) || m.name}
            {search && (
              <React.Fragment>
                <br />
                <span className="is-size-7 tag is-light">{m.path}</span>
              </React.Fragment>
            )}
          </td>
        )
      },
    },
    {
      label: 'created',
      child: (m) => (
        <td style={mediumcss} className="is-size-7 is-vcentered">
          {m.created}
        </td>
      ),
    },
    {
      label: 'Status',
      child: (m) => {
        return (
          <td style={mediumcss} className="is-size-7 is-vcentered">
            {get(m, 'item.review_state', '')}
          </td>
        )
      },
    },
    {
      label: 'modified',
      child: (m) => (
        <td style={mediumcss} className="is-size-7 is-vcentered">
          {m.updated}
        </td>
      ),
    },
  ]
}

const depthParsed = parser('depth=1')

export function PanelItems() {
  const [location, setLocation] = useLocation()
  const { PageSize } = useConfig()

  const Ctx = useTraversal()
  const [state, setState] = useSetState(initialState)
  const { items, loading, total } = state
  const columns = getColumns()

  const search = location.get('q')
  const type = location.get('type')

  let page

  try {
    page = parseInt(location.get('page')) || 0
  } catch {
    page = 0
  }

  let searchParsed = undefined
  if (search && search !== '') {
    searchParsed = parser(search)
  }

  let typeParsed = undefined
  if (type && type !== '') {
    typeParsed = parser(type, 'type_name')
  }

  useEffect(() => {
    if (Ctx.state.loading) return
    ;(async () => {
      let data
      setState({ loading: true, total: Ctx.context.length })
      if (search || type) {
        const qs = buildQs([...(searchParsed ?? []), ...(typeParsed ?? []), ...depthParsed])
        data = await Ctx.client.search(Ctx.path, qs, false, false, page * PageSize, PageSize)
      } else {
        data = await Ctx.client.getItems(Ctx.path, page * PageSize, PageSize)
      }
      setState({
        items: data.member,
        loading: false,
        total: data.items_count,
      })
    })()
  }, [search, type, Ctx.state.refresh, page])

  const doPaginate = (page) => {
    setLocation({ page: page })
  }

  return (
    <ItemsActionsProvider items={items}>
      <div className="columns">
        <div className="column is-2 is-size-7">
          <ItemsActionsDropdown />
        </div>
        <div className="column">
          <SearchLabels />
        </div>
        <div className="column">
          <Pagination
            current={page}
            total={total}
            key="pagination-top"
            doPaginate={doPaginate}
            pager={PageSize}
          />
        </div>
      </div>
      {loading && <div className="progress-line"></div>}
      {!loading && (
        <table className="table is-fullwidth is-hoverable">
          <thead className="is-size-7">
            <tr>
              <th>
                <AllItemsCheckbox />
              </th>
              {columns.map((i) => (
                <th key={i.label} className="has-text-info">
                  {i.label}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item) => (
                <RItem item={item} key={item['@uid']} search={search} columns={columns} />
              ))}
            {items && items.length === 0 && (
              <tr>
                <td colSpan="6" className="has-text-centered">
                  Anything here!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div className="columns is-centered">
        <Pagination
          current={page}
          key="pagination-bottom"
          total={total}
          doPaginate={doPaginate}
          pager={PageSize}
        />
      </div>
    </ItemsActionsProvider>
  )
}
