import React from 'react'
import { GuillotinaClient } from '@guillotinaweb/react-gmi'
import { Icon, RestClient, toQueryString } from '@guillotinaweb/react-gmi'
import { get } from 'helpers/utils'
import { MAP_TYPE_NAME_LABEL } from 'helpers/constants'

const cacheSchemas = {}
let cacheVocabularies = {}

export class BasicAuthClient extends RestClient {
  async request(path, data, headers) {
    data = data || {}
    data.headers = headers || this.getHeaders()
    return await fetch(`${this.url}${path}`, data)
  }

  handlePath(path) {
    if (path.indexOf(process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT) !== -1) {
      return path.split(process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT)[1]
    }
    return path
  }

  getHeaders() {
    const authToken = this.auth.getToken()
    if (!authToken) return {}
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authToken,
    }
  }

  async post(path, data) {
    path = this.handlePath(path)
    return await this.request(path, {
      method: 'post',
      body: JSON.stringify(data),
    })
  }

  async get(path) {
    path = this.handlePath(path)
    return await this.request(path, {})
  }

  async put(path, data) {
    path = this.handlePath(path)
    return await this.request(path, {
      method: 'put',
      body: JSON.stringify(data),
    })
  }

  async patch(path, data) {
    path = this.handlePath(path)
    return await this.request(path, {
      method: 'PATCH',
      body: JSON.stringify(data),
    })
  }

  async upload(path, data) {
    path = this.handlePath(path)

    const headers = this.getHeaders()
    delete headers['Content-Type']
    headers['Content-Type'] = data['content-type']
    headers['X-UPLOAD-FILENAME'] = data.filename
    headers['Content-Encoding'] = 'base64'
    return await this.request(
      path,
      {
        method: 'PATCH',
        body: data.data,
      },
      headers
    )
  }

  async delete(path, data = undefined) {
    path = this.handlePath(path)
    return await this.request(path, {
      method: 'delete',
      body: JSON.stringify(data),
    })
  }
}

export class FiraClient extends GuillotinaClient {
  constructor(rest, isContainer) {
    super(rest, isContainer)
    this.cacheSearchResults = {}
  }

  setCacheSearchResults(key, data) {
    this.cacheSearchResults[key] = data
  }

  cleanPath(path) {
    return path.replace(process.env.REACT_APP_GUILLOTINA, '').replace('//', '/')
  }

  cleanPathWithContainer(path) {
    return path.replace(this.rest.url, '')
  }

  getYearFromPath(path) {
    let clean = this.cleanPath(path)
    if (clean.startsWith('/')) {
      clean = clean.slice(1)
    }

    const splitCleanPath = clean.split('/')
    if (splitCleanPath.length > 2) {
      return splitCleanPath[2]
    }
    return undefined
  }

  getItemsColumn() {
    const smallcss = { width: 25 }
    const mediumcss = { width: 120 }

    return [
      {
        label: '',
        child: (m) => <td style={smallcss}>{<Icon icon={m.icon} />}</td>,
      },
      {
        label: 'type',
        child: (m, navigate) => (
          <td style={smallcss} onClick={navigate}>
            <span className="tag">{MAP_TYPE_NAME_LABEL[m.type]}</span>
          </td>
        ),
      },
      {
        label: 'id/name',
        child: (m, navigate, search) => {
          return (
            <td onClick={navigate}>
              {m.title || get(m, 'item.nom', null) || m.name}
              {search && (
                <React.Fragment>
                  <br />
                  <span className="is-size-7 tag is-light">{m.path}</span>
                </React.Fragment>
              )}
            </td>
          )
        },
      },
      {
        label: 'created',
        child: (m) => (
          <td style={mediumcss} className="is-size-7 is-vcentered">
            {m.created}
          </td>
        ),
      },
      {
        label: 'modified',
        child: (m) => (
          <td style={mediumcss} className="is-size-7 is-vcentered">
            {m.updated}
          </td>
        ),
      },
    ]
  }

  async getTypeSchema(path, name) {
    if (!cacheSchemas[name]) {
      const url = getContainerFromPath(path)
      // todo: handle db case (only addable containers)
      const res = await this.rest.get(`${url}@types/${name}`)
      cacheSchemas[name] = await res.json()
    }
    return cacheSchemas[name]
  }

  async getVocabulary(path, name) {
    if (!cacheVocabularies[`${path}@vocabularies/${name}`]) {
      // todo: handle db case (only addable containers)
      const res = await this.rest.get(`${path}@vocabularies/${name}`)

      if (res.ok) {
        cacheVocabularies[`${path}@vocabularies/${name}`] = await res.json()
      } else {
        throw new Error(`Error get ${path}@vocabularies/${name}`)
      }
    }
    return cacheVocabularies[`${path}@vocabularies/${name}`]
  }

  clearVocabulary() {
    cacheVocabularies = {}
  }

  async getGroups(path) {
    const endpoint = `${getContainerFromPath(path)}@groups`
    return await this.rest.get(endpoint)
  }

  async getUsers(path) {
    const endpoint = `${getContainerFromPath(path)}@users`
    return await this.rest.get(endpoint)
  }

  async getRoles(path) {
    const endpoint = `${getContainerFromPath(path)}@available-roles`
    return await this.rest.get(endpoint)
  }

  prepareSearch(path, params, container = false, prepare = true, start = 0, pageSize = 10) {
    if (path.startsWith('/')) {
      path = path.slice(1)
    }
    if (container) {
      path = getContainerFromPath(path)
    }
    const query = prepare ? toQueryString(params) : params
    return `${path}@search?${query}&b_start=${start}&b_size=${pageSize}`
  }

  async search(path, params, container = false, prepare = true, start = 0, pageSize = 10) {
    const url = this.prepareSearch(path, params, container, prepare, start, pageSize)

    const response = await this.rest.get(url)
    if (response.status >= 400) {
      return {
        error: true,
      }
    }
    const data = await response.json()
    return this.applyCompat(data)
  }

  async memoSearch(path, params, container = false, prepare = true, start = 0, pageSize = 10) {
    const url = this.prepareSearch(path, params, container, prepare, start, pageSize)

    if (url in this.cacheSearchResults) {
      return this.cacheSearchResults[url]
    }
    const res = await this.rest.get(url)
    let data = {}
    if (res.status == 200) {
      data = await res.json()
      this.cacheSearchResults[url] = data
    }

    return this.applyCompat(data)
  }
}

export function getClient(url, auth) {
  return new FiraClient(new BasicAuthClient(url, auth))
}

export const getContainerFromPath = (path) => {
  if (path.startsWith('/')) {
    path = path.substring(1)
  }
  const parts = path.split('/')
  if (parts.lenght > 1) {
    return `${parts[0]}/${parts[1]}/`
  }
  return process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT
}
