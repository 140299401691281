import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSetState } from 'react-use'

import { Form, useTraversal } from '@guillotinaweb/react-gmi'
import { Divider } from 'antd'
import { EditComponent } from 'components/Fields'
import { get } from 'helpers/utils'

export const ReunioRapidaForm = ({ onSubmit, title, loading, dataTest, actionName }) => {
  const traversal = useTraversal()

  const [formData, setFormData] = useState({
    horaris: [],
  })
  const [errors, setErrors] = useState({})
  const [orderFieldsForm, setOrderFieldsForm] = useState([
    'title',
    'short_description_ca',
    'short_description_es',
    'short_description_en',
    'ambits',
    'website',
  ])
  const requiredFields = [
    'title',
    'short_description_ca',
    'short_description_es',
    'short_description_en',
  ]

  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
    formFields: [],
  })

  const totalDates = useMemo(() => {
    return orderFieldsForm.filter((item) => item.startsWith('data')).length
  }, [orderFieldsForm])

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'ReunioRapida')
          setSchema({
            loading: false,
            data: dataJson,
          })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const submit = () => {
    const currentErrors = {}

    requiredFields.forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        currentErrors[key] = 'This field is required'
      }
    })

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors)
      return
    }

    const finalData = Object.assign({}, formData)
    onSubmit(finalData)
  }

  return (
    <>
      <button
        type="button"
        className="button is-info mb-3"
        data-test="btnAddMoreDatesReunioRapidaTest"
        onClick={() => {
          orderFieldsForm.splice(totalDates, 0, `horaris_${totalDates}`)
          setOrderFieldsForm(Object.assign([], orderFieldsForm))
        }}
      >
        <span className="icon">
          <i className="fas fa-plus"></i>
        </span>
        <span>Afegeix més horaris</span>
      </button>
      {totalDates > 1 && (
        <button
          type="button"
          className="button is-info mb-3 ml-5"
          data-test="btnSubtractDatesReunioRapidaTest"
          onClick={() => {
            orderFieldsForm.splice(totalDates - 1, 1)
            setOrderFieldsForm(Object.assign([], orderFieldsForm))
          }}
        >
          <span className="icon">
            <i className="fas fa-minus"></i>
          </span>
          <span>Elimina l&apos;última data</span>
        </button>
      )}
      <Form title={title} onSubmit={submit} dataTest={dataTest}>
        {schema &&
          schema.data &&
          !schema.loading &&
          orderFieldsForm.map((key) => {
            if (key.startsWith('horaris')) {
              return (
                <>
                  <EditComponent
                    id={`${key}_data_inici`}
                    schema={{
                      type: 'datetime',
                      title: 'Data inici',
                    }}
                    placeholder="Data inici"
                    required
                    setValue={(ev) => {
                      const index = key.split('horaris_')[1]
                      let currentValue = get(formData, `horaris.${index}`, {})
                      currentValue = { ...currentValue, data_inici: `${ev}:00` }
                      const newTimeTable = [...get(formData, `horaris`, [])]
                      newTimeTable[index] = currentValue
                      setFormData({ ...formData, horaris: newTimeTable })
                    }}
                    error={errors[`${key}_data_inici`]}
                    dataTest={`${key}_data_iniciTestInput`}
                  />
                  <EditComponent
                    id={`${key}_data_fi`}
                    schema={{
                      type: 'datetime',
                      title: 'Data fi',
                    }}
                    placeholder={'Data fi'}
                    required
                    setValue={(ev) => {
                      const index = key.split('horaris_')[1]
                      let currentValue = get(formData, `horaris.${index}`, {})
                      currentValue = { ...currentValue, data_fi: `${ev}:00` }
                      const newTimeTable = [...get(formData, `horaris`, [])]
                      newTimeTable[index] = currentValue
                      setFormData({ ...formData, horaris: newTimeTable })
                    }}
                    error={errors[`${key}_data_fi`]}
                    dataTest={`${key}_data_fiTestInput`}
                  />
                  <EditComponent
                    id={`${key}_interval`}
                    schema={{
                      type: 'number',
                      title: 'Interval',
                    }}
                    placeholder="Interval"
                    required
                    setValue={(ev) => {
                      const index = key.split('horaris_')[1]
                      let currentValue = get(formData, `horaris.${index}`, {})
                      currentValue = { ...currentValue, interval: parseInt(ev) }
                      const newTimeTable = [...get(formData, `horaris`, [])]
                      newTimeTable[index] = currentValue
                      setFormData({ ...formData, horaris: newTimeTable })
                    }}
                    error={errors[`${key}_interval`]}
                    dataTest={`${key}_intervalTestInput`}
                  />
                  <Divider />
                </>
              )
            }

            const value = schema.data.properties[key]
            return (
              <Fragment key={key}>
                <EditComponent
                  id={key}
                  schema={value}
                  val={key in formData ? formData[key] : ''}
                  placeholder={value?.title ?? ''}
                  required={requiredFields.includes(key)}
                  setValue={(ev) => {
                    setFormData({ ...formData, [key]: ev })
                  }}
                  error={errors[key]}
                  dataTest={`${key}TestInput`}
                />
              </Fragment>
            )
          })}

        <div className="level level-right">
          <button
            type="submit"
            className={`button is-success ${loading ? 'is-loading' : ''}`}
            data-test="formBaseBtnTestSubmit"
          >
            {actionName || 'Add'}
          </button>
        </div>
      </Form>
    </>
  )
}
