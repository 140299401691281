import {
  buildQs,
  Confirm,
  Delete,
  Notification,
  Pagination,
  parser,
  useConfig,
  useGuillotinaClient,
  useLocation,
} from '@guillotinaweb/react-gmi'
import { Button, Checkbox, Col, Divider, Row, Table, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useSetState } from 'react-use'

import { InputSearchFilter } from 'components/Filters/InputSearch'
import { SelectFilter } from 'components/Filters/Select'
import { getComponentsByTypeColumns } from 'components/Table/RenderColumns/getComponents'
import GuilloCfg from 'guillotina/config'
import {
  LIMIT_ELEMENTS_GUILLOTINA,
  PROPOSAL_INTERNAL_STATUS_CLASS,
  PROPOSAL_STATUS,
} from 'helpers/constants'
import {
  arrayFilters,
  arrayFiltersInputs,
  arrayVocabulariesFilters,
  getParamsFilters,
} from 'helpers/proposalsFiltersKeys'
import { get, getDefaultValueFormParams, getQueryParam } from 'helpers/utils'
import { vocabulariesGetLabelByKey } from 'helpers/vocabulariesMapKeyLabels'
import { useVocabularies } from 'hooks/useVocabulary'

const columns = [
  {
    title: 'Accions',
    key: 'path',
    dataIndex: 'path',
    render: (data) => getComponentsByTypeColumns({ type: 'actions-view', data }),
  },
  {
    title: 'Data presentació',
    key: 'submit_date',
    dataIndex: 'submit_date',
    render: (data) => new Date(data).toLocaleString(),
  },
  {
    title: 'Estat Intern',
    key: 'estat_intern',
    dataIndex: 'estat_intern',
  },
  {
    title: 'Artista',
    dataIndex: 'title_artist',
    key: 'title_artist',
  },
  {
    title: 'Representant',
    dataIndex: 'title_representant',
    key: 'title_representant',
  },
  {
    title: 'Espectacle',
    dataIndex: 'nom',
    key: 'nom',
  },
  {
    title: 'Estrena',
    key: 'estrena',
    dataIndex: 'estrena',
    render: (data) => vocabulariesGetLabelByKey(data),
  },
  {
    title: 'Ubicació espectacle',
    key: 'caracteristiques',
    dataIndex: 'caracteristiques',
    render: (data) => vocabulariesGetLabelByKey(data),
  },
  {
    title: 'Ambit',
    key: 'ambits',
    dataIndex: 'ambits',
    render: (data) =>
      getComponentsByTypeColumns({
        type: 'vocabulary-dynamic',
        vocabularyName: 'ambits',
        data: [data],
      }),
  },
  {
    title: 'Public',
    key: 'public',
    dataIndex: 'public',
    render: (data) => vocabulariesGetLabelByKey(data),
  },
  {
    title: 'Idioma',
    key: 'language_artist',
    dataIndex: 'language_artist',
    render: (data) => vocabulariesGetLabelByKey(data),
  },
]

const initialState = {
  items: undefined,
  loading: false,
  total: 0,
}

const orderOptions = [
  {
    title: 'Data creacio',
    token: 'creation_date',
  },
  {
    title: 'Data presentació',
    token: 'submit_date',
  },
  {
    title: 'Artista',
    token: 'title_artist',
  },
  {
    title: 'Representant',
    token: 'title_representant',
  },
  {
    title: 'Espectacle',
    token: 'nom',
  },
  {
    title: 'Estat',
    token: 'estat_intern',
  },
  {
    title: 'Àmbit',
    token: 'ambits',
  },
]

const searchParsed = parser('type_name=Proposta')

export function ProposalsPage({ reviewState, onLogout, years, selectedYear, setSelectedYear }) {
  const [flashMessageInfo, setFlashMessageInfo] = useState({
    message: null,
    type: 'success',
  })

  const [loadingActions, setLoadingActions] = useState(false)
  const [openSendEmails, setOpenSendEmails] = useState(false)
  const [openSendEmailsRejected, setOpenSendEmailsRejected] = useState(false)

  const [state, setState] = useSetState(initialState)

  const [totalItemsEachSearch, setTotalItemsEachSearch] = useSetState({
    loading: false,
    result: undefined,
  })

  const [location, setLocation] = useLocation()
  const { PageSize } = useConfig({ PageSize: LIMIT_ELEMENTS_GUILLOTINA })
  const client = useGuillotinaClient()
  const { data: vocabularies, loading: isLoadingVocabularies } = useVocabularies(
    arrayVocabulariesFilters,
    `${GuilloCfg.url}${selectedYear}/`
  )

  const { items, loading, total } = state
  const page = parseInt(getQueryParam(location, 'page', 0))
  const sort = getQueryParam(location, 'sort', 'submit_date')
  const sortDirection = getQueryParam(location, 'sort_direction', 'des')

  const [
    paramsParsed,
    [
      defaultLanguage,
      defaultArea,
      defaultAudience,
      defaultFeatures,
      defaultName,
      defaultArtist,
      defaultManager,
      defaultProposalState,
    ],
  ] = getParamsFilters(location)

  const getStatusParsed = () => {
    let statusParsed = []

    if (reviewState === PROPOSAL_STATUS.DRAFT) {
      statusParsed = parser(`review_state=${PROPOSAL_STATUS.DRAFT}`)
    } else {
      statusParsed = parser(`review_state=${PROPOSAL_STATUS.ACTIVE}`)
    }
    return statusParsed
  }

  useEffect(() => {
    ;(async () => {
      if (!isLoadingVocabularies && selectedYear) {
        setState({ loading: true })
        let sortParsed = {}
        if (sortDirection === 'asc') {
          sortParsed = parser(`_sort_asc=${sort}`)
        } else if (sortDirection === 'des') {
          sortParsed = parser(`_sort_des=${sort}`)
        }

        const qs = buildQs([...searchParsed, ...sortParsed, ...paramsParsed, ...getStatusParsed()])
        const response = await client.search(
          `${GuilloCfg.url}${selectedYear}/`,
          qs,
          false,
          false,
          page * PageSize,
          PageSize
        )
        if (response && response.error) {
          onLogout()
          setState({
            loading: false,
          })
        } else {
          setState({
            items: response.items,
            loading: false,
            total: response.items_total,
          })
        }
      }
    })()
  }, [
    selectedYear,
    page,
    sort,
    sortDirection,
    reviewState,
    defaultLanguage,
    defaultArea,
    defaultAudience,
    defaultFeatures,
    defaultName,
    defaultArtist,
    defaultManager,
    defaultProposalState,
    isLoadingVocabularies,
  ])

  useEffect(() => {
    ;(async () => {
      const payload = {}

      if (!loading && !isLoadingVocabularies && vocabularies !== undefined && selectedYear) {
        setTotalItemsEachSearch({
          loading: true,
        })

        for (let j = 0; j < arrayFilters.length; j++) {
          const { filter, resultObj } = arrayFilters[j]
          payload[filter] = {}

          const cleanedParamsParsed = paramsParsed.filter((params) => {
            return params[0].indexOf(filter) === -1
          })
          const commonFilters = [...searchParsed, ...cleanedParamsParsed, ...getStatusParsed()]

          const totalQs = buildQs(commonFilters)
          payload[filter]['total'] = {
            total: get(
              client.cacheSearchResults,
              [`${selectedYear}/${totalQs}`, 'total'],
              undefined
            ),
            query: totalQs,
          }

          const values = resultObj ? vocabularies[`${resultObj}`] : arrayFilters[j].values

          for (let i = 0; i < values.length; i++) {
            const qs = buildQs([...commonFilters, ...parser(`${filter}=${values[i].token}`)])
            payload[filter][values[i].token] = {
              total: get(client.cacheSearchResults, [`${selectedYear}/${qs}`, 'total'], undefined),
              query: qs,
            }
          }
        }

        const res = await client.post(`${GuilloCfg.url}${selectedYear}/@totalsFilters`, payload)

        if (res.ok) {
          setTotalItemsEachSearch({
            loading: false,
            result: await res.json(),
          })
        } else {
          setTotalItemsEachSearch({
            loading: false,
          })
        }
      }
    })()
  }, [loading, vocabularies, selectedYear])

  const doPaginate = (page) => {
    setLocation({ page: page })
  }

  const handleSendEmailSubmitProposal = async () => {
    setLoadingActions(true)
    const response = await client.get(
      `${GuilloCfg.url}${selectedYear}/@sendEmailProposalsNotSubmited`
    )
    if (response.ok) {
      setFlashMessageInfo({
        message: 'Great! Emails sent',
        type: 'success',
      })
    } else {
      setFlashMessageInfo({
        message: 'Error when sending emails',
        type: 'danger',
      })
    }
    setLoadingActions(false)
    setOpenSendEmails(false)
  }

  const handleSendEmailRejectedProposals = async () => {
    setLoadingActions(true)
    const response = await client.post(
      `${GuilloCfg.url}${selectedYear}/@sendEmailRejectedProposals`
    )
    if (response.ok) {
      setFlashMessageInfo({
        message: 'Great! Emails sent',
        type: 'success',
      })
    } else {
      setFlashMessageInfo({
        message: 'Error when sending emails',
        type: 'danger',
      })
    }

    setLoadingActions(false)
    setOpenSendEmailsRejected(false)
  }

  const handleDownloadXlsx = async () => {
    setLoadingActions(true)
    let sortParsed = {}
    if (sortDirection === 'asc') {
      sortParsed = parser(`_sort_asc=${sort}`)
    } else if (sortDirection === 'des') {
      sortParsed = parser(`_sort_des=${sort}`)
    }
    const qs = buildQs([...searchParsed, ...paramsParsed, ...getStatusParsed(), ...sortParsed])
    const endpoint = `${GuilloCfg.url}${selectedYear}/@getPropostesExcel?${qs}`
    const res = await client.download(endpoint)

    const text = await res.blob()
    const blob = new Blob([text], {
      type: '',
    })
    const url = window.URL.createObjectURL(blob)

    // Create blob link to download
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `propostes.xlsx`)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.parentNode?.removeChild(link)
    setLoadingActions(false)
  }

  return (
    <div className="box main-panel">
      {(loading || isLoadingVocabularies || years.loading || !vocabularies) && (
        <div className="progress-line"></div>
      )}
      {!loading && !isLoadingVocabularies && !years.loading && vocabularies && (
        <>
          {years.total > 1 && (
            <>
              <Divider orientation="left">Edicio</Divider>
              <Row gutter={[16, 24]} justify="start" align="middle">
                <Col className="gutter-row" span={6}>
                  <SelectFilter
                    defaultValue={selectedYear}
                    values={years.items.map((item) => {
                      return {
                        title: item.id,
                        token: item.id,
                      }
                    })}
                    loadingTotals={false}
                    totalItemsWhenSearch={{}}
                    onChange={(val) => {
                      setSelectedYear(val)
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          <Divider orientation="left">Filtres</Divider>
          <Row gutter={[16, 24]} justify="start" align="middle">
            {arrayFilters.map((filterObj) => {
              const { filter: filterName, resultObj, label } = filterObj
              const values = resultObj ? vocabularies[`${resultObj}`] : filterObj.values
              return (
                <Col className="gutter-row" span={6} key={`filter_${filterName}`}>
                  <Typography.Text>{label}</Typography.Text>
                  <SelectFilter
                    defaultValue={getDefaultValueFormParams(location, filterName)}
                    type={filterName}
                    values={values}
                    loadingTotals={totalItemsEachSearch.loading}
                    totalItemsWhenSearch={totalItemsEachSearch.result}
                  />
                </Col>
              )
            })}
            {arrayFiltersInputs.map((filterObj) => {
              const { filter: filterName, label } = filterObj
              return (
                <Col className="gutter-row" span={6} key={`filter_input_${filterName}`}>
                  <Typography.Text>{label}</Typography.Text>
                  <InputSearchFilter
                    value={getDefaultValueFormParams(location, filterName, '')}
                    type={filterName}
                  />
                </Col>
              )
            })}
          </Row>
          <Divider orientation="left">Ordenar</Divider>
          <Row gutter={[16, 24]} justify="start" align="middle">
            <Col className="gutter-row" span={6}>
              <Typography.Text>Ordenar per</Typography.Text>
              <SelectFilter
                defaultValue={sort}
                type={'sort'}
                values={orderOptions}
                appendDefault={false}
              />
            </Col>
            <Col className="gutter-row" span={6}>
              <Typography.Text></Typography.Text>
              <div>
                <Checkbox
                  checked={sortDirection === 'asc'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setLocation({ sort_direction: 'asc', page: 0 })
                    } else {
                      setLocation({ sort_direction: 'des', page: 0 })
                    }
                  }}
                >
                  Invertir
                </Checkbox>
              </div>
            </Col>
          </Row>

          <>
            <Divider orientation="left">Accions</Divider>
            {flashMessageInfo.message && (
              <Notification isColor={flashMessageInfo.type}>
                {flashMessageInfo.message}
                <Delete
                  onClick={() => {
                    setFlashMessageInfo({
                      message: null,
                      type: 'success',
                    })
                  }}
                />
              </Notification>
            )}
            <Button
              className="mr-5"
              type="primary"
              onClick={() => {
                handleDownloadXlsx()
              }}
            >
              Descarregar XLSX
            </Button>
            {reviewState === PROPOSAL_STATUS.DRAFT && (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpenSendEmails(true)
                  }}
                >
                  Enviar email recordatori presentar proposta
                </Button>
              </>
            )}
            {reviewState === PROPOSAL_STATUS.ACTIVE && (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    setOpenSendEmailsRejected(true)
                  }}
                >
                  Enviar email propostes descartades
                </Button>
              </>
            )}
            {loadingActions && <div className="progress-line mt-2"></div>}
          </>

          <Divider orientation="left">Dades</Divider>

          <div className="columns">
            <div className="column">
              <Pagination
                current={page}
                total={total}
                key="pagination-top"
                doPaginate={doPaginate}
                pager={PageSize}
              />
            </div>
          </div>

          <Table
            rowClassName={(record) => {
              return PROPOSAL_INTERNAL_STATUS_CLASS[record.estat_intern]
            }}
            dataSource={items ?? []}
            columns={columns}
            rowKey={(record) => record.id}
            size="middle"
            pagination={false}
            scroll={{ x: 900 }}
          />
          <div className="columns">
            <div className="column">
              <Pagination
                current={page}
                total={total}
                key="pagination-top"
                doPaginate={doPaginate}
                pager={PageSize}
              />
            </div>
          </div>
        </>
      )}
      {openSendEmails && (
        <Confirm
          loading={loadingActions}
          onCancel={() => setOpenSendEmails(false)}
          onConfirm={handleSendEmailSubmitProposal}
          message={`Are you sure to send emails?`}
        />
      )}
      {openSendEmailsRejected && (
        <Confirm
          loading={loadingActions}
          onCancel={() => setOpenSendEmailsRejected(false)}
          onConfirm={handleSendEmailRejectedProposals}
          message={`Are you sure to send emails?`}
        />
      )}
    </div>
  )
}
