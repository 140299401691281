import React, { useEffect } from 'react'
import { useSetState } from 'react-use'
import {
  TraversalContext,
  ItemModel,
  useCrudContext,
  SearchInput,
  parser,
  Confirm,
  useLocation,
} from '@guillotinaweb/react-gmi'
import { LogoutOutlined, EyeOutlined } from '@ant-design/icons'
import { Divider, Collapse, Typography, Spin, Button } from 'antd'

import { PropertiesDetail } from 'components/Properties'
import { ENS_TYPES } from 'helpers/constants'
import { getContainerFromPath } from 'services/client'
import { getRelativePath } from 'helpers/utils'

const searchObjectTypeParsed = parser('type_name=Ens')
const searchTypeParsed = parser('tipus=representant')

const ManagerItemComponent = ({ schema, ignoreFiels }) => {
  const [, setLocation] = useLocation()
  const Ctx = React.useContext(TraversalContext)
  const { patch, loading } = useCrudContext()
  const [open, setOpen] = React.useState(false)
  const modifyContent = Ctx.hasPerm('guillotina.ModifyContent')

  const [manager, setManager] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const model = new ItemModel(Ctx.context)

  useEffect(() => {
    ;(async () => {
      if (
        model &&
        model.item?.tipus === ENS_TYPES.ARTIST &&
        model.item?.representant &&
        !manager.loading &&
        !manager.data &&
        !manager.error
      ) {
        setManager({ loading: true })
        const response = await Ctx.client.get(
          `${getContainerFromPath(Ctx.path)}${model.item.representant}`
        )
        if (response.ok) {
          setManager({ loading: false, data: await response.json() })
        } else {
          setManager({
            loading: false,
            error: 'Error to get manager',
            data: {},
          })
        }

        try {
        } catch (err) {
          setManager({ loading: false, error: err })
        }
      }
    })()
  }, [model])

  const updateManager = async (newManager) => {
    const dataPatch = await patch({
      representant: newManager.path,
    })
    if (dataPatch.isError) {
      Ctx.flash(`Error when update manager ${dataPatch.errorMessage}!`, 'danger')
    } else {
      Ctx.flash(`Manager updated!`, 'success')
    }

    Ctx.refresh()
  }

  return (
    <>
      <Divider orientation="left">Representant</Divider>
      {modifyContent && (
        <>
          <div className="is-flex is-align-items-center">
            <Typography.Text>Afageix o modifica el representant</Typography.Text>
            <SearchInput
              path={`${Ctx.client.cleanPath(model.item.parent['@id'])}/`}
              qs={[...searchObjectTypeParsed, ...searchTypeParsed]}
              client={Ctx.client}
              onChange={updateManager}
              btnClass="is-small ml-4"
              queryCondition="title"
            />
            {loading && (
              <>
                <div className="ml-4 mr-4"> Actualitzant ... </div> <Spin />
              </>
            )}
          </div>
          <Divider />
        </>
      )}

      {manager.data && !manager.loading && (
        <>
          {modifyContent && (
            <>
              <div className="is-flex is-align-items-center">
                <Typography.Text>Elimina el representant</Typography.Text>
                <Button onClick={() => setOpen(true)} className="is-small ml-4">
                  Eliminar
                </Button>
              </div>
              <Divider />
            </>
          )}
          <Collapse>
            <Collapse.Panel
              header={`Representant: ${manager.data.title}`}
              key="1"
              extra={
                <>
                  <EyeOutlined
                    onClick={(event) => {
                      setLocation({ path: getRelativePath(manager.data['@id']) })
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation()
                    }}
                  />
                  <a
                    className="ml-2"
                    onClick={(e) => e.stopPropagation()}
                    style={{ color: '#000' }}
                    href={`/?path=/${getRelativePath(manager.data['@id'])}/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LogoutOutlined />
                  </a>
                </>
              }
            >
              <PropertiesDetail
                schema={schema.data}
                model={manager.data}
                ignoreFields={ignoreFiels}
                linkFields={['facebook', 'instagram', 'linkedin', 'twitter', 'youtube', 'web']}
              />
            </Collapse.Panel>
          </Collapse>
          <Divider />
        </>
      )}
      {model && model.item && !model.item.representant && (
        <>
          <Typography.Title level={5}>Sense representant</Typography.Title>
          <Divider />
        </>
      )}

      {open && (
        <Confirm
          loading={loading}
          onCancel={() => setOpen(false)}
          onConfirm={() => updateManager({ path: null })}
          message={`Are you sure to remove: ${manager.data.title}?`}
        />
      )}
    </>
  )
}

export const ManagerItem = React.memo(ManagerItemComponent)
