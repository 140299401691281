export const LIMIT_ELEMENTS_GUILLOTINA = 100
export const PROPOSAL_STATUS = {
  DRAFT: 'esborrany',
  ACTIVE: 'presentada',
}

export const SHOWS_STATUS = {
  HIDE: 'amagat',
  PUBLISH: 'publicat',
}

export const PROPOSAL_INTERNAL_STATUS_CLASS = {
  proposta: '',
  preseleccionada: 'background-color-yellow',
  descartada: 'background-color-red',
  pactada: 'background-color-green',
}

export const LLOTJA_STATUS_CLASS = {
  pendent: 'background-color-yellow',
  descartat: 'background-color-red',
  publicat: 'background-color-green',
}

export const PAYMENT_STATUS_CLASS = {
  privat: 'background-color-yellow',
  pendent: 'background-color-orange',
  pagat: 'background-color-green',
}

export const ENS_TYPES = {
  MANAGER: 'representant',
  ARTIST: 'artista',
}

export const PROPOSAL_INTERNAL_STATUS = {
  PROPOSTA: 'proposta',
  PRESELECCIONADA: 'preseleccionada',
  DESCARTADA: 'descartada',
  PACTADA: 'pactada',
}

export const PAYMENT_TYPE_LINES = {
  AMOUNT: 'AMOUNT',
  DISCOUNT: 'DISCOUNT',
}

export const MAP_TYPE_NAME_LABEL = {
  AcreditacioProfessional: 'Acreditació Professional',
  AcreditacioPremsa: 'Acreditació Premsa',
  ActivitatsProfessionals: 'Activitats Professionals',
  ActivitatProfessional: 'Activitat Professional',
  Actuacions: 'Actuacions',
  Actuacio: 'Actuació',
  Email: 'Email',
  EnsPremsa: 'Entitat Premsa',
  EnsProfessional: 'Entitat Professional',
  EnsServeis: 'Entitat Serveis',
  Ens: 'Entitat Artista/Representant',
  Entrada: 'Entrada',
  Espectacles: 'Espectacles',
  Espectacle: 'Espectacle',
  Estand: 'Estand',
  Pagament: 'Pagament',
  Parada: 'Parada',
  Proposta: 'Proposta',
  TipologiaActivitatProfessional: 'Tipologia Activitat Professional',
  Account: 'Contenidor',
  Year: 'Any',
  FaqsFolder: 'FaqsFolder',
}
