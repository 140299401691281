import { useEffect, useState } from 'react'
import { get } from 'helpers/utils'
import { useVocabulary } from './useVocabulary'

export function useGetValueVocabularyDynamic({ isMultiple, vocabularyName, val }) {
  const [result, setResult] = useState()
  const vocabulary = useVocabulary(vocabularyName)

  useEffect(() => {
    if (vocabulary.data && val) {
      let value = ''
      if (isMultiple) {
        value = val.map((itemValue) => {
          const obj = get(vocabulary, 'data.items', []).find((voc) => voc.token === itemValue)
          if (obj) {
            return obj.title.default
          }
          return itemValue
        })
      } else {
        const obj = get(vocabulary, 'data.items', []).find((voc) => voc.token === val)
        if (obj) {
          value = obj.title.default
        }
      }
      setResult(value)
    }
  }, [vocabulary])

  return result
}
