import { useState } from 'react'
import { Row, Col } from 'antd'
import { Confirm, useCrudContext, useTraversal, ItemModel } from '@guillotinaweb/react-gmi'

export function WorkflowLlotja() {
  const [workflowAction, setWorkflowAction] = useState(null)
  const { post, loading } = useCrudContext()
  const traversal = useTraversal()
  const model = new ItemModel(traversal.context)
  const currentState =
    model.item['guillotina.contrib.workflows.interfaces.IWorkflowBehavior']['review_state']

  const doWorkflowAction = async () => {
    const { isError, errorMessage } = await post({}, `@workflow/${workflowAction}`, false)

    if (!isError) {
      traversal.flash(`Great status changed!`, 'success')
    } else {
      traversal.flash(`Failed to status changed!: ${errorMessage}`, 'danger')
    }

    traversal.refresh()
    setWorkflowAction(null)
  }

  const getTextByStatus = () => {
    switch (currentState) {
      case 'publicat':
        return 'PUBLICAT'
      case 'descartat':
        return 'DESCARTAT'
      default:
        return 'PENDENT DE VALIDACIÓ'
    }
  }

  return (
    <>
      {workflowAction && (
        <Confirm
          loading={loading}
          onCancel={() => setWorkflowAction(null)}
          onConfirm={doWorkflowAction}
          message={`Are you sure to change state: ${
            traversal.context.title || traversal.context['@name']
          }?`}
        />
      )}
      <Row className="is-flex is-align-items-center">
        <Col span={5} className="has-text-weight-bold" data-test={`textInfoStatus-${currentState}`}>
          {getTextByStatus()}
        </Col>
        {currentState === 'pendent' ? (
          <>
            <button
              className="button mr-4"
              onClick={() => setWorkflowAction('publicar')}
              data-test="btnPublicEnsTest"
            >
              Acceptar/Publicar
            </button>
            <button
              className="button"
              onClick={() => setWorkflowAction('descartar')}
              data-test="btnDenyEnsTest"
            >
              Descartar
            </button>
          </>
        ) : (
          <button
            className="button"
            onClick={() => setWorkflowAction('pendent')}
            data-test="btnPendingEnsTest"
          >
            Retornar a pendent
          </button>
        )}
      </Row>
    </>
  )
}
