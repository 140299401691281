import { useLocation } from '@guillotinaweb/react-gmi'
import { Tabs, Typography } from 'antd'
import { VocabularyItem } from './Vocabulary/VocabularyItem'

export function PanelVocabulary() {
  const [location, setLocation] = useLocation()

  const defaultTab = location.get('vocabulary')
  return (
    <div data-test="panelVocabulariesTest">
      <Typography.Title level={3}>{`Vocabularis dinàmics`}</Typography.Title>

      <Tabs
        defaultActiveKey={defaultTab ?? 'eix'}
        onChange={(key) => {
          setLocation({
            vocabulary: key,
          })
        }}
      >
        <Tabs.TabPane tab="Eix" key="eix">
          <VocabularyItem field="vocabulari_eix" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Espais" key="espai">
          <VocabularyItem field="vocabulari_espai" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rols" key="rol">
          <VocabularyItem field="vocabulari_rol" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Canvis actuacions" key="canvis">
          <VocabularyItem field="vocabulari_canvis" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ambits" key="ambits">
          <VocabularyItem field="vocabulari_ambits" />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ambits reunions ràpides" key="ambits_reunions_rapides">
          <VocabularyItem field="vocabulari_ambits_reunions_rapides" />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
