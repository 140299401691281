import { useEffect, useState, useMemo } from 'react'
import { useSetState } from 'react-use'

import { Form, useTraversal } from '@guillotinaweb/react-gmi'
import { EditComponent } from 'components/Fields'

const constOrderFieldsForm = [
  'title',
  'nom_fiscal',
  'nif',
  'adreca',
  'codi_postal',
  'poblacio',
  'pais',
  'comunitat_autonoma',
  'comarca',
  'telefon',
  'correu',
  'nom_contacte',
  'cognoms_contacte',
  'idioma',
]

const constRequiredFields = [
  'title',
  'nom_fiscal',
  'nif',
  'adreca',
  'codi_postal',
  'poblacio',
  'pais',
  'telefon',
  'correu',
  'nom_contacte',
  'cognoms_contacte',
  'idioma',
]

export const EnsForm = ({ onSubmit, actionName, title, dataTest, type }) => {
  const Ctx = useTraversal()

  const [formData, setFormData] = useState({ tarifa: 'estandard' })
  const [errors, setErrors] = useState({})

  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })

  const orderFieldsForm = useMemo(() => {
    if (type === 'Ens') {
      return ['tipus', ...constOrderFieldsForm]
    }
    return constOrderFieldsForm
  }, [type])

  const requiredFields = useMemo(() => {
    if (type === 'Ens') {
      return ['tipus', ...constRequiredFields]
    }
    return constRequiredFields
  }, [type])

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await Ctx.client.getTypeSchema(Ctx.path, type)
          setSchema({
            loading: false,
            data: dataJson,
          })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const submit = () => {
    const currentErrors = {}

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        currentErrors[key] = 'This field is required'
      }
    })

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors)
      return
    }
    onSubmit(formData)
  }

  const renderField = (key) => {
    if (key === 'comunitat_autonoma' && formData.pais !== 'country-ES') {
      return false
    }
    if (
      key === 'comarca' &&
      (formData.pais !== 'country-ES' || formData.comunitat_autonoma !== 'catalunya')
    ) {
      return false
    }
    return true
  }

  return (
    <Form title={title} onSubmit={submit} dataTest={dataTest}>
      {schema &&
        schema.data &&
        !schema.loading &&
        orderFieldsForm.map((key) => {
          if (renderField(key)) {
            const value = schema.data.properties[key]
            return (
              <EditComponent
                key={key}
                id={key}
                schema={value}
                val={key in formData ? formData[key] : ''}
                placeholder={value?.title ?? ''}
                required={requiredFields.includes(key)}
                setValue={(ev) => {
                  setFormData({ ...formData, [key]: ev })
                }}
                error={errors[key]}
                dataTest={`${key}TestInput`}
              />
            )
          }
          return null
        })}

      <div className="level level-right">
        <button type="submit" className="button is-success" data-test="formBaseBtnTestSubmit">
          {actionName || 'Add'}
        </button>
      </div>
    </Form>
  )
}
