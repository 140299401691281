import React from 'react'
import { ContextToolbar, useTraversal, TabsPanel, PanelActions } from '@guillotinaweb/react-gmi'
import { PanelProperties, PanelItems } from 'components/Panels/Ens'
import { PanelPermissions } from 'components/Panels/Permissions'

const tabs = {
  Items: PanelItems,
  Properties: PanelProperties,
  Permissions: PanelPermissions,
  Actions: PanelActions,
}

const tabsPermissions = {
  Items: 'guillotina.ViewContent',
  Properties: 'guillotina.ViewContent',
  Permissions: 'guillotina.SeePermissions',
}

export function EnsProfessionalView(props) {
  const traversal = useTraversal()
  const calculated = traversal.filterTabs(tabs, tabsPermissions)
  return (
    <TabsPanel
      tabs={calculated}
      currentTab="Items"
      editableTabs={[]}
      rightToolbar={<ContextToolbar {...props} />}
      {...props}
    />
  )
}
