import { SHOWS_STATUS } from './constants'
import { getItemParsed } from './utils'

export const arrayVocabulariesFilters = [
  {
    filter: 'ambits',
    vocabularyName: 'ambits',
    label: 'Ambit',
    resultObj: 'areaObj',
  },
  {
    filter: 'eix',
    label: 'Eix',
    vocabularyName: 'eix',
    resultObj: 'eixObj',
  },
  {
    filter: 'espai',
    label: 'Espai',
    vocabularyName: 'espai',
    resultObj: 'espaiObj',
  },
  {
    filter: 'seccions',
    label: 'Secció',
    vocabularyName: 'seccio',
    resultObj: 'seccioObj',
  },
]

export const arrayFilters = [
  ...arrayVocabulariesFilters,
  {
    filter: 'review_state',
    label: 'Estat',
    values: [
      {
        token: SHOWS_STATUS.HIDE,
        title: 'esborrany',
      },
      {
        token: SHOWS_STATUS.PUBLISH,
        title: 'publicat',
      },
    ],
  },
  {
    filter: 'obrador_arrel',
    label: "Obrador d'arrel",
    values: [
      {
        title: 'Si',
        token: true,
      },
      {
        title: 'No',
        token: false,
      },
    ],
  },
  {
    filter: 'entrada_exhaurida',
    label: 'Entrades exhaurides',
    values: [
      {
        title: 'Si',
        token: true,
      },
      {
        title: 'No',
        token: false,
      },
    ],
  },
  {
    filter: 'cancelat',
    label: 'Cancel·lat',
    values: [
      {
        title: 'Si',
        token: true,
      },
      {
        title: 'No',
        token: false,
      },
    ],
  },
]

export const arrayFiltersInputs = [
  {
    filter: 'shows_names',
    label: 'Nom espectacle',
  },
]

export const getParamsFilters = (location) => {
  let result = []
  const resultQueryParams = []
  arrayFilters.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  arrayFiltersInputs.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  return [result, resultQueryParams]
}
