import { useEffect } from 'react'
import { useSetState } from 'react-use'
import { useTraversal, useGuillotinaClient, useLocation } from '@guillotinaweb/react-gmi'
import { Spin, Divider, Collapse } from 'antd'

import { LogoutOutlined, EyeOutlined } from '@ant-design/icons'
import { getRelativePath } from 'helpers/utils'
import { PropertiesDetail } from 'components/Properties'

export function CollapseItemRender({ path, type, label = '', moreActions = null, ignoreFields }) {
  const traversal = useTraversal()
  const client = useGuillotinaClient()
  const [data, setData] = useSetState({
    item: undefined,
    loading: false,
    schema: undefined,
  })

  const [, setLocation] = useLocation()

  useEffect(() => {
    ;(async () => {
      if (traversal?.context && data.item === undefined && !data.loading) {
        setData({ loading: true })

        const response = await client.get(`/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${path}`)
        const resultState = {}

        if (response.ok) {
          resultState['item'] = await response.json()
          const jsonSchema = await traversal.client.getTypeSchema(traversal.path, type)
          resultState['schema'] = jsonSchema
        } else {
          resultState['item'] = null
        }

        resultState['loading'] = false
        setData(resultState)
      }
    })()
  }, [traversal, data])

  if (data.loading) {
    return (
      <div className="p-4">
        <Spin />
      </div>
    )
  }
  if (data.item !== undefined && data.item !== null) {
    return (
      <div data-test={`collapseItemRenderTest${data.item['@name']}`}>
        <Divider orientation="left"></Divider>
        <Collapse>
          <Collapse.Panel
            header={`${label}: ${data.item.title} ${(data.item.artistes_info ?? []).join('-')} ${
              data.item.review_state ? '-- Estat:' : ''
            } ${data.item.review_state ?? ''}`}
            key="1"
            extra={
              <>
                {moreActions}
                <EyeOutlined
                  onClick={(event) => {
                    setLocation({ path: getRelativePath(data.item['@id']) })
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation()
                  }}
                />
                <a
                  className="ml-2"
                  onClick={(e) => e.stopPropagation()}
                  style={{ color: '#000' }}
                  href={`/?path=/${getRelativePath(data.item['@id'])}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoutOutlined />
                </a>
              </>
            }
          >
            <PropertiesDetail
              schema={data.schema}
              model={data.item}
              ignoreFields={
                ignoreFields ?? [
                  'guillotina.behaviors.dublincore.IDublinCore',
                  'guillotina.behaviors.attachment.IMultiAttachment',
                  'guillotina.contrib.workflows.interfaces.IWorkflowBehavior',
                  '__behaviors__',
                  'type_name',
                  'creation_date',
                  'modification_date',
                  'declaracio_acceptar',
                ]
              }
            />
          </Collapse.Panel>
        </Collapse>
      </div>
    )
  }
  return null
}
