import { useEffect, useState } from 'react'
import {
  useLocation,
  ItemsActionsProvider,
  AllItemsCheckbox,
  ItemsActionsDropdown,
  Pagination,
  SearchLabels,
  useTraversal,
  buildQs,
  parser,
  useConfig,
  Icon,
} from '@guillotinaweb/react-gmi'
import { RItem } from '../Items'
import { useSetState } from 'react-use'
import { MAP_TYPE_NAME_LABEL } from 'helpers/constants'
import { get, getQueryParam } from 'helpers/utils'
import { Row, Col, Divider, Typography, Checkbox, Button } from 'antd'
import { SelectFilter } from 'components/Filters/Select'

const initialState = {
  page: 0,
  items: [],
  loading: true,
  total: 0,
}

const orderOptions = [
  {
    title: 'Nom',
    token: 'title',
  },
  {
    title: 'Data creacio',
    token: 'creation_date',
  },
  {
    title: 'Estat',
    token: 'review_state',
  },
]

const getColumns = () => {
  const smallcss = { width: 25 }
  const mediumcss = { width: 120 }
  return [
    {
      label: '',
      child: (m) => <td style={smallcss}>{<Icon icon={m.icon} />}</td>,
    },
    {
      label: 'type',
      child: (m, navigate) => (
        <td style={smallcss} onClick={navigate}>
          <span className="tag">{MAP_TYPE_NAME_LABEL[m.type]}</span>
        </td>
      ),
    },
    {
      label: 'id/name',
      child: (m, navigate, search) => {
        return (
          <td onClick={navigate}>
            {m.title || get(m, 'item.nom', null) || m.name}
            {search && (
              <>
                <br />
                <span className="is-size-7 tag is-light">{m.path}</span>
              </>
            )}
          </td>
        )
      },
    },
    {
      label: 'created',
      child: (m) => (
        <td style={mediumcss} className="is-size-7 is-vcentered">
          {m.created}
        </td>
      ),
    },
    {
      label: 'modified',
      child: (m) => (
        <td style={mediumcss} className="is-size-7 is-vcentered">
          {m.updated}
        </td>
      ),
    },
    {
      label: 'Status',
      child: (m, navigate) => {
        return <td onClick={navigate}>{m.item.review_state}</td>
      },
    },
  ]
}

const depthParsed = parser('depth=1')

export function PanelItems() {
  const [loadingActions, setLoadingActions] = useState(false)
  const [location, setLocation] = useLocation()
  const { PageSize } = useConfig()

  const Ctx = useTraversal()
  const [state, setState] = useSetState(initialState)
  const { items, loading, total } = state
  const columns = getColumns()

  const search = location.get('q')
  const type = location.get('type')
  let page
  const sort = getQueryParam(location, 'sort', 'title')
  const sortDirection = getQueryParam(location, 'sort_direction', 'des')

  try {
    page = parseInt(location.get('page')) || 0
  } catch {
    page = 0
  }

  let searchParsed = undefined
  if (search && search !== '') {
    searchParsed = parser(search)
  }

  let typeParsed = undefined
  if (type && type !== '') {
    typeParsed = parser(type, 'type_name')
  }

  useEffect(() => {
    if (Ctx.state.loading) return
    ;(async () => {
      setState({ loading: true, total: Ctx.context.length })
      let sortParsed = {}
      if (sortDirection === 'asc') {
        sortParsed = parser(`_sort_asc=${sort}`)
      } else if (sortDirection === 'des') {
        sortParsed = parser(`_sort_des=${sort}`)
      }

      const qs = buildQs([
        ...(searchParsed ?? []),
        ...(typeParsed ?? []),
        ...depthParsed,
        ...sortParsed,
      ])

      const data = await Ctx.client.search(Ctx.path, qs, false, false, page * PageSize, PageSize)

      setState({
        items: data.items,
        loading: false,
        total: data.items_count,
      })
    })()
  }, [search, type, Ctx.state.refresh, page, sort, sortDirection])

  const doPaginate = (page) => {
    setLocation({ page: page })
  }

  const handleDownloadXlsx = async (xlsxName, filename) => {
    const yearFromPath = Ctx.path.split('/')[3]
    setLoadingActions(true)
    const qs = buildQs([
      ...(searchParsed ?? []),
      ...(typeParsed ?? parser('type_name=TipologiaActivitatProfessional')),
    ])
    const endpoint = `${Ctx.url}${yearFromPath}/@${xlsxName}?${qs}`
    try {
      const res = await Ctx.client.download(endpoint)
      if (res.ok) {
        const text = await res.blob()
        const blob = new Blob([text], {
          type: '',
        })
        const url = window.URL.createObjectURL(blob)

        // Create blob link to download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        link.parentNode?.removeChild(link)
      } else {
        Ctx.flash(`Failed to get excel!`, 'danger')
      }
    } catch (err) {
      Ctx.flash(`Failed to get excel!`, 'danger')
    }

    setLoadingActions(false)
  }

  return (
    <ItemsActionsProvider items={items}>
      <div className="columns">
        <div className="column is-2 is-size-7">
          <ItemsActionsDropdown />
        </div>
        <div className="column">
          <SearchLabels />
        </div>
        <div className="column">
          <SearchLabels label="Type" query="type" />
        </div>
        <div className="column">
          <Pagination
            current={page}
            total={total}
            key="pagination-top"
            doPaginate={doPaginate}
            pager={PageSize}
          />
        </div>
      </div>
      {loading && <div className="progress-line"></div>}
      {!loading && (
        <>
          <Divider orientation="left">Accions</Divider>
          <Button
            className="mr-5"
            type="primary"
            disabled={loadingActions}
            onClick={() => {
              handleDownloadXlsx(
                'getTipologiaActivitatProfessionalExcel',
                'tipologies_activitats_professionals'
              )
            }}
          >
            Descarregar XLSX tipologies activitats professionals
          </Button>
          <Button
            className="mr-5"
            type="primary"
            disabled={loadingActions}
            onClick={() => {
              handleDownloadXlsx(
                'getActivitatsProfessionalsByTipologiaExcel',
                'activitats_professionals'
              )
            }}
          >
            Descarregar XLSX activitats professionals
          </Button>
          {loadingActions && <div className="progress-line mt-2"></div>}
          <Divider orientation="left">Ordenar</Divider>
          <Row gutter={[16, 24]} justify="start" align="middle">
            <Col className="gutter-row" span={6}>
              <Typography.Text>Ordenar per</Typography.Text>
              <SelectFilter
                defaultValue={sort}
                type={'sort'}
                values={orderOptions}
                appendDefault={false}
              />
            </Col>
            <Col className="gutter-row" span={6}>
              <Typography.Text></Typography.Text>
              <div>
                <Checkbox
                  checked={sortDirection === 'asc'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setLocation({ sort_direction: 'asc', page: 0 })
                    } else {
                      setLocation({ sort_direction: 'des', page: 0 })
                    }
                  }}
                >
                  Invertir
                </Checkbox>
              </div>
            </Col>
          </Row>
          <table className="table is-fullwidth is-hoverable" data-test="itemPanelTableTest">
            <thead className="is-size-7">
              <tr>
                <th>
                  <AllItemsCheckbox />
                </th>
                {columns.map((i) => (
                  <th key={i.label} className="has-text-info">
                    {i.label}
                  </th>
                ))}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((item) => (
                  <RItem item={item} key={item['@uid']} search={search} columns={columns} />
                ))}
              {items && items.length === 0 && (
                <tr>
                  <td colSpan="6" className="has-text-centered">
                    Anything here!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
      <div className="columns is-centered">
        <Pagination
          current={page}
          key="pagination-bottom"
          total={total}
          doPaginate={doPaginate}
          pager={PageSize}
        />
      </div>
    </ItemsActionsProvider>
  )
}
