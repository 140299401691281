import React, { useEffect } from 'react'
import { useSetState } from 'react-use'
import {
  ItemModel,
  Icon,
  PropertiesButtonView,
  BehaviorsView,
  Confirm,
  useCrudContext,
  useTraversal,
} from '@guillotinaweb/react-gmi'

import { Row, Col, Divider } from 'antd'

import { proposalTitles } from 'helpers/propertiesTitles'
import { PropertiesDetail } from 'components/Properties'
import { PROPOSAL_INTERNAL_STATUS_CLASS } from 'helpers/constants'
import { EditableField } from 'components/Fields'
import { CreateShowSection } from './Properties/CreateShowSection'
import { EnsSection } from './Properties/EnsSection'

const keysProperties = [
  'uuid',
  'num',
  'nom',
  'altres_ambits',
  'durada',
  'tipus',
  'caracteristiques',
  'public',
  'franja',
  'idioma',
  'estrena',
  'dataestrena',
  'claus',
  'claus_altres',
  'actuants',
  'tecnics',
  'manager',
  'localitat',
  'muntatge',
  'desmuntatge',
  'declaracio_drets',
  'altres_drets',
  'pais',
  'comunitat_autonoma',
  'comarca',
  'caixet',
  'proposta_economica',
  'nombre_actuacions',
  'enllacos',
  'enllacos2',
  'enllacos3',
  'dossier_artistic',
  'fitxa_artistica',
  'fitxa_tecnica',
  'imatge_companyia',
  'imatge_companyia2',
  'rejected_proposal_email_send',
]

export function PanelProperties() {
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })

  const traversal = useTraversal()
  const modifyContent = traversal.hasPerm('guillotina.ModifyContent')

  const [submitProposal, setSubmitProposal] = React.useState(false)
  const { post, loading } = useCrudContext()
  const model = new ItemModel(traversal.context)

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'Proposta')
          setSchema({ loading: false, data: dataJson })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const submitProposalAction = async () => {
    const { isError, errorMessage } = await post({}, '@workflow/presentar?lan=ca', false)

    if (!isError) {
      traversal.flash(`Proposal submitted!`, 'success')
    } else {
      traversal.flash(`Failed to submitted Proposal!: ${errorMessage}`, 'danger')
    }

    traversal.refresh()
    setSubmitProposal(false)
  }

  return (
    <div data-test="panelPropertiesTest">
      {submitProposal && (
        <Confirm
          loading={loading}
          onCancel={() => setSubmitProposal(false)}
          onConfirm={submitProposalAction}
          message={`Are you sure to submit Proposal: ${
            traversal.context.title || traversal.context['@name']
          }?`}
        />
      )}
      <div className="container">
        <div className="level">
          <div className="level-left">
            <h2 className="title is-size-4 is-primary">
              <Icon icon={model.icon} align="is-left" className="has-text-grey" />
              &nbsp;
              <span>{traversal.context.title || traversal.context['@name']}</span>
            </h2>
          </div>
          <div className="level-right">
            <PropertiesButtonView />
          </div>
        </div>

        <EnsSection />
        <Divider orientation="left"></Divider>

        {schema && schema.data && !schema.loading && (
          <>
            <Row className="is-flex is-align-items-center">
              <Col span={5} className="has-text-weight-bold">
                {model.item['guillotina.contrib.workflows.interfaces.IWorkflowBehavior'][
                  'review_state'
                ] === 'presentada'
                  ? 'PROPOSTA PRESENTADA'
                  : 'PROPOSTA EN ESBORRANY'}
              </Col>
              {model.item['guillotina.contrib.workflows.interfaces.IWorkflowBehavior'][
                'review_state'
              ] !== 'presentada' && modifyContent ? (
                <button
                  className="button"
                  onClick={() => setSubmitProposal(true)}
                  data-test="btnSubmitProposalTest"
                >
                  Presentar Proposta
                </button>
              ) : null}
            </Row>
            <CreateShowSection renderLink />
            <Divider orientation="left"></Divider>
            <Row className={PROPOSAL_INTERNAL_STATUS_CLASS[model.item['estat_intern']]}>
              <Col span={5} className="has-text-weight-bold">
                Estat intern:
              </Col>
              <Col flex="auto" className="is-capitalized">
                <EditableField
                  field="estat_intern"
                  value={model.item['estat_intern']}
                  schema={schema.data.properties['estat_intern']}
                  modifyContent={modifyContent && traversal.hasPerm('fm.ComentarPropostes')}
                />
              </Col>
            </Row>
            <Row>
              <Col span={5} className="has-text-weight-bold">
                Observacions Comissió:
              </Col>
              <Col flex="auto">
                <EditableField
                  field="observacions_comissio"
                  value={model.item['observacions_comissio']}
                  schema={schema.data.properties['observacions_comissio']}
                  modifyContent={modifyContent && traversal.hasPerm('fm.ComentarPropostes')}
                />
              </Col>
            </Row>
            <Row>
              <Col span={5} className="has-text-weight-bold">
                Observacions Fira:
              </Col>
              <Col flex="auto">
                <EditableField
                  field="observacions_fira"
                  value={model.item['observacions_fira']}
                  schema={schema.data.properties['observacions_fira']}
                  modifyContent={modifyContent && traversal.hasPerm('fm.ComentarFiraPropostes')}
                />
              </Col>
            </Row>
            <Row>
              <Col span={5} className="has-text-weight-bold">
                Ambit:
              </Col>
              <Col flex="auto">
                <EditableField
                  field="ambits"
                  value={model.item['ambits']}
                  schema={schema.data.properties['ambits']}
                  modifyContent={modifyContent && traversal.hasPerm('fm.ModificarAmbitsProposta')}
                />
              </Col>
            </Row>

            <Divider orientation="left"></Divider>
            <PropertiesDetail
              schema={schema.data}
              model={model.item}
              canModified={modifyContent && traversal.hasPerm('fm.ModificarCampNeutreProposta')}
              linkFields={['enllacos', 'enllacos2', 'enllacos3']}
              arrayTextTitleLabel={proposalTitles}
              arraySortProperties={keysProperties}
            />
            <BehaviorsView context={traversal.context} schema={schema.data} />
          </>
        )}
      </div>
    </div>
  )
}
