import { useEffect, useState, useRef, Fragment } from 'react'

const ERRORS = {
  failed_to_fetch: 'Failed to fetch data: Backend not running?',
  invalid_credentials: 'Failed! Invalid credentials',
  invalid_permissions: 'Failed! Invalid permissions',
}

export const Login = ({ auth, onLogin }) => {
  const [state, setState] = useState({
    username: '',
    password: '',
    loading: undefined,
    errors: undefined,
  })

  const ref = useRef(null)
  useEffect(() => {
    ref.current.focus()
  }, [ref])

  const doLogin = async (ev) => {
    ev.preventDefault()
    setState({ ...state, loading: true, errors: undefined })
    const { username, password } = state
    const res = await auth.login(username, password)

    if (!res) {
      setState({ ...state, errors: auth.errors, loading: false })
      return
    }

    if (onLogin) {
      onLogin()
    }
  }

  return (
    <Fragment>
      <form className="login__form" action="" onSubmit={doLogin} data-test="formLoginTest">
        <div className="field">
          <label className="label">Username:</label>
          <input
            type="text"
            className="input"
            placeholder="Username"
            onChange={(e) => {
              setState({ ...state, username: e.target.value })
            }}
            value={state.username}
            ref={ref}
            data-test="inputUsernameLoginTest"
          />
        </div>
        <div className="field">
          <label className="label">Password:</label>
          <input
            type="password"
            className="input"
            placeholder="Password"
            onChange={(e) => {
              setState({ ...state, password: e.target.value })
            }}
            value={state.password}
            data-test="inputPasswordLoginTest"
          />
        </div>
        <div className="field">
          <button className="button is-warning" type="submit" data-test="btnLoginTest">
            Login
          </button>
        </div>
        <div className="field">
          {state.errors && (
            <p className="has-text-danger">{ERRORS[state.errors] || 'Generic error'}</p>
          )}
        </div>
      </form>
    </Fragment>
  )
}
