import { Tag } from 'antd'
import GuilloCfg from 'guillotina/config'
import { get } from 'helpers/utils'
import { useEdition } from 'hooks/useEdition'
import { useVocabulary } from 'hooks/useVocabulary'
import { memo, useEffect, useState } from 'react'

const VocabularyDynamicComponent = ({ keys = [], vocabularyName }) => {
  const edition = useEdition()
  const [data, setData] = useState([])
  const vocabulary = useVocabulary(vocabularyName, `${GuilloCfg.url}${edition}/`)

  useEffect(() => {
    if (vocabulary.data) {
      const keysLabels = []
      keys.map((key) => {
        const obj = get(vocabulary, 'data.items', []).find((voc) => voc.token === key)
        if (obj) {
          keysLabels.push(obj['title'].default)
        }
      })
      setData(keysLabels)
    }
  }, [vocabulary])

  return (
    <>
      {data.map((tag) => {
        return <Tag key={tag}>{tag}</Tag>
      })}
    </>
  )
}

const VocabularyDynamic = memo(VocabularyDynamicComponent)
VocabularyDynamic.displayName = 'VocabularyDynamic'
export default VocabularyDynamic
