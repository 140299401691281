import { Select } from '@guillotinaweb/react-gmi'
import { vocabulariesGetLabelByKey } from 'helpers/vocabulariesMapKeyLabels'
import { get } from 'helpers/utils'
import { Spin } from 'antd'
import { useVocabularyDynamicBySchema } from 'hooks/useVocabulary'

export function SelectVocabulary({ schema, className, setValue, val, dataTest, ...rest }) {
  const vocabulary = useVocabularyDynamicBySchema(schema)

  const getOptions = (items) => {
    if (get(vocabulary, 'data.items', null)) {
      const vocData = vocabulary.data.items.map((item) => {
        return {
          text: item.title.default ?? item.title,
          value: item.token,
        }
      })
      return vocData
    }

    return items.map((item) => {
      return {
        text: vocabulariesGetLabelByKey(item),
        value: item,
      }
    })
  }

  const getProps = () => {
    if (schema.type === 'array') {
      const currentValue = val || []
      return {
        multiple: true,
        size: 5,
        value: currentValue,
        options: getOptions(schema.items.enum),
        onChange: (ev) => {
          const selectValue = get(ev, 'target.value', '')
          if (!currentValue.includes(selectValue)) {
            setValue([...currentValue, selectValue])
          } else {
            setValue(currentValue.filter((value) => value !== selectValue))
          }
        },
      }
    }

    return {
      value: val ?? '',
      appendDefault: true,
      options: getOptions(schema.enum),
      onChange: (ev) => {
        let selectValue = get(ev, 'target.value', '')
        if (selectValue === '') {
          selectValue = null
        }
        return setValue(selectValue)
      },
    }
  }

  if (vocabulary.data === undefined || vocabulary.loading) {
    return <Spin />
  }
  return (
    <Select
      {...getProps()}
      className={className}
      classWrap="is-fullwidth"
      dataTest={dataTest}
      {...rest}
    />
  )
}
