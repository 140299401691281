import { memo } from 'react'
import { Tag } from 'antd'

const ObjectRenderComponent = ({ data = [], fieldRenderObject }) => (
  <>
    {data.map((item, idx) => {
      return <Tag key={`${item['@id']}_${idx}`}>{item[fieldRenderObject]}</Tag>
    })}
  </>
)

const ObjectRender = memo(ObjectRenderComponent)
ObjectRender.displayName = 'ObjectRender'
export default ObjectRender
