import Tags from './Tags'
import ActionsView from './ActionsView'
import VocabularyDynamic from './VocabularyDyamic'
import ObjectRender from './ObjectRender'
import BooleanRender from './BooleanComponent'

export const getComponentsByTypeColumns = ({
  type,
  data,
  getLabelByKey,
  vocabularyName,
  fieldRenderObject,
}) => {
  if (type === 'tags') {
    return <Tags tags={data} getLabelByKey={getLabelByKey} />
  } else if (type === 'actions-view') {
    return <ActionsView data={data} />
  } else if (type === 'vocabulary-dynamic') {
    return <VocabularyDynamic keys={data} vocabularyName={vocabularyName} />
  } else if (type === 'objectes') {
    return <ObjectRender data={data} fieldRenderObject={fieldRenderObject} />
  } else if (type === 'boolean') {
    return <BooleanRender data={data} />
  }
  return null
}
