import { memo } from 'react'
import { Tag } from 'antd'
import { vocabulariesGetLabelByKey } from 'helpers/vocabulariesMapKeyLabels'

const TagsComponent = ({ tags = [], getLabelByKey = false }) => (
  <>
    {tags.map((tag) => {
      return <Tag key={tag}>{getLabelByKey ? vocabulariesGetLabelByKey(tag) : tag}</Tag>
    })}
  </>
)

const Tags = memo(TagsComponent)
Tags.displayName = 'Tags'
export default Tags
