import React from 'react'
import { ContextToolbar, useTraversal, TabsPanel, PanelActions } from '@guillotinaweb/react-gmi'
import { PanelProperties, PanelPerformances } from 'components/Panels/Show'
import { PanelPermissions } from 'components/Panels/Permissions'

const tabs = {
  Properties: PanelProperties,
  Permissions: PanelPermissions,
  Actuacions: PanelPerformances,
  Actions: PanelActions,
}

const tabsPermissions = {
  Properties: 'guillotina.ViewContent',
  Permissions: 'guillotina.SeePermissions',
  Actuacions: 'guillotina.ViewContent',
}

export function ShowView(props) {
  const traversal = useTraversal()
  const calculated = traversal.filterTabs(tabs, tabsPermissions)
  return (
    <TabsPanel
      tabs={calculated}
      currentTab="Properties"
      editableTabs={[]}
      rightToolbar={<ContextToolbar {...props} />}
      {...props}
    />
  )
}
