import * as React from 'react'
import { EditableField } from 'components/Fields'
import { get } from 'helpers/utils'

export function PropertiesDetail({
  schema,
  model,
  canModified = false,
  ignoreFields = [],
  linkFields = [],
  arrayTextTitleLabel = {},
  arraySortProperties = [],
}) {
  const getLabel = (key, value) => {
    if (key in arrayTextTitleLabel) {
      return arrayTextTitleLabel[key]
    }
    return value.title || (value.items ? value.items.title : '')
  }

  const getKeysProperties = () => {
    return arraySortProperties.length > 0 ? arraySortProperties : Object.keys(schema.properties)
  }

  return (
    <div className="columns">
      <div className="column">
        <table className="table is-striped is-fullwidth is-size-7">
          <thead>
            <tr>
              <th className="is-2">Prop</th>
              <th className="is-8">Value</th>
            </tr>
          </thead>
          <tbody>
            {schema &&
              getKeysProperties().map((key) => {
                const value = schema.properties[key]
                if (!ignoreFields.includes(key)) {
                  return (
                    <tr key={'prop' + key}>
                      <td>{getLabel(key, value)}</td>
                      <td>
                        <EditableField
                          field={key}
                          value={model[key]}
                          schema={value}
                          modifyContent={canModified}
                          required={get(schema.data, 'required', []).includes(key)}
                          idLinkField={linkFields.includes(key)}
                        />
                      </td>
                    </tr>
                  )
                }
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
