import {
  Icon,
  Pagination,
  SearchLabels,
  buildQs,
  parser,
  useConfig,
  useLocation,
  useTraversal,
} from '@guillotinaweb/react-gmi'
import { Checkbox, Col, Divider, Row, Typography } from 'antd'
import {
  AllItemsCheckbox,
  ItemsActionsDropdown,
  ItemsPublishUnpublishActionsProvider,
} from 'components/Actions/SelectedItemsActionsPublishUnpublish'
import { InputSearchFilter } from 'components/Filters/InputSearch'
import { SelectFilter } from 'components/Filters/Select'
import {
  arrayFilters,
  arrayFiltersInputs,
  arrayVocabulariesFilters,
  getParamsFilters,
} from 'helpers/performanceFiltersKeys'
import { get, getDefaultValueFormParams, getQueryParam } from 'helpers/utils'

import { useGetValueVocabularyDynamic, useVocabularies } from 'hooks/useVocabulary'
import { useEffect } from 'react'
import { useSetState } from 'react-use'
import { RItemPublishUnpublish } from '../Items'

const orderOptions = [
  {
    title: 'Data',
    token: 'data',
  },
  {
    title: 'Estat',
    token: 'review_state',
  },
  {
    title: 'Àmbit',
    token: 'ambits',
  },
  {
    title: 'Espai',
    token: 'espai',
  },
]

const initialState = {
  page: 0,
  items: [],
  loading: true,
  total: 0,
}

const RenderEspaiValue = (m, navigate) => {
  const result = useGetValueVocabularyDynamic({
    isMultiple: false,
    vocabularyName: 'espai',
    val: m.item.espai,
  })
  return (
    <td style={{ width: 120 }} className="is-size-7 is-vcentered" onClick={navigate}>
      {result}
    </td>
  )
}

const getColumns = () => {
  const smallcss = { width: 25 }
  const mediumcss = { width: 120 }
  return [
    {
      label: '',
      child: (m) => <td style={smallcss}>{<Icon icon={m.icon} />}</td>,
    },
    {
      label: 'Data',
      child: (m, navigate) => (
        <td style={mediumcss} className="is-size-7 is-vcentered" onClick={navigate}>
          {new Date(m.item.data).toLocaleString()}
        </td>
      ),
    },
    {
      label: 'Preu',
      child: (m, navigate) => (
        <td style={mediumcss} className="is-size-7 is-vcentered" onClick={navigate}>
          {m.item.preu}
        </td>
      ),
    },
    {
      label: 'Preu anticipat',
      child: (m, navigate) => (
        <td style={mediumcss} className="is-size-7 is-vcentered" onClick={navigate}>
          {m.item.preu_anticipada}
        </td>
      ),
    },
    {
      label: 'Espai',
      child: RenderEspaiValue,
    },
    {
      label: 'Espectacles',
      child: (m, navigate) => (
        <td style={mediumcss} className="is-size-7 is-vcentered" onClick={navigate}>
          {m.item.espectacles_data.map((show, index) => (
            <span key={`${m.item['@name']}_${show.id}_${index}`} className="tag mr-3">
              {show.title || '-'} / {(show.artistes_info ?? []).map((artist) => artist).join(',')}
            </span>
          ))}
        </td>
      ),
    },
    {
      label: 'Status',
      child: (m, navigate) => {
        return <td onClick={navigate}>{m.item.review_state}</td>
      },
    },
  ]
}

// Ordenar per data
const showParsed = parser('type_name=Actuacio')

export function PanelItems() {
  const [location, setLocation] = useLocation()
  const { PageSize } = useConfig()
  const Ctx = useTraversal()
  const [state, setState] = useSetState(initialState)
  const [totalItemsEachSearch, setTotalItemsEachSearch] = useSetState({
    loading: false,
    result: undefined,
  })

  const { data: vocabularies, loading: isLoadingVocabularies } = useVocabularies(
    arrayVocabulariesFilters
  )

  const { items, loading, total } = state
  const columns = getColumns()

  const search = location.get('q')
  const type = location.get('type')

  const page = parseInt(getQueryParam(location, 'page', 0))
  const sort = getQueryParam(location, 'sort', 'title')
  const sortDirection = getQueryParam(location, 'sort_direction', 'des')

  const [
    paramsParsed,
    [
      defaultAmbits,
      defaultEix,
      defaultEspai,
      defaultSeccio,
      defaultStatus,
      defaultObrador,
      defaultEntradesExhaurides,
      defaultCancelat,
      defaultTitle,
    ],
  ] = getParamsFilters(location)

  let searchParsed = undefined
  if (search && search !== '') {
    searchParsed = parser(search)
  }

  let typeParsed = undefined
  if (type && type !== '') {
    typeParsed = parser(type, 'type_name')
  }

  useEffect(() => {
    if (Ctx.state.loading) return
    ;(async () => {
      setState({ loading: true, total: Ctx.context.length })

      let sortParsed = {}
      if (sortDirection === 'asc') {
        sortParsed = parser(`_sort_asc=${sort}`)
      } else if (sortDirection === 'des') {
        sortParsed = parser(`_sort_des=${sort}`)
      }

      const qs = buildQs([
        ...(searchParsed ?? []),
        ...(typeParsed ?? []),
        ...paramsParsed,
        ...showParsed,
        ...sortParsed,
      ])
      const data = await Ctx.client.search(Ctx.path, qs, false, false, page * PageSize, PageSize)
      setState({
        items: data.member,
        loading: false,
        total: data.items_count,
      })
    })()
  }, [
    search,
    type,
    Ctx.state.refresh,
    page,
    sort,
    sortDirection,
    defaultAmbits,
    defaultEix,
    defaultEspai,
    defaultSeccio,
    defaultStatus,
    defaultObrador,
    defaultEntradesExhaurides,
    defaultCancelat,
    defaultTitle,
  ])

  useEffect(() => {
    ;(async () => {
      const payload = {}

      if (!loading && vocabularies !== undefined && !isLoadingVocabularies) {
        setTotalItemsEachSearch({
          loading: true,
        })

        for (let j = 0; j < arrayFilters.length; j++) {
          const { filter, resultObj } = arrayFilters[j]
          payload[filter] = {}

          const cleanedParamsParsed = paramsParsed.filter((params) => {
            return params[0].indexOf(filter) === -1
          })

          const commonFilters = [...showParsed, ...(searchParsed ?? []), ...cleanedParamsParsed]

          const totalQs = buildQs(commonFilters)
          payload[filter]['total'] = {
            total: get(
              Ctx.client.cacheSearchResults,
              [`${Ctx.path}/${totalQs}`, 'total'],
              undefined
            ),
            query: totalQs,
          }

          const values = resultObj ? vocabularies[`${resultObj}`] : arrayFilters[j].values

          for (let i = 0; i < values.length; i++) {
            const qs = buildQs([...commonFilters, ...parser(`${filter}=${values[i].token}`)])
            payload[filter][values[i].token] = {
              total: get(Ctx.client.cacheSearchResults, [`${Ctx.path}/${qs}`, 'total'], undefined),
              query: qs,
            }
          }
        }

        const res = await Ctx.client.post(
          `${Ctx.client.getYearFromPath(Ctx.path)}/@totalsFilters`,
          payload
        )

        if (res.ok) {
          setTotalItemsEachSearch({
            loading: false,
            result: await res.json(),
          })
        } else {
          setTotalItemsEachSearch({
            loading: false,
          })
        }
      }
    })()
  }, [loading, vocabularies, isLoadingVocabularies])

  const doPaginate = (page) => {
    setLocation({ page: page })
  }

  return (
    <ItemsPublishUnpublishActionsProvider items={items}>
      <div className="columns">
        <div className="column is-2 is-size-7">
          <ItemsActionsDropdown />
        </div>
        <div className="column">
          <SearchLabels />
        </div>
        <div className="column">
          <Pagination
            current={page}
            total={total}
            key="pagination-top"
            doPaginate={doPaginate}
            pager={PageSize}
          />
        </div>
      </div>
      {loading ||
        isLoadingVocabularies ||
        (vocabularies === undefined && <div className="progress-line"></div>)}

      {!loading && !isLoadingVocabularies && vocabularies !== undefined && (
        <>
          <Divider orientation="left">Filtres</Divider>
          <Row gutter={[16, 24]} justify="start" align="middle">
            {arrayFilters.map((filterObj) => {
              const { filter: filterName, resultObj, label } = filterObj
              const values = resultObj ? vocabularies[`${resultObj}`] : filterObj.values
              return (
                <Col className="gutter-row" span={6} key={`filter_${filterName}`}>
                  <Typography.Text>{label}</Typography.Text>
                  <SelectFilter
                    defaultValue={getDefaultValueFormParams(location, filterName)}
                    type={filterName}
                    values={values}
                    loadingTotals={totalItemsEachSearch.loading}
                    totalItemsWhenSearch={totalItemsEachSearch.result}
                  />
                </Col>
              )
            })}
            {arrayFiltersInputs.map((filterObj) => {
              const { filter: filterName, label } = filterObj
              return (
                <Col className="gutter-row" span={6} key={`filter_input_${filterName}`}>
                  <Typography.Text>{label}</Typography.Text>
                  <InputSearchFilter
                    value={getDefaultValueFormParams(location, filterName, '')}
                    type={filterName}
                  />
                </Col>
              )
            })}
          </Row>

          <Divider orientation="left">Ordenar</Divider>
          <Row gutter={[16, 24]} justify="start" align="middle">
            <Col className="gutter-row" span={6}>
              <Typography.Text>Ordenar per</Typography.Text>
              <SelectFilter
                defaultValue={sort}
                type={'sort'}
                values={orderOptions}
                appendDefault={false}
              />
            </Col>
            <Col className="gutter-row" span={6}>
              <Typography.Text></Typography.Text>
              <div>
                <Checkbox
                  checked={sortDirection === 'asc'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setLocation({ sort_direction: 'asc', page: 0 })
                    } else {
                      setLocation({ sort_direction: 'des', page: 0 })
                    }
                  }}
                >
                  Invertir
                </Checkbox>
              </div>
            </Col>
          </Row>
          <table className="table is-fullwidth is-hoverable" data-test="itemPanelTableTest">
            <thead className="is-size-7">
              <tr>
                <th>
                  <AllItemsCheckbox />
                </th>
                {columns.map((i) => (
                  <th key={i.label} className="has-text-info">
                    {i.label}
                  </th>
                ))}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {items &&
                items.map((item) => (
                  <RItemPublishUnpublish
                    item={item}
                    key={item['@uid']}
                    search={search}
                    columns={columns}
                  />
                ))}
              {items && items.length === 0 && (
                <tr>
                  <td colSpan="6" className="has-text-centered">
                    Anything here!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}

      <div className="columns is-centered">
        <Pagination
          current={page}
          key="pagination-bottom"
          total={total}
          doPaginate={doPaginate}
          pager={PageSize}
        />
      </div>
    </ItemsPublishUnpublishActionsProvider>
  )
}
