import { useEffect, useState } from 'react'
import { useSetState } from 'react-use'

import { Form, useTraversal, stringToSlug } from '@guillotinaweb/react-gmi'
import { EditComponent } from 'components/Fields'

const orderFieldsForm = [
  'artistes',
  'title',
  'ambit',
  'altres_ambits',
  'durada',
  'estrena',
  'seccio',
  'eix',
]
const requiredFields = ['seccio', 'ambit', 'artistes']

export const ShowForm = ({ onSubmit, title, loading, dataTest, actionName }) => {
  const traversal = useTraversal()

  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
    formFields: [],
  })

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'Espectacle')
          setSchema({
            loading: false,
            data: dataJson,
          })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const getId = () => {
    let result = ''
    let artistes = ''
    if (formData.title) {
      result = `${stringToSlug(formData.title)}_`
    }
    artistes = (formData['artistes'] ?? []).map((item) => item.title).join(',')
    return stringToSlug(`${result}${artistes}`)
  }

  const submit = () => {
    const currentErrors = {}

    requiredFields.forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        currentErrors[key] = 'This field is required'
      }
    })

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors)
      return
    }

    const finalData = Object.assign({}, formData)
    finalData['artistes'] = (finalData['artistes'] ?? []).map((item) => item.path)
    onSubmit({ id: getId(), ...finalData })
  }

  const renderField = (key) => {
    if (key === 'comunitat_autonoma' && !(formData.pais ?? []).includes('country-ES')) {
      return false
    }
    if (
      key === 'comarca' &&
      (!(formData.pais ?? []).includes('country-ES') ||
        !(formData.comunitat_autonoma ?? []).includes('catalunya'))
    ) {
      return false
    }
    return true
  }

  return (
    <Form title={title} onSubmit={submit} dataTest={dataTest}>
      {schema &&
        schema.data &&
        !schema.loading &&
        orderFieldsForm.map((key) => {
          if (renderField(key)) {
            const value = schema.data.properties[key]
            return (
              <EditComponent
                key={key}
                id={key}
                schema={value}
                val={key in formData ? formData[key] : ''}
                placeholder={value?.title ?? ''}
                required={requiredFields.includes(key)}
                setValue={(ev) => {
                  setFormData({ ...formData, [key]: ev })
                }}
                error={errors[key]}
                dataTest={`${key}TestInput`}
              />
            )
          }
          return null
        })}

      <div className="level level-right">
        <button
          type="submit"
          className={`button is-success ${loading ? 'is-loading' : ''}`}
          data-test="formBaseBtnTestSubmit"
        >
          {actionName || 'Add'}
        </button>
      </div>
    </Form>
  )
}
