import { BehaviorsView, Icon, ItemModel, parser, useTraversal } from '@guillotinaweb/react-gmi'
import { Divider } from 'antd'
import { Fragment, useEffect, useMemo } from 'react'
import { useSetState } from 'react-use'

import {
  AddItemToObject,
  CollapseItemRender,
  DeleteItemFromObject,
  PropertiesDetail,
  WorkflowShow,
} from 'components/Properties'

import { SHOWS_STATUS } from 'helpers/constants'

const arraySortProperties = [
  'title',
  'uuid',
  'data',
  'data_filtre',
  'amagar_info_data',
  'espai',
  'canvis',
  'amagar_info_espai',
  'preu_anticipada',
  'preu',
  'preu_altres',
  'preu_altres_html',
  'preu_inversa',
  'preu_access_professional',
  'entrada_amb_reserva',
  'no_access_professional',
  'no_actua',
  'entrada_exhaurida',
  'cancelat',
  'te_stream_tancat',
  'link_stream_tancat',
  'te_stream_obert',
  'link_stream_obert',
]

export function PanelProperties() {
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const traversal = useTraversal()
  const modifyContent = traversal.hasPerm('guillotina.ModifyContent')
  const model = new ItemModel(traversal.context)

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'Actuacio')
          setSchema({ loading: false, data: dataJson })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const showWorkflowBtn = useMemo(() => {
    if (!model) return false
    if (model.item.review_state === SHOWS_STATUS.PUBLISH) return true

    let canPublish = true
    model.item.espectacles_data.some((showItem) => {
      if (showItem.review_state === SHOWS_STATUS.HIDE) {
        canPublish = false
        return true
      }
      return false
    })
    return canPublish
  }, [model])

  return (
    <div className="container">
      <div className="level">
        <div className="level-left">
          <h2 className="title is-size-4 is-primary">
            <Icon icon={model.icon} align="is-left" className="has-text-grey" />
            &nbsp;
            <span>{traversal.context.title || traversal.context['@name']}</span>
          </h2>
        </div>
      </div>
      <hr />

      {schema && schema.data && !schema.loading && (
        <Fragment>
          {showWorkflowBtn && modifyContent && <WorkflowShow />}
          {model.item.espectacles.map((show) => {
            return (
              <CollapseItemRender
                key={`render_${show}`}
                label="Espectacle"
                type="Espectacle"
                path={show}
                moreActions={
                  modifyContent && (
                    <DeleteItemFromObject isMultiple path={show} label="show" field="espectacles" />
                  )
                }
              />
            )
          })}
          {modifyContent && (
            <>
              <AddItemToObject
                isMultiple
                qs={parser('type_name=Espectacle')}
                label="Afageix un espectacle"
                field="espectacles"
              />
              <Divider orientation="left"></Divider>
            </>
          )}
          <PropertiesDetail
            schema={schema.data}
            model={model.item}
            canModified={modifyContent}
            arraySortProperties={arraySortProperties}
          />
          <BehaviorsView context={traversal.context} schema={schema.data} />
        </Fragment>
      )}
    </div>
  )
}
