import { Fragment } from 'react'
import { useTraversal, ItemModel, useLocation } from '@guillotinaweb/react-gmi'
import { get } from 'helpers/utils'
import { LogoutOutlined, DeleteOutlined } from '@ant-design/icons'
import { useGetValueVocabularyDynamic } from 'hooks/useVocabulary'

export const RenderEspaiValue = (m, navigate) => {
  const result = useGetValueVocabularyDynamic({
    isMultiple: false,
    vocabularyName: 'espai',
    val: m.espai,
  })
  return (
    <td style={{ width: 120 }} className="is-size-7 is-vcentered" onClick={navigate}>
      {result}
    </td>
  )
}

const columns = [
  {
    label: 'Data',
    child: (m) => (
      <td style={{ width: 120 }} className="is-size-7 is-vcentered">
        {new Date(m.data).toLocaleString()}
      </td>
    ),
  },
  {
    label: 'Preu',
    child: (m) => (
      <td style={{ width: 120 }} className="is-size-7 is-vcentered">
        {m.preu}
      </td>
    ),
  },
  {
    label: 'Estat',
    child: (m) => (
      <td style={{ width: 120 }} className="is-size-7 is-vcentered">
        {m.review_state}
      </td>
    ),
  },
  {
    label: 'Espai',
    child: RenderEspaiValue,
  },
]

function RItem({ item, columns }) {
  const [, navigate] = useLocation()
  const traversal = useTraversal()

  return (
    <tr key={item} data-test={`itemTest-${item['id']}`}>
      {columns.map((i) => (
        <Fragment key={i.label}>{i.child(item)}</Fragment>
      ))}
      <td style={{ width: 25 }}>
        <LogoutOutlined
          className="mr-2"
          onClick={() =>
            navigate(
              {
                path: `/${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${item.path}/`,
              },
              true
            )
          }
        />
        {traversal.hasPerm('guillotina.DeleteContent') && (
          <DeleteOutlined onClick={() => traversal.doAction('removeItems', { items: [item] })} />
        )}
      </td>
    </tr>
  )
}

export function PerformancesList() {
  const traversal = useTraversal()
  const model = new ItemModel(traversal.context)
  const allPerformacnes = get(model, 'item.actuacions', [])
  return (
    <table className="table is-fullwidth is-hoverable" data-test="itemPanelTableTest">
      <thead className="is-size-7">
        <tr>
          {columns.map((i) => (
            <th key={i.label} className="has-text-info">
              {i.label}
            </th>
          ))}
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {allPerformacnes.map((item) => (
          <RItem item={item} key={item['id']} columns={columns} />
        ))}
        {allPerformacnes.length === 0 && (
          <tr>
            <td colSpan="6" className="has-text-centered">
              Anything here!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
