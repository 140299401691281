import { AccreditationForm } from './AccreditationForm'

export const AccreditationProfessionalForm = ({
  onSubmit,
  title,
  loading,
  dataTest,
  actionName,
}) => (
  <AccreditationForm
    onSubmit={onSubmit}
    title={title}
    loading={loading}
    dataTest={dataTest}
    actionName={actionName}
    type="AcreditacioProfessional"
  />
)
