import { ContextToolbar, useTraversal, TabsPanel } from '@guillotinaweb/react-gmi'
import { PanelItems } from 'components/Panels/Shows'
import { PanelPermissions } from 'components/Panels/Permissions'

const tabs = {
  Items: PanelItems,
  Permissions: PanelPermissions,
}

const tabsPermissions = {
  Items: 'guillotina.ViewContent',
  Permissions: 'guillotina.SeePermissions',
}

export function ShowsView(props) {
  const traversal = useTraversal()
  const calculated = traversal.filterTabs(tabs, tabsPermissions)
  return (
    <TabsPanel
      tabs={calculated}
      currentTab="Items"
      editableTabs={[]}
      rightToolbar={<ContextToolbar {...props} />}
      {...props}
    />
  )
}
