import { useGetValueVocabularyDynamicBySchema } from 'hooks/useVocabulary'

export function RenderVocabularyDynamic({ value, schema }) {
  const valueVocabularyDynamic = useGetValueVocabularyDynamicBySchema({
    schema: schema,
    val: value,
  })

  const returnValue = () => {
    if (Array.isArray(valueVocabularyDynamic)) {
      return valueVocabularyDynamic.map((val) => {
        return <div key={val}>{val}</div>
      })
    }
    return valueVocabularyDynamic
  }
  return <div>{returnValue()}</div>
}
