import { Fragment, useEffect } from 'react'
import { useSetState } from 'react-use'
import { ItemModel, useTraversal, useLocation } from '@guillotinaweb/react-gmi'
import { Collapse, Divider } from 'antd'
import { LogoutOutlined, EyeOutlined } from '@ant-design/icons'
import { PropertiesDetail } from 'components/Properties'
import { get, getRelativePath } from 'helpers/utils'

export function ArtistsSection() {
  const traversal = useTraversal()
  const [, setLocation] = useLocation()
  const [data, setData] = useSetState({
    data: undefined,
    schema: undefined,
    loading: false,
    error: undefined,
  })

  const model = new ItemModel(traversal.context)

  useEffect(() => {
    ;(async () => {
      if (traversal.loading) return
      if (
        model.item &&
        model.item.parent &&
        !data.loading &&
        !data.data &&
        !data.schema &&
        !data.error
      ) {
        setData({ loading: true })
        const data = await traversal.client.get(
          `${getRelativePath(get(model, 'item.@id', ''))}@artistes`
        )
        const jsonSchema = await traversal.client.getTypeSchema(traversal.path, 'Ens')

        if (data.status === 200) {
          const dataJson = await data.json()
          setData({
            loading: false,
            data: dataJson,
            schema: jsonSchema,
          })
        } else {
          setData({ loading: false, error: 'Error when try to get artist' })
        }
      }
    })()
  }, [data, model])

  const renderEnsType = () => {
    return (
      <>
        {data.data.length > 0 && <Divider orientation="left" />}
        {data.data.map((artist) => {
          return (
            <Collapse key={artist['@id']}>
              <Collapse.Panel
                header={`Artista: ${artist.title}`}
                key="1"
                extra={
                  <>
                    <EyeOutlined
                      onClick={(event) => {
                        setLocation({ path: getRelativePath(artist['@id']) })
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation()
                      }}
                    />
                    <a
                      className="ml-2"
                      onClick={(e) => e.stopPropagation()}
                      style={{ color: '#000' }}
                      href={`/?path=/${getRelativePath(artist['@id'])}/`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LogoutOutlined />
                    </a>
                  </>
                }
              >
                <PropertiesDetail
                  schema={data.schema}
                  model={artist}
                  ignoreFields={[
                    'guillotina.behaviors.dublincore.IDublinCore',
                    '__behaviors__',
                    'type_name',
                    'creation_date',
                    'modification_date',
                    'declaracio_acceptar',
                  ]}
                  linkFields={['facebook', 'instagram', 'linkedin', 'twitter', 'youtube', 'web']}
                />
              </Collapse.Panel>
            </Collapse>
          )
        })}
      </>
    )
  }

  return <Fragment>{!data.loading && data.data && renderEnsType()}</Fragment>
}
