import { ContextToolbar, useTraversal, TabsPanel, PanelPermissions } from '@guillotinaweb/react-gmi'
import { PanelItems } from 'components/Panels/Performances'

const tabs = {
  Items: PanelItems,
  Permissions: PanelPermissions,
}

const tabsPermissions = {
  Items: 'guillotina.ViewContent',
  Properties: 'guillotina.ViewContent',
}

export function PerformancesView(props) {
  const traversal = useTraversal()
  const calculated = traversal.filterTabs(tabs, tabsPermissions)
  return (
    <TabsPanel
      tabs={calculated}
      currentTab="Items"
      editableTabs={[]}
      rightToolbar={<ContextToolbar {...props} />}
      {...props}
    />
  )
}
