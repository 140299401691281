import { Checkbox, useTraversal } from '@guillotinaweb/react-gmi'
import { Dropdown } from 'components/Fields/Dropdown'
import { createContext, useContext, useState } from 'react'

const ItemsPublishUnpublishActionsCtx = createContext({})

export const actions = {
  DELETE: {
    text: 'Delete',
    perms: ['guillotina.DeleteContent'],
    action: 'removeItems',
  },
  MOVE: {
    text: 'Move to...',
    perms: ['guillotina.MoveContent'],
    action: 'moveItems',
  },
  COPY: {
    text: 'Copy to...',
    perms: ['guillotina.DuplicateContent'],
    action: 'copyItems',
  },
  PUBLISH: {
    text: 'Publish',
    perms: ['guillotina.ModifyContent'],
    action: 'publishItem',
  },
  UNPUBLISH: {
    text: 'Unpublish',
    perms: ['guillotina.ModifyContent'],
    action: 'unpublishItem',
  },
}

export function ItemsPublishUnpublishActionsProvider({ items, children }) {
  const traversal = useTraversal()
  const [selected, setSelected] = useState({})

  function onSelectAllItems(checked) {
    setSelected(
      items.reduce(
        (obj, item) => {
          obj[`${item.path}/${item.id}`] = checked
          return obj
        },
        { all: checked }
      )
    )
  }

  function onSelectOneItem(item) {
    setSelected((state) => ({
      ...state,
      all: false,
      [`${item.path}/${item.id}`]: !state[`${item.path}/${item.id}`],
    }))
  }

  function onAction(actionKey) {
    traversal.doAction(actions[actionKey].action, {
      items: items.filter((item) => selected[`${item.path}/${item.id}`]),
    })
  }

  return (
    <ItemsPublishUnpublishActionsCtx.Provider
      value={{
        onAction,
        onSelectAllItems,
        onSelectOneItem,
        selected,
      }}
    >
      {children}
    </ItemsPublishUnpublishActionsCtx.Provider>
  )
}

export function AllItemsCheckbox() {
  const { onSelectAllItems, selected } = useContext(ItemsPublishUnpublishActionsCtx)

  return (
    <Checkbox
      key={selected.all}
      onChange={onSelectAllItems}
      style={{ marginLeft: 2 }}
      value={selected.all}
    />
  )
}

export function ItemPublishUnpublishCheckbox({ item }) {
  const { selected, onSelectOneItem } = useContext(ItemsPublishUnpublishActionsCtx)
  const absId = `${item.path}/${item.id}`
  const value = selected[absId]

  return <Checkbox key={value} onChange={() => onSelectOneItem(item)} value={value} />
}

export function ItemsActionsDropdown() {
  const traversal = useTraversal()
  const { selected, onAction } = useContext(ItemsPublishUnpublishActionsCtx)
  const disabled = Object.values(selected).every((v) => !v)
  const options = Object.keys(actions).map((action) => ({
    text: actions[action].text,
    value: action,
  }))

  return (
    <Dropdown
      disabled={disabled}
      id="items-actions"
      onChange={onAction}
      optionDisabledWhen={(o) => actions[o.value].perms.some((perm) => !traversal.hasPerm(perm))}
      options={options}
    >
      Choose action...
    </Dropdown>
  )
}
