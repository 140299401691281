import { forwardRef, useEffect, useMemo } from 'react'
import { useSetState } from 'react-use'
import { Input, Select, useTraversal, useCrudContext, stringToSlug } from '@guillotinaweb/react-gmi'
import { Spin } from 'antd'
import { get } from 'helpers/utils'
import { useForm, Controller, useFieldArray } from 'react-hook-form'

export const VocabularyForm = forwardRef(
  ({ field, allItems, keyItem = null, initData = null, onCancel }, ref) => {
    const [languages, setLanguages] = useSetState({
      data: undefined,
      loading: false,
      error: undefined,
    })

    const getDefaultValue = useMemo(() => {
      const formData = {}

      if (initData) {
        formData['default'] = initData['default']
        formData['languages'] = []
        Object.keys(initData).forEach((keyInitMap) => {
          if (keyInitMap !== 'default') {
            formData['languages'].push({
              key: keyInitMap,
              value: initData[keyInitMap],
            })
          }
        })
      }
      return formData
    }, [initData])

    const { control, handleSubmit, reset, errors } = useForm({
      defaultValues: getDefaultValue,
    })
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'languages',
    })

    const traversal = useTraversal()
    const { patch, loading } = useCrudContext()
    useEffect(() => reset(getDefaultValue), [getDefaultValue])

    useEffect(() => {
      ;(async () => {
        if (!languages.loading && !languages.data && !languages.error) {
          try {
            setLanguages({ loading: true })
            const dataJson = await traversal.client.getVocabulary(
              process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT,
              'idiomesfm'
            )
            setLanguages({ loading: false, data: dataJson })
            ref.current.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' })
          } catch (err) {
            setLanguages({ loading: false, error: err })
          }
        }
      })()
    }, [languages])

    const onSubmit = async (data) => {
      const dataToSend = {}
      dataToSend['default'] = data['default']
      get(data, 'languages', []).map((itemLang) => {
        dataToSend[itemLang.key] = itemLang.value
      })

      if (keyItem) {
        allItems[keyItem] = dataToSend
      } else {
        const newKey = stringToSlug(dataToSend['default'])
        allItems[newKey] = dataToSend
      }
      const dataPatch = await patch({
        [field]: allItems,
      })

      if (dataPatch.isError) {
        traversal.flash(`Error when update vocabulary!`, 'danger')
      } else {
        traversal.flash(`Vocabulary updated!`, 'success')
        traversal.client.clearVocabulary()
      }

      traversal.refresh()
    }

    if (languages.loading || !languages.data) return <Spin />
    return (
      <div ref={ref}>
        {languages.loading || !languages.data ? (
          <Spin />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} ref={ref}>
            <div>
              <label>Default</label>
              <Controller
                name="default"
                control={control}
                defaultValue={get(initData, 'default', '')}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    onChange={(ev) => props.onChange(ev)}
                    type="text"
                    value={props.value}
                    className="is-fullwidth"
                    required
                    dataTest="inputDefaultValueTest"
                  />
                )}
              />
            </div>
            {fields.map(({ id, key, value }, index) => {
              return (
                <div
                  key={id}
                  className="is-flex is-justify-content-space-between is-align-items-center"
                >
                  <div className="is-flex is-justify-content-space-between is-align-items-center">
                    <label className="mr-4">Idioma</label>
                    <Controller
                      name={`languages[${index}].key`}
                      control={control}
                      defaultValue={key ?? ''}
                      rules={{ required: true }}
                      render={(props) => (
                        <Select
                          dataTest={`selectLanguageValueTest-${index}`}
                          appendDefault
                          options={languages.data.items.map((item) => {
                            return {
                              text: item.title,
                              value: item.token,
                            }
                          })}
                          error={
                            get(errors, `languages[${index}].key`, null)
                              ? 'This field is required'
                              : null
                          }
                          value={props.value}
                          classWrap="is-fullwidth"
                          onChange={(ev) => {
                            const selectValue = get(ev, 'target.value', '')
                            return props.onChange(selectValue)
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="is-flex is-justify-content-space-between is-align-items-center">
                    <label className="mr-4">Valor</label>
                    <Controller
                      name={`languages[${index}].value`}
                      control={control}
                      defaultValue={value ?? ''}
                      rules={{ required: true }}
                      render={(props) => (
                        <Input
                          dataTest={`inputValueLanguageTest-${index}`}
                          onChange={(ev) => props.onChange(ev)}
                          type="text"
                          value={props.value}
                          required
                        />
                      )}
                    />
                  </div>

                  <button
                    type="button"
                    className="button"
                    onClick={() => remove(index)}
                    data-test={`btnDeleteLanguageTest-${index}`}
                  >
                    <span className="icon">
                      <i className="fas fa-trash"></i>
                    </span>
                  </button>
                </div>
              )
            })}

            {!loading ? (
              <div className="is-flex is-justify-content-space-between is-align-items-center mt-3">
                <div>
                  <button type="submit" className="button" data-test="btnSaveItemTest">
                    Save
                  </button>
                  {onCancel && (
                    <button type="submit" className="button ml-4 is-danger" onClick={onCancel}>
                      Cancel
                    </button>
                  )}
                </div>

                <button
                  type="button"
                  className="button is-info"
                  onClick={() => append({})}
                  data-test="btnAddMoreLangugesTest"
                >
                  <span className="icon">
                    <i className="fas fa-plus"></i>
                  </span>
                </button>
              </div>
            ) : (
              <Spin />
            )}
          </form>
        )}
      </div>
    )
  }
)
