import { EyeOutlined, LogoutOutlined } from '@ant-design/icons'
import {
  Confirm,
  ItemModel,
  SearchInput,
  parser,
  useCrudContext,
  useLocation,
  useTraversal,
} from '@guillotinaweb/react-gmi'
import { Button, Collapse, Divider, Spin, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useSetState } from 'react-use'

import { PropertiesDetail } from 'components/Properties'
import { get, getRelativePath } from 'helpers/utils'
import { getContainerFromPath } from 'services/client'

const searchObjectTypeParsed = parser('type_name=AcreditacioProfessional')
const searchPublishedParsed = parser('review_state=publicat')

const AcreditatItemComponent = ({ schema, ignoreFiels }) => {
  const [, setLocation] = useLocation()
  const { patch, loading } = useCrudContext()
  const [open, setOpen] = React.useState(false)
  const traversal = useTraversal()
  const modifyContent = traversal.hasPerm('guillotina.ModifyContent')

  const [acreditat, setAcreditat] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const model = new ItemModel(traversal.context)

  useEffect(() => {
    ;(async () => {
      if (
        model &&
        model.item?.acreditat &&
        !acreditat.loading &&
        !acreditat.data &&
        !acreditat.error
      ) {
        setAcreditat({ loading: true })
        const response = await traversal.client.get(
          `${getContainerFromPath(traversal.path)}/@search?id=${model.item.acreditat}`
        )

        const responseData = await response.json()
        const responseObject = await traversal.client.get(get(responseData, 'items.0.path', ''))
        if (responseObject.ok) {
          setAcreditat({ loading: false, data: await responseObject.json() })
        } else {
          setAcreditat({
            loading: false,
            error: 'Error to get acreditat',
            data: {},
          })
        }
      }
    })()
  }, [model])

  const updateAcreditat = async (newAcreditat) => {
    const dataPatch = await patch({
      acreditat: newAcreditat.id,
    })
    if (dataPatch.isError) {
      traversal.flash(`Error when update acreditat ${dataPatch.errorMessage}!`, 'danger')
    } else {
      traversal.flash(`Acreditat updated!`, 'success')
    }

    traversal.refresh()
  }

  return (
    <>
      <Divider orientation="left">Acreditat</Divider>
      {modifyContent && (
        <>
          <div className="is-flex is-align-items-center">
            <Typography.Text>Afageix o modifica l&apos;acreditat assignat</Typography.Text>
            <SearchInput
              path={`${getContainerFromPath(traversal.path)}${traversal.client.getYearFromPath(
                model.item['@id']
              )}/`}
              qs={[...searchObjectTypeParsed, ...searchPublishedParsed]}
              client={traversal.client}
              onChange={updateAcreditat}
              btnClass="is-small ml-4"
              queryCondition="title"
            />
            {loading && (
              <>
                <div className="ml-4 mr-4"> Actualitzant ... </div> <Spin />
              </>
            )}
          </div>
          <Divider />
        </>
      )}

      {acreditat.data && !acreditat.loading && (
        <>
          {modifyContent && (
            <>
              <div className="is-flex is-align-items-center">
                <Typography.Text>Elimina l&apos;acreditat</Typography.Text>
                <Button onClick={() => setOpen(true)} className="is-small ml-4">
                  Eliminar
                </Button>
              </div>
              <Divider />
            </>
          )}
          <Collapse>
            <Collapse.Panel
              header={`Acreditat: ${acreditat.data.title}`}
              key="1"
              extra={
                <>
                  <EyeOutlined
                    onClick={(event) => {
                      setLocation({ path: getRelativePath(acreditat.data['@id']) })
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation()
                    }}
                  />
                  <a
                    className="ml-2"
                    onClick={(e) => e.stopPropagation()}
                    style={{ color: '#000' }}
                    href={`/?path=/${getRelativePath(acreditat.data['@id'])}/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LogoutOutlined />
                  </a>
                </>
              }
            >
              <PropertiesDetail
                schema={schema.data}
                model={acreditat.data}
                ignoreFields={ignoreFiels}
                linkFields={['facebook', 'instagram', 'linkedin', 'twitter', 'youtube', 'web']}
              />
            </Collapse.Panel>
          </Collapse>
          <Divider />
        </>
      )}
      {model && model.item && !model.item.acreditat && (
        <>
          <Typography.Title level={5}>Sense Acreditat</Typography.Title>
          <Divider />
        </>
      )}

      {open && (
        <Confirm
          loading={loading}
          onCancel={() => setOpen(false)}
          onConfirm={() => updateAcreditat({ id: null })}
          message={`Are you sure to remove: ${acreditat.data.title}?`}
        />
      )}
    </>
  )
}

export const AcreditatItem = React.memo(AcreditatItemComponent)
