import * as React from 'react'
import { useGuillotinaClient, useTraversal, ItemModel } from '@guillotinaweb/react-gmi'
import { SearchInput } from './SearchInput'

export const InputSearchList = ({ value, type, path = null, onChange, dataTest, qs, ...rest }) => {
  const client = useGuillotinaClient()
  const traversal = useTraversal()

  const model = new ItemModel(traversal.context)

  const addTags = (event) => {
    onChange([...value, event])
  }

  return (
    <div className="control">
      <div className="tags">
        {value.map((tag, index) => (
          <div key={`input_list_${tag.path}_${index}`} className="tag is-info is-medium">
            {tag.title}
            <button
              className="delete is-small"
              onClick={() => onChange([...value.filter((itemTag) => itemTag.path !== tag.path)])}
            ></button>
          </div>
        ))}
      </div>
      <SearchInput
        path={
          path ??
          `${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${client.getYearFromPath(
            model.item['@id']
          )}/`
        }
        qs={[...(qs ?? [])]}
        client={client}
        dataTest={dataTest}
        onChange={(value) => {
          if (value !== '') {
            addTags(value)
          }
        }}
        btnClass="mb-3"
        queryCondition="title"
        {...rest}
      />
    </div>
  )
}
