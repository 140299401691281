export const proposalTitles = {
  tipus: 'Format',
  caracteristiques: "Ubicació de l'espectacle",
  nom: 'Nom espectacle',
  idioma: "Idioma de l'espectacle amb propostes de text",
  localitat: 'Desplaçament',
  altres_drets: 'Drets gestionats per altres',
  caixet: 'Caixet habitual',
  imatge_companyia: 'Fotografia promocional 1',
}
