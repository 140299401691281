import { useContext, useEffect, useMemo } from 'react'
import { useSetState, usePreviousDistinct } from 'react-use'
import { TraversalContext, useLocation } from '@guillotinaweb/react-gmi'
import { getContainerFromPath } from 'services/client'

export function Breadcrumbs() {
  const ctx = useContext(TraversalContext)
  const [breadcrumbs, setBreadcrumbs] = useSetState({
    data: undefined,
    loading: false,
    path: ctx.path,
  })
  const [, navigate] = useLocation()
  const prevPath = usePreviousDistinct(breadcrumbs)

  useEffect(async () => {
    if (!breadcrumbs.loading && (!breadcrumbs.data || (prevPath && prevPath.path !== ctx.path))) {
      setBreadcrumbs({ loading: true })
      const res = await ctx.client.get(`${ctx.path}@breadcrumbs`)
      let breadcrumbsData = {}
      if (res.status === 200) {
        breadcrumbsData = await res.json()
      }
      setBreadcrumbs({ loading: false, data: breadcrumbsData, path: ctx.path })
    }
  }, [breadcrumbs, setBreadcrumbs, ctx.path, prevPath])

  const getBreadcrumbsFormated = useMemo(() => {
    const containerPath = getContainerFromPath(ctx.path.replace(/\/$/, ''))
    let segmentsBreadcrumbs = containerPath.split('/').filter((seg) => seg !== '')
    segmentsBreadcrumbs = ['', ...segmentsBreadcrumbs]
    const linksBreadcrumbs = buildPaths(segmentsBreadcrumbs)

    if (breadcrumbs.data && breadcrumbs.data.items) {
      breadcrumbs.data.items.forEach((breadcrumb) => {
        segmentsBreadcrumbs.push(breadcrumb.title || breadcrumb.id)
        const path = breadcrumb['@id'].split('//')[1].split('/').splice(1).join('/')
        linksBreadcrumbs.push(`/${path}/`)
      })
    }
    return [segmentsBreadcrumbs, linksBreadcrumbs]
  }, [ctx.path, breadcrumbs])

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        {getBreadcrumbsFormated[0].map((item, indx) => {
          const path = getBreadcrumbsFormated[1][indx]
          const onClick = (e) => {
            if (window.event.ctrlKey || window.event.metaKey) return
            e.preventDefault()
            navigate({ path }, true)
          }

          return indx === 0 ? (
            <li key={indx}>
              <a href={path} onClick={onClick}>
                <span className="icon">
                  <i className="fas fa-home"></i>
                </span>
              </a>
            </li>
          ) : (
            <li key={indx}>
              <a href={path} onClick={onClick}>
                {item}
              </a>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const buildPaths = (segments) => {
  let current = ''
  const results = []
  segments.map((item, indx) => {
    if (indx === 0) {
      current += '/'
    } else {
      current += item + '/'
    }
    results.push(current)
    return item
  })
  return results
}
