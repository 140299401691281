import { getItemParsed } from './utils'

export const arrayVocabulariesFilters = [
  {
    filter: 'pais',
    vocabularyName: 'countriesfm',
    label: 'País',
    resultObj: 'paisObj',
  },
  {
    filter: 'rol',
    vocabularyName: 'rol_fira',
    label: 'Rol',
    resultObj: 'rolObj',
  },
]

export const arrayFilters = [
  ...arrayVocabulariesFilters,
  {
    filter: 'type_name',
    label: 'Tipus acreditació',
    values: [
      {
        title: 'Acreditació Professional',
        token: 'AcreditacioProfessional',
      },
      {
        title: 'Acreditació Premsa',
        token: 'AcreditacioPremsa',
      },
    ],
  },
  {
    filter: 'ens_type_name',
    label: 'Tipus entitat',
    values: [
      {
        title: 'Professional',
        token: 'EnsProfessional',
      },
      {
        title: 'Premsa',
        token: 'EnsPremsa',
      },
      {
        title: 'Serveis',
        token: 'EnsServeis',
      },
      {
        title: 'Artistes/Representants',
        token: 'Ens',
      },
    ],
  },
  {
    filter: 'ens_tipus',
    label: 'Tipus entitat Artista/Representant',
    values: [
      {
        title: 'Artista',
        token: 'artista',
      },
      {
        title: 'Representant',
        token: 'representant',
      },
    ],
  },
  {
    filter: 'idioma',
    label: 'Idioma',
    values: [
      {
        title: 'Català',
        token: 'lang-ca',
      },
      {
        title: 'Español',
        token: 'lang-es',
      },
      {
        title: 'English',
        token: 'lang-en',
      },
    ],
  },
  {
    filter: 'review_state',
    label: 'Estat',
    values: [
      {
        title: 'Pendent',
        token: 'pendent',
      },
      {
        title: 'Publicat',
        token: 'publicat',
      },
      {
        title: 'Descartat',
        token: 'descartat',
      },
    ],
  },
  {
    filter: 'procedencia',
    label: 'Procedència',
    values: [
      {
        title: 'Catalunya',
        token: 'catalunya',
      },
      {
        title: 'Espanya',
        token: 'espanya',
      },
      {
        title: 'Internacional',
        token: 'internacional',
      },
    ],
  },
  {
    filter: 'reporter_grafic',
    label: 'Reporter gràfic',
    values: [
      {
        title: 'Si',
        token: 'True',
      },
      {
        title: 'No',
        token: 'False',
      },
    ],
  },
]

export const arrayFiltersInputs = [
  {
    filter: 'nom',
    label: 'Nom',
  },
  {
    filter: 'cognoms',
    label: 'Cognoms',
  },
  {
    filter: 'num',
    label: 'Número',
  },
  {
    filter: 'ens_title',
    label: 'Titol entitat',
  },
]

export const getParamsFilters = (location) => {
  let result = []
  const resultQueryParams = []
  arrayFilters.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  arrayFiltersInputs.forEach((filterObj) => {
    const { filter } = filterObj
    result = getItemParsed(result, resultQueryParams, location, filter)
  })

  return [result, resultQueryParams]
}
