import { memo, useState } from 'react'
import { Input } from 'antd'
import { useLocation } from '@guillotinaweb/react-gmi'
const { Search } = Input

const InputSearchComponent = ({ value, type }) => {
  const [inputValue, setInputValue] = useState(value)
  const [, setLocation, remove] = useLocation()
  return (
    <Search
      placeholder="buscar..."
      allowClear
      onChange={(e) => setInputValue(e.target.value)}
      onSearch={(value) => {
        if (value === '') {
          remove(type)
        } else {
          setLocation({ [type]: value, page: 0 })
        }
      }}
      style={{ width: '100%' }}
      value={inputValue}
    />
  )
}

export const InputSearchFilter = memo(InputSearchComponent)
InputSearchFilter.displayName = 'InputSearchFilter'
export default InputSearchFilter
