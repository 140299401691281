import { useEffect, useState, Fragment, useMemo } from 'react'
import { useSetState } from 'react-use'

import { Form, useTraversal, useGuillotinaClient } from '@guillotinaweb/react-gmi'
import { EditComponent } from 'components/Fields'

export const PerformanceForm = ({
  onSubmit,
  title,
  loading,
  dataTest,
  actionName,
  multipleData = false,
  multipleShows = false,
}) => {
  const traversal = useTraversal()
  const client = useGuillotinaClient()

  const [formData, setFormData] = useState({
    data: `${new Date().getFullYear()}-10-01T00:00`,
  })
  const [errors, setErrors] = useState({})
  const [orderFieldsForm, setOrderFieldsForm] = useState([
    ...(multipleShows ? ['espectacles'] : []),
    'data',
    'amagar_info_data',
    'espai',
    'amagar_info_espai',
    'preu_anticipada',
    'preu',
    'preu_access_professional',
  ])
  const [requiredFields, setRequiredFields] = useState([
    'data',
    'espai',
    'preu',
    ...(multipleShows ? ['espectacles'] : []),
  ])

  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
    formFields: [],
  })

  const totalDates = useMemo(() => {
    return orderFieldsForm.filter((item) => item.startsWith('data')).length
  }, [orderFieldsForm])

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'Actuacio')
          setSchema({
            loading: false,
            data: dataJson,
          })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const submit = () => {
    const currentErrors = {}

    requiredFields.forEach((key) => {
      if (formData[key] === null || formData[key] === undefined) {
        currentErrors[key] = 'This field is required'
      }
    })

    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors)
      return
    }

    const finalData = Object.assign({}, formData)
    if (!('espectacles' in finalData)) {
      finalData['espectacles'] = [client.cleanPathWithContainer(traversal.context['@id'])]
    } else {
      finalData['espectacles'] = (finalData['espectacles'] ?? []).map((item) => item.path)
    }
    if (multipleData && totalDates > 1) {
      const allFormsData = []
      ;[...Array(totalDates).keys()].map((number) => {
        if (number > 0) {
          finalData['data'] = finalData[`data_${number}`]
        }
        allFormsData.push(Object.assign({}, finalData))
      })
      onSubmit(allFormsData)
    } else {
      onSubmit(finalData)
    }
  }

  return (
    <>
      {multipleData && (
        <>
          <button
            type="button"
            className="button is-info mb-3"
            data-test="btnAddMoreDatesPerformanceTest"
            onClick={() => {
              orderFieldsForm.splice(totalDates, 0, `data_${totalDates}`)
              requiredFields.splice(totalDates, 0, `data_${totalDates}`)

              setRequiredFields(Object.assign([], requiredFields))
              setOrderFieldsForm(Object.assign([], orderFieldsForm))
            }}
          >
            <span className="icon">
              <i className="fas fa-plus"></i>
            </span>
            <span>Afegeix més dates</span>
          </button>
          {totalDates > 1 && (
            <button
              type="button"
              className="button is-info mb-3 ml-5"
              data-test="btnSubtractDatesPerformanceTest"
              onClick={() => {
                orderFieldsForm.splice(totalDates - 1, 1)
                requiredFields.splice(totalDates - 1, 1)

                setRequiredFields(Object.assign([], requiredFields))
                setOrderFieldsForm(Object.assign([], orderFieldsForm))
              }}
            >
              <span className="icon">
                <i className="fas fa-minus"></i>
              </span>
              <span>Elimina l&apos;última data</span>
            </button>
          )}
        </>
      )}
      <Form title={title} onSubmit={submit} dataTest={dataTest}>
        {schema &&
          schema.data &&
          !schema.loading &&
          orderFieldsForm.map((key) => {
            const keySchema = key.startsWith('data') ? 'data' : key
            const value = schema.data.properties[keySchema]
            return (
              <Fragment key={key}>
                <EditComponent
                  id={key}
                  schema={value}
                  val={key in formData ? formData[key] : ''}
                  placeholder={value?.title ?? ''}
                  required={requiredFields.includes(key)}
                  setValue={(ev) => {
                    setFormData({ ...formData, [key]: ev })
                  }}
                  error={errors[key]}
                  dataTest={`${key}TestInput`}
                />
              </Fragment>
            )
          })}

        <div className="level level-right">
          <button
            type="submit"
            className={`button is-success ${loading ? 'is-loading' : ''}`}
            data-test="formBaseBtnTestSubmit"
          >
            {actionName || 'Add'}
          </button>
        </div>
      </Form>
    </>
  )
}
