import React, { Fragment, useEffect, useState } from 'react'
import { useSetState } from 'react-use'

import {
  TraversalContext,
  ItemModel,
  Icon,
  PropertiesButtonView,
  BehaviorsView,
  useLocation,
} from '@guillotinaweb/react-gmi'
import { Divider, Button } from 'antd'
import {
  AddItemToObject,
  PropertiesDetail,
  DeleteItemFromObject,
  WorkflowPayment,
} from 'components/Properties'
import { LogoutOutlined, EyeOutlined } from '@ant-design/icons'
import { getRelativePath } from 'helpers/utils'

import { processResponse } from 'helpers/utils'
import { PaymentDetail } from './PaymentDetail'
import { dynamicFunctionsPaymentForm } from 'helpers/payments'

const ignoreFiels = [
  'guillotina.behaviors.dublincore.IDublinCore',
  'guillotina.contrib.workflows.interfaces.IWorkflowBehavior',
  '__behaviors__',
  'type_name',
  'creation_date',
  'modification_date',
  'objectes_pagament',
  'informacio_pagament',
]

export function PanelProperties() {
  const [loadingActions, setLoadingActions] = useState(false)
  const [, setLocation] = useLocation()
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const Ctx = React.useContext(TraversalContext)
  const modifyContent = Ctx.hasPerm('guillotina.ModifyContent')

  const model = new ItemModel(Ctx.context)

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await Ctx.client.getTypeSchema(Ctx.path, model.type)
          setSchema({ loading: false, data: dataJson })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  const handleSendEmailBill = async () => {
    setLoadingActions(true)
    let processResult = {}
    try {
      const response = await Ctx.client.post(
        `${Ctx.url}${Ctx.client.cleanPathWithContainer(model.item['@id'])}/@sendEmailSendBill`
      )
      processResult = await processResponse(response, true)
    } catch (e) {
      console.error('Error', e)
      processResult = { isError: true, errorMessage: 'unhandled exception' }
    } finally {
      setLoadingActions(false)
      const { isError, errorMessage } = processResult
      if (!isError) {
        Ctx.flash(`Email send!`, 'success')
      } else {
        Ctx.flash(`Failed to send email!: ${errorMessage}`, 'danger')
      }
      Ctx.refresh()
    }
  }

  const renderObjectInfo = (objData) => {
    return (
      <div className="box is-flex is-justify-content-space-between is-align-items-center">
        <div>
          <div>{objData.title}</div>
          <div>{objData.type_name}</div>
        </div>
        <div>
          {modifyContent && (
            <DeleteItemFromObject
              isMultiple
              path={objData['uuid']}
              label={objData.type_name}
              field="objectes_pagament"
            />
          )}
          <EyeOutlined
            onClick={(event) => {
              setLocation({ path: getRelativePath(objData['@id']) })
              // If you don't want click extra trigger collapse, you can prevent this:
              event.stopPropagation()
            }}
          />
          <a
            className="ml-2"
            onClick={(e) => e.stopPropagation()}
            style={{ color: '#000' }}
            href={`/?path=/${getRelativePath(objData['@id'])}/`}
            target="_blank"
            rel="noreferrer"
          >
            <LogoutOutlined />
          </a>
        </div>
      </div>
    )
  }

  return (
    <div className="container">
      <div className="level">
        <div className="level-left">
          <h2 className="title is-size-4 is-primary">
            <Icon icon={model.icon} align="is-left" className="has-text-grey" />
            &nbsp;
            <span>{Ctx.context.title || Ctx.context['@name']}</span>
          </h2>
        </div>
        <div className="level-right">
          <PropertiesButtonView />
        </div>
      </div>
      <hr />

      {schema && schema.data && !schema.loading && (
        <>
          {modifyContent && model.item.factura_fitxer && (
            <>
              <Divider orientation="left">Enviar Factura</Divider>
              <Button
                className="mr-5"
                type="primary"
                disabled={loadingActions}
                onClick={() => {
                  handleSendEmailBill()
                }}
              >
                Enviar
              </Button>
              {loadingActions && <div className="progress-line mt-2"></div>}
            </>
          )}
          {modifyContent && (
            <>
              <Divider orientation="left">Workflow</Divider>
              <WorkflowPayment />
            </>
          )}
          <Divider orientation="left">Objectes pagament</Divider>
          {model.item.objectes_pagament_data.map((objData) => (
            <Fragment key={objData['uuid']}>{renderObjectInfo(objData)}</Fragment>
          ))}
          <Divider orientation="left">Linies pagament</Divider>
          {model && <PaymentDetail payment={model.item} />}
          {modifyContent && model && (
            <AddItemToObject
              useUuid
              isMultiple
              qs={dynamicFunctionsPaymentForm['getQueryObjectsPayment'](
                model.item['parent']['@uid']
              )}
              label="Afageix un objecte"
              field="objectes_pagament"
              path={dynamicFunctionsPaymentForm['getPathObjectsPayment'](model, Ctx.client)}
            />
          )}
          <Divider orientation="left">Dades</Divider>
          <PropertiesDetail
            schema={schema.data}
            model={model.item}
            canModified={modifyContent}
            ignoreFields={ignoreFiels}
          />
          <BehaviorsView context={Ctx.context} schema={schema.data} />
        </>
      )}
    </div>
  )
}
