import { useState } from 'react'
import { Row, Col } from 'antd'
import { Confirm, useCrudContext, useTraversal, ItemModel } from '@guillotinaweb/react-gmi'

export function WorkflowShow() {
  const [workflowAction, setWorkflowAction] = useState(null)
  const { post, loading } = useCrudContext()
  const traversal = useTraversal()
  const model = new ItemModel(traversal.context)
  const currentState =
    model.item['guillotina.contrib.workflows.interfaces.IWorkflowBehavior']['review_state']
  const publicShowAction = async () => {
    const { isError, errorMessage } = await post({}, `@workflow/${workflowAction}`, false)

    if (!isError) {
      traversal.flash(`Great status changed!`, 'success')
    } else {
      traversal.flash(`Failed to status changed!: ${errorMessage}`, 'danger')
    }

    traversal.refresh()
    setWorkflowAction(null)
  }

  return (
    <>
      {workflowAction && (
        <Confirm
          loading={loading}
          onCancel={() => setWorkflowAction(null)}
          onConfirm={publicShowAction}
          message={`Are you sure to change state: ${
            traversal.context.title || traversal.context['@name']
          }?`}
        />
      )}
      <Row className="is-flex is-align-items-center">
        <Col span={5} className="has-text-weight-bold" data-test={`textInfoStatus-${currentState}`}>
          {currentState === 'publicat' ? `ESTAT: PUBLICAT` : `ESTAT: EN ESBORRANY`}
        </Col>
        {currentState !== 'publicat' ? (
          <button
            className="button"
            onClick={() => setWorkflowAction('publicar')}
            data-test="btnPublicShowTest"
          >
            Publicar
          </button>
        ) : (
          <button
            className="button"
            onClick={() => setWorkflowAction('amagar')}
            data-test="btnHideShowTest"
          >
            Passar a esborrany
          </button>
        )}
      </Row>
    </>
  )
}
