import React from 'react'

import { LogoutOutlined } from '@ant-design/icons'
import {
  Delete,
  ItemCheckbox,
  ItemModel,
  useLocation,
  useTraversal,
} from '@guillotinaweb/react-gmi'
import { ItemPublishUnpublishCheckbox } from 'components/Actions/SelectedItemsActionsPublishUnpublish'

export function RItem({ item, search, columns }) {
  const [, navigate] = useLocation()
  const traversal = useTraversal()
  const model = new ItemModel(item, traversal.url, traversal.path)
  const link = () => navigate({ path: model.path }, true)

  return (
    <tr key={item} data-test={`itemTest-${item['@name']}`}>
      <td style={{ width: 25 }}>
        <ItemCheckbox item={item} />
      </td>
      {columns.map((i) => (
        <React.Fragment key={i.label}>{i.child(model, link, search)}</React.Fragment>
      ))}
      <td style={{ width: 25 }}>
        <div className="level">
          {traversal.hasPerm('guillotina.DeleteContent') && (
            <div className="level-item has-text-centered">
              <Delete onClick={() => traversal.doAction('removeItems', { items: [model.item] })} />
            </div>
          )}
          <a
            className="level-item has-text-centered ml-3"
            style={{ color: '#000' }}
            href={`/?path=${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${item.path}/`}
            target="_blank"
            rel="noreferrer"
          >
            <LogoutOutlined style={{ fontSize: '20px' }} />
          </a>
        </div>
      </td>
    </tr>
  )
}

export function RItemPublishUnpublish({ item, search, columns }) {
  const [, navigate] = useLocation()
  const traversal = useTraversal()
  const model = new ItemModel(item, traversal.url, traversal.path)
  const link = () => navigate({ path: model.path }, true)

  return (
    <tr key={item} data-test={`itemTest-${item['@name']}`}>
      <td style={{ width: 25 }}>
        <ItemPublishUnpublishCheckbox item={item} />
      </td>
      {columns.map((i) => (
        <React.Fragment key={i.label}>{i.child(model, link, search)}</React.Fragment>
      ))}
      <td style={{ width: 25 }}>
        <div className="level">
          {traversal.hasPerm('guillotina.DeleteContent') && (
            <div className="level-item has-text-centered">
              <Delete onClick={() => traversal.doAction('removeItems', { items: [model.item] })} />
            </div>
          )}
          <a
            className="level-item has-text-centered ml-3"
            style={{ color: '#000' }}
            href={`/?path=${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}${item.path}/`}
            target="_blank"
            rel="noreferrer"
          >
            <LogoutOutlined style={{ fontSize: '20px' }} />
          </a>
        </div>
      </td>
    </tr>
  )
}
