import {
  AllItemsCheckbox,
  Confirm,
  Icon,
  ItemsActionsDropdown,
  ItemsActionsProvider,
  Pagination,
  SearchLabels,
  buildQs,
  parser,
  useConfig,
  useLocation,
  useTraversal,
} from '@guillotinaweb/react-gmi'
import { Button } from 'antd'
import { MAP_TYPE_NAME_LABEL } from 'helpers/constants'
import { get } from 'helpers/utils'
import { useEffect, useState } from 'react'
import { useSetState } from 'react-use'
import { RItem } from '../Items'

const initialState = {
  page: 0,
  items: [],
  loading: true,
  total: 0,
}

const getColumns = () => {
  const smallcss = { width: 25 }
  const mediumcss = { width: 120 }
  return [
    {
      label: '',
      child: (m) => <td style={smallcss}>{<Icon icon={m.icon} />}</td>,
    },
    {
      label: 'type',
      child: (m, navigate) => (
        <td style={smallcss} onClick={navigate}>
          <span className="tag">{MAP_TYPE_NAME_LABEL[m.type]}</span>
        </td>
      ),
    },
    {
      label: 'Data Inici',
      child: (m, navigate) => (
        <td style={mediumcss} className="is-size-7 is-vcentered" onClick={navigate}>
          {get(m, 'item.data_inici', '')}
        </td>
      ),
    },
    {
      label: 'Data fi',
      child: (m, navigate) => (
        <td style={mediumcss} className="is-size-7 is-vcentered" onClick={navigate}>
          {get(m, 'item.data_fi', '')}
        </td>
      ),
    },
    {
      label: 'Acreditat',
      child: (m, navigate) => (
        <td style={mediumcss} className="is-size-7 is-vcentered" onClick={navigate}>
          {get(m, 'item.acreditat_email', '')}
        </td>
      ),
    },
    {
      label: 'created',
      child: (m) => (
        <td style={mediumcss} className="is-size-7 is-vcentered">
          {m.created}
        </td>
      ),
    },
    {
      label: 'modified',
      child: (m) => (
        <td style={mediumcss} className="is-size-7 is-vcentered">
          {m.updated}
        </td>
      ),
    },
  ]
}

const depthParsed = parser('depth=1')

export function PanelItems() {
  const [location, setLocation] = useLocation()
  const { PageSize } = useConfig()
  const [loadingActions, setLoadingActions] = useState(false)
  const [openSendEmails, setOpenSendEmails] = useState(false)

  const Ctx = useTraversal()
  const [state, setState] = useSetState(initialState)
  const { items, loading, total } = state
  const columns = getColumns()

  const search = location.get('q')
  const type = location.get('type')
  let page

  try {
    page = parseInt(location.get('page')) || 0
  } catch {
    page = 0
  }

  let searchParsed = undefined
  if (search && search !== '') {
    searchParsed = parser(search)
  }

  let typeParsed = undefined
  if (type && type !== '') {
    typeParsed = parser(type, 'type_name')
  }

  useEffect(() => {
    if (Ctx.state.loading) return
    ;(async () => {
      let data
      setState({ loading: true, total: Ctx.context.length })
      if (search || type) {
        const qs = buildQs([...(searchParsed ?? []), ...(typeParsed ?? []), ...depthParsed])
        data = await Ctx.client.search(Ctx.path, qs, false, false, page * PageSize, PageSize)
      } else {
        data = await Ctx.client.getItems(Ctx.path, page * PageSize, PageSize)
      }
      setState({
        items: data.items,
        loading: false,
        total: data.items_count,
      })
    })()
  }, [search, type, Ctx.state.refresh, page])

  const doPaginate = (page) => {
    setLocation({ page: page })
  }

  const handleDownloadXlsx = async (xlsxName, filename) => {
    const yearFromPath = Ctx.path.split('/')[3]
    setLoadingActions(true)
    const endpoint = `${Ctx.url}${yearFromPath}/@getReunionsRapidesExcel`
    const res = await Ctx.client.download(endpoint)

    const text = await res.blob()
    const blob = new Blob([text], {
      type: '',
    })
    const url = window.URL.createObjectURL(blob)

    // Create blob link to download
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `reunions_rapides.xlsx`)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
    link.parentNode?.removeChild(link)
    setLoadingActions(false)
  }

  const handleSendEmails = async () => {
    setLoadingActions(true)
    const endpoint = `/${Ctx.path.split('/').slice(1, 4).join('/')}/@sendEmailReunionsRapides`
    const response = await Ctx.client.get(endpoint)
    if (response.ok) {
      Ctx.flash(`Emails enviats!`, 'success')
    } else {
      Ctx.flash(`Error al enviar els emails`, 'danger')
    }
    setLoadingActions(false)
    setOpenSendEmails(false)
  }

  return (
    <ItemsActionsProvider items={items}>
      <Button
        className="mr-5 mb-2"
        type="primary"
        onClick={() => {
          setOpenSendEmails(true)
        }}
      >
        Enviar email acreditats
      </Button>
      <Button
        className="mr-5"
        type="primary"
        disabled={loadingActions}
        onClick={() => {
          handleDownloadXlsx()
        }}
      >
        Descarregar XLSX reunions
      </Button>
      {loadingActions && <div className="progress-line mt-2"></div>}
      <div className="columns">
        <div className="column is-2 is-size-7">
          <ItemsActionsDropdown />
        </div>
        <div className="column">
          <SearchLabels />
        </div>
        <div className="column">
          <SearchLabels label="Type" query="type" />
        </div>
        <div className="column">
          <Pagination
            current={page}
            total={total}
            key="pagination-top"
            doPaginate={doPaginate}
            pager={PageSize}
          />
        </div>
      </div>
      {loading && <div className="progress-line"></div>}
      {!loading && (
        <table className="table is-fullwidth is-hoverable" data-test="itemPanelTableTest">
          <thead className="is-size-7">
            <tr>
              <th>
                <AllItemsCheckbox />
              </th>
              {columns.map((i) => (
                <th key={i.label} className="has-text-info">
                  {i.label}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item) => (
                <RItem item={item} key={item['@uid']} search={search} columns={columns} />
              ))}
            {items && items.length === 0 && (
              <tr>
                <td colSpan="6" className="has-text-centered">
                  Anything here!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <div className="columns is-centered">
        <Pagination
          current={page}
          key="pagination-bottom"
          total={total}
          doPaginate={doPaginate}
          pager={PageSize}
        />
      </div>
      {openSendEmails && (
        <Confirm
          loading={loadingActions}
          onCancel={() => setOpenSendEmails(false)}
          onConfirm={handleSendEmails}
          message="Estàs segur d'enviar l'email de reunions rapides a tots els acreditats?"
        />
      )}
    </ItemsActionsProvider>
  )
}
