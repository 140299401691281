import React, { Fragment, useEffect } from 'react'
import { useSetState } from 'react-use'

import { Divider } from 'antd'
import { useTraversal, ItemModel, Icon, BehaviorsView, parser } from '@guillotinaweb/react-gmi'

import { PropertiesDetail } from 'components/Properties'
import {
  CollapseItemRender,
  DeleteItemFromObject,
  AddItemToObject,
  WorkflowShow,
} from 'components/Properties'

const ignoreFiels = [
  'guillotina.behaviors.dublincore.IDublinCore',
  'guillotina.behaviors.attachment.IMultiAttachment',
  'guillotina.contrib.image.behaviors.IMultiImageAttachment',
  'guillotina.contrib.workflows.interfaces.IWorkflowBehavior',
  '__behaviors__',
  'type_name',
  'creation_date',
  'modification_date',
  'proposta_original',
  'llista_procedencia_keys',
  'artistes',
]

export function PanelProperties() {
  const [schema, setSchema] = useSetState({
    data: undefined,
    loading: false,
    error: undefined,
  })
  const traversal = useTraversal()
  const modifyContent = traversal.hasPerm('guillotina.ModifyContent')
  const model = new ItemModel(traversal.context)

  useEffect(() => {
    ;(async () => {
      if (!schema.loading && !schema.data && !schema.error) {
        try {
          setSchema({ loading: true })
          const dataJson = await traversal.client.getTypeSchema(traversal.path, 'Espectacle')
          setSchema({ loading: false, data: dataJson })
        } catch (err) {
          setSchema({ loading: false, error: err })
        }
      }
    })()
  }, [schema])

  return (
    <div className="container" data-test="panelPropertiesTest">
      <div className="level">
        <div className="level-left">
          <h2 className="title is-size-4 is-primary">
            <Icon icon={model.icon} align="is-left" className="has-text-grey" />
            &nbsp;
            <span>{traversal.context.title || traversal.context['@name']}</span>
          </h2>
        </div>
      </div>
      <hr />

      {schema && schema.data && !schema.loading && (
        <Fragment>
          {modifyContent && <WorkflowShow />}
          {modifyContent && model.item.proposta_original && (
            <CollapseItemRender
              label="Proposta"
              type="Proposta"
              path={model.item.proposta_original}
              moreActions={
                modifyContent && <DeleteItemFromObject label="proposal" field="proposta_original" />
              }
            />
          )}
          {modifyContent && !model.item.proposta_original && (
            <AddItemToObject
              qs={[
                ...parser('type_name=Proposta'),
                ...parser('review_state=presentada'),
                ...parser('estat_intern=pactada'),
              ]}
              label="Afageix la proposta"
              field="proposta_original"
            />
          )}
          {model.item.artistes.map((artista) => {
            return (
              <CollapseItemRender
                key={`render_${artista}`}
                label="Artista"
                type="Ens"
                path={artista}
                moreActions={
                  modifyContent && (
                    <DeleteItemFromObject
                      isMultiple
                      path={artista}
                      label="artist"
                      field="artistes"
                    />
                  )
                }
              />
            )
          })}
          {modifyContent && (
            <AddItemToObject
              isMultiple
              qs={[...parser('type_name=Ens'), ...parser('tipus=artista')]}
              label="Afageix un artista"
              field="artistes"
            />
          )}
          <Divider orientation="left"></Divider>
          <PropertiesDetail
            schema={schema.data}
            model={model.item}
            canModified={modifyContent}
            ignoreFields={ignoreFiels}
          />
          <BehaviorsView context={traversal.context} schema={schema.data} />
        </Fragment>
      )}
    </div>
  )
}
