import { Fragment, useEffect } from 'react'
import { useSetState } from 'react-use'
import { ItemModel, useTraversal, useLocation } from '@guillotinaweb/react-gmi'
import { Collapse } from 'antd'
import { LogoutOutlined, EyeOutlined } from '@ant-design/icons'
import { PropertiesDetail } from 'components/Properties'
import { get, getRelativePath } from 'helpers/utils'

export function EnsSection() {
  const traversal = useTraversal()
  const [, setLocation] = useLocation()
  const [data, setData] = useSetState({
    dataArtist: undefined,
    dataManager: undefined,
    loading: false,
    error: undefined,
  })

  const model = new ItemModel(traversal.context)

  useEffect(() => {
    ;(async () => {
      if (traversal.loading) return
      if (
        model.item &&
        model.item.parent &&
        !data.loading &&
        !data.dataArtist &&
        !data.dataManager &&
        !data.schema &&
        !data.error
      ) {
        setData({ loading: true })
        const data = await traversal.client.get(
          `${getRelativePath(get(model, 'item.@id', ''))}@artista`
        )
        const jsonSchema = await traversal.client.getTypeSchema(traversal.path, 'Ens')

        if (data.status === 200) {
          const dataJson = await data.json()
          if (dataJson.representant && dataJson.representant !== '') {
            const dataManager = await traversal.client.get(
              `${getRelativePath(get(model, 'item.@id', ''))}@representant`
            )
            if (dataManager.status === 200) {
              const dataManagerJson = await dataManager.json()
              setData({
                loading: false,
                dataArtist: dataJson,
                dataManager: dataManagerJson,
                schema: jsonSchema,
              })
            }
          } else {
            setData({
              loading: false,
              dataArtist: dataJson,
              schema: jsonSchema,
            })
          }
        } else {
          setData({ loading: false, error: 'Error when try to get artist' })
        }
      }
    })()
  }, [data, model])

  const renderEnsType = (ensData, label) => {
    return (
      <>
        <Collapse>
          <Collapse.Panel
            header={`${label}: ${ensData.title}`}
            key="1"
            extra={
              <>
                <EyeOutlined
                  onClick={(event) => {
                    setLocation({ path: getRelativePath(ensData['@id']) })
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation()
                  }}
                />
                <a
                  className="ml-2"
                  onClick={(e) => e.stopPropagation()}
                  style={{ color: '#000' }}
                  href={`/?path=/${getRelativePath(ensData['@id'])}/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LogoutOutlined />
                </a>
              </>
            }
          >
            <PropertiesDetail
              schema={data.schema}
              model={ensData}
              ignoreFields={[
                'guillotina.behaviors.dublincore.IDublinCore',
                '__behaviors__',
                'type_name',
                'creation_date',
                'modification_date',
                'declaracio_acceptar',
              ]}
              linkFields={['facebook', 'instagram', 'linkedin', 'twitter', 'youtube', 'web']}
            />
          </Collapse.Panel>
        </Collapse>
      </>
    )
  }

  return (
    <Fragment>
      {!data.loading && data.dataArtist && renderEnsType(data.dataArtist, 'Artista')}
      {!data.loading && data.dataManager && renderEnsType(data.dataManager, 'Representant')}
    </Fragment>
  )
}
