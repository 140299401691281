export const LinkField = ({ value }) => {
  const values = value !== '' ? value.split('\n') : null
  const urlify = (text) => {
    const urlRegex = /(https?:\/\/[\w/\-?=%&.;]+\.[\w/\-&?=%&.;]+)/g
    return text.replace(urlRegex, '<a target="_blank" rel="noreferrer" href="$1">$1</a>')
  }
  return (
    <>
      {values &&
        values.map((val, index) => {
          return (
            <div
              key={`${val}__${index}`}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: urlify(val) }} />
            </div>
          )
        })}
    </>
  )
}
