import { buildQs, parser, useGuillotinaClient } from '@guillotinaweb/react-gmi'
import { useEffect, useState } from 'react'
import { useSetState } from 'react-use'
import GuilloCfg from 'guillotina/config'
import { EditionProvider } from 'context/EditionContext'

export const wrapperEditionPages = (Component) => {
  return (props) => {
    const client = useGuillotinaClient()
    const [years, setYears] = useSetState({
      items: undefined,
      loading: false,
      total: 0,
    })
    const [selectedYear, setSelectedYear] = useState(undefined)

    useEffect(() => {
      ;(async () => {
        if (!years.loading && !years.items) {
          setYears({ loading: true })
          const sortParsed = parser(`_sort_des=id`)

          const qs = buildQs([...sortParsed, ...parser(`type_name=Year`)])
          const response = await client.search(GuilloCfg.url, qs, false, false, 0, 50)
          if (response && response.error) {
            props.onLogout()
            setYears({
              loading: false,
            })
          } else {
            if (response.items_total > 0) {
              setSelectedYear(response.items[0].id)
            }

            setYears({
              items: response.items,
              loading: false,
              total: response.items_total,
            })
          }
        }
      })()
    }, [years, setYears, setSelectedYear])

    if (!years.items || years.loading) {
      return (
        <div className="box main-panel">
          <div className="progress-line" />
        </div>
      )
    }

    return (
      <EditionProvider year={selectedYear}>
        <Component
          {...props}
          years={years}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
        />
      </EditionProvider>
    )
  }
}
