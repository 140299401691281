import { useState } from 'react'
import { Layout } from './common/layout'
import { AuthToken } from './services/auth'
import { ClientProvider } from '@guillotinaweb/react-gmi'
import { Login } from 'components/Login/Login'
import { Guillo } from 'guillotina/guillotina'
import GuilloCfg from 'guillotina/config'
import { ProposalsPage } from 'pages/proposals'
import { AuthProvider } from 'context/AuthContext'

import { Router, Route, Switch, useLocation } from 'wouter'

import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'

import '@guillotinaweb/react-gmi/dist/css/style.css'

import 'antd/dist/antd.css'

import './scss/styles.sass'
import { PROPOSAL_STATUS } from 'helpers/constants'
import { getClient } from 'services/client'
import { EntitiesPage } from 'pages/entities'
import { AccreditationsPage } from 'pages/accreditations'
import { wrapperEditionPages } from 'components/WrapperEditionPages'
import { PaymentsPage } from 'pages/payments'

const ProposalPageComponent = wrapperEditionPages(ProposalsPage)
const EntitiesPageComponent = wrapperEditionPages(EntitiesPage)
const AccreditationsPageComponent = wrapperEditionPages(AccreditationsPage)
const PaymentsPageComponent = wrapperEditionPages(PaymentsPage)
const auth = new AuthToken(
  GuilloCfg.url,
  localStorage.getItem('token'),
  localStorage.getItem('isLogged')
)
const client = getClient(GuilloCfg.url, auth)

function App() {
  const [, setLocation] = useLocation()
  const [isLogged, setIsLogged] = useState(auth.isLogged)
  const onLogout = () => {
    auth.logout()
    setIsLogged(false)
  }

  return (
    <AuthProvider auth={auth}>
      <ClientProvider client={client}>
        <Layout isLogged={isLogged} onLogout={onLogout}>
          {isLogged && (
            <Router base="">
              <Switch>
                <Route
                  path="/fira-proposals-active"
                  component={() => (
                    <ProposalPageComponent
                      onLogout={onLogout}
                      reviewState={PROPOSAL_STATUS.ACTIVE}
                    />
                  )}
                />
                <Route
                  path="/fira-proposals-draft"
                  component={() => (
                    <ProposalPageComponent
                      onLogout={onLogout}
                      reviewState={PROPOSAL_STATUS.DRAFT}
                    />
                  )}
                />
                <Route
                  path="/fira-entities"
                  component={() => <EntitiesPageComponent onLogout={onLogout} />}
                />
                <Route
                  path="/fira-accreditations"
                  component={() => <AccreditationsPageComponent onLogout={onLogout} />}
                />
                <Route
                  path="/fira-payments"
                  component={() => <PaymentsPageComponent onLogout={onLogout} />}
                />
                <Route path="/:rest*" component={() => <Guillo />} />
              </Switch>
            </Router>
          )}
          {!isLogged && (
            <div className="columns is-centered">
              <div className="columns is-half">
                <Login
                  auth={auth}
                  onLogin={() => {
                    setIsLogged(auth.isLogged)
                    setLocation('fira-proposals-active')
                  }}
                />
                <GoogleReCaptchaProvider reCaptchaKey={'6Lc-RuEZAAAAAPybG1AjQcch0dZ9mXMw_OC0eyeM'}>
                  <GoogleReCaptcha />
                </GoogleReCaptchaProvider>
              </div>
            </div>
          )}
        </Layout>
      </ClientProvider>
    </AuthProvider>
  )
}

export default App
