import { useTraversal, useCrudContext, ItemModel } from '@guillotinaweb/react-gmi'
import { Divider, Typography, Spin } from 'antd'
import { SearchInput } from 'components/Fields/'

export const AddItemToObject = ({
  qs,
  label,
  field,
  path = null,
  isMultiple = false,
  useUuid = false,
}) => {
  const traversal = useTraversal()
  const { patch, loading } = useCrudContext()
  const model = new ItemModel(traversal.context)

  const doAction = async (newObj) => {
    let objValue = newObj.path
    if (useUuid) {
      objValue = newObj.uuid
    } else if (objValue.startsWith('/')) {
      objValue = objValue.slice(1)
    }
    let data = {}
    if (isMultiple) {
      data = {
        [field]: {
          op: 'appendunique',
          value: objValue,
        },
      }
    } else {
      data = {
        [field]: objValue,
      }
    }
    const dataPatch = await patch(data)
    if (dataPatch.isError) {
      traversal.flash(`Error when add ${dataPatch.errorMessage}!`, 'danger')
    } else {
      traversal.flash(`Item added!`, 'success')
    }

    traversal.refresh()
  }

  return (
    <>
      <Divider orientation="left"></Divider>
      <div className="is-flex is-align-items-center">
        <Typography.Text>{label ?? 'Afageix un artista'}</Typography.Text>
        <SearchInput
          dataTestWrapper={`wrapperSearchInputTest${field}`}
          path={
            path ??
            `${process.env.REACT_APP_GUILLOTINA_DB_ACCOUNT}/${traversal.client.getYearFromPath(
              model.item.parent['@id']
            )}/`
          }
          qs={qs}
          client={traversal.client}
          onChange={doAction}
          btnClass="is-small ml-4"
          queryCondition="title"
        />
        {loading && (
          <>
            <div className="ml-4 mr-4"> Afegint ... </div> <Spin />
          </>
        )}
      </div>
    </>
  )
}
